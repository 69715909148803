import styled from '@emotion/styled/macro';
import { MenuItem, Select } from '@mui/material';

export const SelectLabel = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  margin-bottom: 10px;
`;

export const SelectContainer = styled.label`
  display: flex;
  flex-direction: column;
`;

export const FontIconWrapper = styled.div`
  font-size: 16px;
  opacity: 1;
  color: ${({ theme }) => theme.palette.common.black};
  margin-right: 8px;
`;

export const StyledSelect = styled(Select)`
  & .MuiSelect-select {
    font-size: 16px;
    color: ${({ theme }) => theme.palette.common.blackSpace};
    font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
    padding-left: 0 !important;
  }

  & .MuiSelect-select ${FontIconWrapper} {
    display: none;
  }

  &:hover .MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.common.blackSpace};
  }

  & .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
    color: ${({ theme }) => theme.palette.common.blackSpace};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 16px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
`;
