import React, { useRef, useEffect, useState } from 'react';
import { debounce } from 'lodash';

type OffsetPosition = 'top' | 'bottom';

export default function useVisibility<Element extends HTMLElement>(
  offset = 0,
  debounceMilliseconds = 100,
  offsetPosition: OffsetPosition = 'top',
  showAfterEdge = false,
): [boolean, React.RefObject<Element>] {
  const [isVisible, setIsVisible] = useState(true);
  const currentElement = useRef<Element>(null);

  const onScroll = debounce(() => {
    if (!currentElement.current) {
      setIsVisible(false);
      return;
    }

    if (offsetPosition === 'top') {
      const top = currentElement.current.getBoundingClientRect().top;
      const condition = showAfterEdge
        ? top + offset >= 0 && top - offset <= window.innerHeight
        : top - offset <= window.innerHeight;
      return setIsVisible(condition);
    }

    if (offsetPosition === 'bottom') {
      const bottom = currentElement.current.getBoundingClientRect().bottom;
      const condition = showAfterEdge
        ? bottom + offset >= 0 && bottom - offset <= window.innerHeight
        : bottom - offset <= window.innerHeight;
      return setIsVisible(condition);
    }
  }, debounceMilliseconds);

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true);
    return () => document.removeEventListener('scroll', onScroll, true);
  }, [onScroll]);

  return [isVisible, currentElement];
}
