import React, { useCallback, useEffect, useState } from 'react';
// libs
import { useIntl } from 'react-intl';
// material-ui
import { InputAdornment } from '@mui/material';
// constants
import { IntlKeys } from 'localization/keys';
// icons
import VRALogo from 'assets/icons/cryptos/vra.svg';
import { ReactComponent as ArrowForwardIcon } from 'assets/icons/arrow-forward.svg';
import { ReactComponent as IDealLogo } from 'assets/icons/i-deal-logo.svg';
import { ReactComponent as SofortLogo } from 'assets/icons/sofort-logo.svg';
import { ReactComponent as SepaLogo } from 'assets/icons/sepa-logo.svg';
import { ReactComponent as BancontactLogo } from 'assets/icons/bancontact-logo.svg';
import { ReactComponent as VisaIcon } from 'assets/icons/visa.svg';
import { ReactComponent as MastercardIcon } from 'assets/icons/mastercard.svg';
// styled
import {
  ActionButton,
  ActionButtonWrapper,
  CryptoContainer,
  CryptoLabel,
  CryptoLogo,
  CryptoTextField,
  CurrencyTextField,
  LogosContainer,
  StyledMenuItem,
  StyledMenu,
  Footer,
  FooterItem,
} from './styled';

export interface FormTabContent {
  actionButtonText: string;
  isSelling?: boolean;
}

const CURRENCY_INPUT_PROPS = {
  startAdornment: <InputAdornment position="start">€</InputAdornment>,
};

const CRYPTO_INPUT_PROPS = {
  startAdornment: <InputAdornment position="start">=</InputAdornment>,
};

const EXCHANGE_RATES_TO_EURO_BUY = {
  vra: 241.3127413127413,
};

const EXCHANGE_RATES_TO_EURO_SELL = {
  vra: 239.5129416177419,
};

function FormTabContent({ isSelling, actionButtonText }: FormTabContent) {
  const { formatMessage } = useIntl();

  const [currencyValue, setCurrencyValue] = useState('');
  const [cryptoValue, setCryptoValue] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: any) => setAnchorEl(event?.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleCurrencyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const numericValue = Number(event.target.value);
      let crypto;
      if (!isSelling) {
        crypto = EXCHANGE_RATES_TO_EURO_BUY.vra * numericValue;
      }
      if (isSelling) {
        crypto = EXCHANGE_RATES_TO_EURO_SELL.vra * numericValue;
      }
      setCurrencyValue(event.target.value);
      setCryptoValue(crypto ? crypto.toString() : '');
    },
    [isSelling],
  );

  const handleCryptoChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const numericValue = Number(event.target.value);
      let currency;
      if (!isSelling) {
        currency = EXCHANGE_RATES_TO_EURO_BUY.vra / numericValue;
      }
      if (isSelling) {
        currency = EXCHANGE_RATES_TO_EURO_SELL.vra / numericValue;
      }
      setCryptoValue(event.target.value);
      setCurrencyValue(currency ? currency.toString() : '');
    },
    [isSelling],
  );

  useEffect(() => {
    const numericValue = Number(currencyValue);
    let crypto;
    if (!isSelling) {
      crypto = EXCHANGE_RATES_TO_EURO_BUY.vra * numericValue;
    }
    if (isSelling) {
      crypto = EXCHANGE_RATES_TO_EURO_SELL.vra * numericValue;
    }
    setCryptoValue(crypto ? crypto.toString() : '');
  }, [currencyValue, isSelling]);

  return (
    <>
      <CurrencyTextField
        value={currencyValue}
        autoComplete="off"
        size="small"
        type="number"
        InputProps={CURRENCY_INPUT_PROPS}
        onChange={handleCurrencyChange}
      />
      <CryptoContainer>
        <CryptoTextField
          value={cryptoValue}
          autoComplete="off"
          size="small"
          type="number"
          InputProps={CRYPTO_INPUT_PROPS}
          onChange={handleCryptoChange}
        />
        <div>
          <CryptoLabel startIcon={<CryptoLogo src={VRALogo} alt="" />} disableRipple onClick={handleClick}>
            VRA
          </CryptoLabel>
          <StyledMenu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
            <StyledMenuItem onClick={handleClose}>
              <CryptoLogo src={VRALogo} alt="" /> VRA
            </StyledMenuItem>
          </StyledMenu>
        </div>
      </CryptoContainer>
      <ActionButtonWrapper>
        <ActionButton fullWidth endIcon={<ArrowForwardIcon />}>
          {actionButtonText}
        </ActionButton>
      </ActionButtonWrapper>
      <LogosContainer>
        <IDealLogo />
        <SofortLogo />
        <SepaLogo />
        <BancontactLogo />
        <VisaIcon />
        <MastercardIcon />
      </LogosContainer>
      <Footer>
        <FooterItem>{formatMessage({ id: IntlKeys.websiteSinglecoinOrderLimit })}</FooterItem>
        <FooterItem>{formatMessage({ id: IntlKeys.websiteSinglecoinRepeatOrder })}</FooterItem>
      </Footer>
    </>
  );
}

export default FormTabContent;
