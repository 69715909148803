import React from 'react';
// libs
import { useIntl } from 'react-intl';
// constants
import { IntlKeys } from 'localization/keys';
// images
import makeAccount from 'assets/images/affiliate-page/make-account.svg';
import shareLink from 'assets/images/affiliate-page/share-link.svg';
import earnCommissions from 'assets/images/affiliate-page/earn-commissions.svg';
// styled
import {
  Wrapper,
  Container,
  StepItem,
  StepItemDescription,
  StepItemImage,
  StepItemImageWrapper,
  StepItemTitle,
  StepsList,
  Description,
  Title,
} from './styled';

const STEPS_LIST = [
  {
    id: '1',
    imageSrc: makeAccount,
    title: IntlKeys.affiliationPageThreeStepsSectionStepTitle1,
    description: IntlKeys.affiliationPageThreeStepsSectionStepDescription1,
  },
  {
    id: '2',
    imageSrc: shareLink,
    title: IntlKeys.affiliationPageThreeStepsSectionStepTitle2,
    description: IntlKeys.affiliationPageThreeStepsSectionStepDescription2,
  },
  {
    id: '3',
    imageSrc: earnCommissions,
    title: IntlKeys.affiliationPageThreeStepsSectionStepTitle3,
    description: IntlKeys.affiliationPageThreeStepsSectionStepDescription3,
  },
];

const ThreeStepsSection: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Container>
        <Title>{formatMessage({ id: IntlKeys.affiliationPageThreeStepsSectionTitle })}</Title>
        <Description>{formatMessage({ id: IntlKeys.affiliationPageThreeStepsSectionDescription })}</Description>
        <StepsList>
          {STEPS_LIST.map(({ id, imageSrc, title, description }) => (
            <StepItem key={id}>
              <StepItemImageWrapper>
                <StepItemImage src={imageSrc}></StepItemImage>
              </StepItemImageWrapper>
              <StepItemTitle>{formatMessage({ id: title })}</StepItemTitle>
              <StepItemDescription>{formatMessage({ id: description })}</StepItemDescription>
            </StepItem>
          ))}
        </StepsList>
      </Container>
    </Wrapper>
  );
};

export default ThreeStepsSection;
