import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

const CenteredStyles = css`
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

export const Navigation = styled.nav`
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: ${({ theme }) => theme.palette.common.kuretakeBlackManga};

  ${({ theme }) => theme.media.sm`
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
  `}
`;

export const NavLinksWrapper = styled.div`
  display: flex;

  & > * > img {
    display: none;
  }

  ${({ theme }) => theme.media.sm`
    width: 100%;
    height: 74px;

    && > a:nth-of-type(3), && > button:nth-of-type(1) {
      display: none;
    }

    && > * {
      flex: 1;
      margin: 0;
      font-size: 10px;
      line-height: 12px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 15px 8px;

      & > svg {
        display: none;
      }

      & > img {
        display: block;
        margin: auto 0;
      }
    }
  `}
`;

export const NavLink = styled.a`
  margin-right: 46px;
  padding: 18px 8px;
  color: ${({ theme }) => theme.palette.common.white};
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.palette.common.galacticTint};
  }

  ${({ theme }) => theme.media.md`
    margin-right: 14px;
  `}
`;

export const NavButton = styled.button`
  display: flex;
  font-size: 16px;
  cursor: pointer;
  align-items: center;
  margin-right: 46px;
  padding: 18px 8px;
  transition: color 0.2s ease-in-out;
  color: ${({ theme }) => theme.palette.common.white};

  & > svg {
    margin-left: 8px;
    color: ${({ theme }) => theme.palette.common.white50};
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.common.galacticTint};
  }

  ${({ theme }) => theme.media.md`
    margin-right: 14px;
  `}
`;

export const LogoLink = styled.a`
  ${CenteredStyles}

  ${({ theme }) => theme.media.sm`
    display: none;
  `}
`;

export const ProfileButton = styled.button`
  ${CenteredStyles};

  cursor: pointer;

  ${({ theme }) => theme.media.sm`
    display: none;
  `}
`;

export const ExplorePopoverWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 10px 10px 0 rgb(0 0 0 / 10%);
  z-index: 10;
`;

export const AccountPopoverWrapper = styled.div`
  position: absolute;
  top: 100%;
  right: 20px;
  box-shadow: 0 10px 10px 0 rgb(0 0 0 / 10%);
  z-index: 10;
`;

export const NFTPopoverWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: calc(50% - 68px);
  z-index: 10;

  ${({ theme }) => theme.breakpoints.down('md')} {
    left: calc(50% - 55px);
  }
`;
