import React, { useMemo } from 'react';
// libs
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
// material-ui
import { Stack } from '@mui/material';
// store
import { uiLocaleSelector } from 'store/ui';
// constants
import { FOOTER_LISTS } from '../constants';
// icons
import { ReactComponent as AppleStoreIcon } from 'assets/icons/apple-store.svg';
import { ReactComponent as GooglePlayIcon } from 'assets/icons/google-play.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram.svg';
import { ReactComponent as LinkedinIcon } from 'assets/icons/linkedin.svg';
// other
import { IntlKeys } from 'localization/keys';
// styled
import {
  IconLink,
  InfoContainer,
  InfoContent,
  InfoWrapper,
  LoginContainer,
  LoginTitle,
  List,
  ListContainer,
  ListItem,
  ListItemLink,
  ListItemTitle,
  Divider,
  ListWrapper,
  RegisterButton,
  LoginText,
  LoginLink,
  AppsContainer,
  ButtonsContainer,
  SocialsContainer,
  SocialsInnerContainer,
} from './styled';

function Info() {
  const { formatMessage } = useIntl();

  const locale = useSelector(uiLocaleSelector);

  const footerLists = useMemo(
    () => (
      <ListWrapper>
        {FOOTER_LISTS(locale).map((list, index) => {
          return (
            <ListContainer key={index}>
              {list.map((nestedList) => (
                <List key={nestedList[0].title}>
                  {nestedList.map(({ title, link }, index) => {
                    return (
                      <ListItem key={title}>
                        {!index ? (
                          <ListItemTitle>{formatMessage({ id: title })}</ListItemTitle>
                        ) : (
                          <ListItemLink href={link} tabIndex={1}>
                            {formatMessage({ id: title })}
                          </ListItemLink>
                        )}
                      </ListItem>
                    );
                  })}
                </List>
              ))}
            </ListContainer>
          );
        })}
      </ListWrapper>
    ),
    [locale, formatMessage],
  );

  return (
    <InfoWrapper>
      <InfoContent>
        <InfoContainer>
          <LoginContainer>
            <LoginTitle>{formatMessage({ id: IntlKeys.footerStartInvestmentTitle })}</LoginTitle>
            <ButtonsContainer>
              <Stack direction="column" spacing={2}>
                <RegisterButton>{formatMessage({ id: IntlKeys.footerRegisterButton })}</RegisterButton>
                <LoginText>
                  {formatMessage({ id: IntlKeys.footerLoginText })}{' '}
                  <LoginLink href="#">{formatMessage({ id: IntlKeys.footerLoginLinkText })}</LoginLink>
                </LoginText>
              </Stack>
            </ButtonsContainer>
            <AppsContainer>
              <Stack direction="column" spacing={2}>
                <IconLink href="#" tabIndex={1}>
                  <AppleStoreIcon />
                </IconLink>
                <IconLink href="#" tabIndex={1}>
                  <GooglePlayIcon />
                </IconLink>
              </Stack>
            </AppsContainer>
            <SocialsContainer>
              <SocialsInnerContainer>
                <Stack direction="row" spacing={2}>
                  <IconLink href="#" tabIndex={1}>
                    <TelegramIcon />
                  </IconLink>
                  <IconLink href="#" tabIndex={1}>
                    <InstagramIcon />
                  </IconLink>
                  <IconLink href="#" tabIndex={1}>
                    <LinkedinIcon />
                  </IconLink>
                </Stack>
              </SocialsInnerContainer>
            </SocialsContainer>
          </LoginContainer>
          <Divider />
          {footerLists}
        </InfoContainer>
      </InfoContent>
    </InfoWrapper>
  );
}

export default Info;
