// icons
import { ReactComponent as NewsIcon } from 'assets/icons/news.svg';
// other
import { IntlKeys } from 'localization/keys';

export const NFTS_ITEMS = [
  // {
  //   title: IntlKeys.headerNFTPopoverCreate,
  //   // TODO: replace text
  //   description: IntlKeys.commonLorem,
  //   icon: NewsIcon,
  //   link: 'create-nft',
  // },
  {
    title: IntlKeys.headerNFTPopoverLaunchpad,
    // TODO: replace text
    description: IntlKeys.commonLorem,
    icon: NewsIcon,
    link: '/',
  },
  {
    title: IntlKeys.headerNFTPopoverMarketplace,
    // TODO: replace text
    description: IntlKeys.commonLorem,
    icon: NewsIcon,
    link: 'marketplace',
  },
  {
    title: IntlKeys.headerNFTPopoverMyCollections,
    // TODO: replace text
    description: IntlKeys.commonLorem,
    icon: NewsIcon,
    link: 'my-collections',
  },
];
