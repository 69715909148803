import list from './ziplist.json';

export const COINMERCE_BASE_URL = 'https://coinmerce.io/';
export const EURO_SIGN = '€';

export const POSTAL_CODE_LIST = list as unknown as Record<string, { label: string; format: string }>;

export enum MONTH {
  January = 1,
  February = 2,
  March = 3,
  April = 4,
  May = 5,
  June = 6,
  July = 7,
  August = 8,
  September = 9,
  October = 10,
  November = 11,
  December = 12,
}
