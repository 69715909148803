import styled from '@emotion/styled/macro';

export const Wrapper = styled.footer`
  position: relative;
  bottom: 0;
  padding: 60px 0;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.common.kuretakeBlackManga};
`;

export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  border: 0;
  background-color: ${({ theme }) => theme.palette.common.deadForest};
  line-height: 1px;
  margin: 44px 0;
`;
