import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { random } from 'lodash';
import { RootState } from '../index';
import { NotificationType } from 'components/Notification';
import { NotificationPayload } from '../types';
import { SLICE_NAMES } from '../constants';

type NotificationState = { message: string; messageId: number; type: NotificationType };

const initialState: NotificationState = { message: '', messageId: 1, type: 'info' };

const uiSlice = createSlice({
  name: SLICE_NAMES.NOTIFICATION,
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<NotificationPayload>) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.messageId = random(0, 200000);
    },
  },
});

export const notificationSelector = (state: RootState): NotificationState => state[SLICE_NAMES.NOTIFICATION];

export const { setNotification } = uiSlice.actions;

export default uiSlice.reducer;
