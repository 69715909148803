import React from 'react';
import { useIntl } from 'react-intl';
// material-ui
import { Link } from '@mui/material';
// components
import ExchangeForm from '../components/ExchangeForm';
// constants
import { IntlKeys } from 'localization/keys';
// icons
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg';
// styled
import {
  TopTenBadge,
  TopTenBadgeDigit,
  TopTenBadgeText,
  TopTenContainer,
  TopTenDescription,
  TopTenDescriptionList,
  TopTenPrimaryTitle,
  TopTenSecondaryTitle,
  TopTenTopTenDescriptionListItem,
  Wrapper,
} from './styled';

function LeftColumn() {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <TopTenContainer>
        <TopTenBadge>
          <TopTenBadgeText>TOP</TopTenBadgeText>
          <TopTenBadgeDigit>10</TopTenBadgeDigit>
        </TopTenBadge>
        <TopTenPrimaryTitle>Top 10 bundle</TopTenPrimaryTitle>
        <TopTenSecondaryTitle>B-TOP10</TopTenSecondaryTitle>
      </TopTenContainer>
      <TopTenDescription>
        {formatMessage({ id: IntlKeys.websiteBundleviewDescription }, { bundle: 'Top 10', bundleShort: 'B-TOP10' })}
      </TopTenDescription>
      <ExchangeForm />
      <TopTenDescriptionList>
        <TopTenTopTenDescriptionListItem>
          <CheckmarkIcon />
          <div>{formatMessage({ id: IntlKeys.websiteBundleviewUsp1 })}</div>
        </TopTenTopTenDescriptionListItem>
        <TopTenTopTenDescriptionListItem>
          <CheckmarkIcon />
          <div>{formatMessage({ id: IntlKeys.websiteBundleviewUsp2 })}</div>
        </TopTenTopTenDescriptionListItem>
        <TopTenTopTenDescriptionListItem>
          <CheckmarkIcon />
          <div>
            {formatMessage(
              { id: IntlKeys.websiteBundleviewUsp3 },
              {
                link: (
                  <Link href="https://www.trustpilot.com/review/coinmerce.io">
                    {formatMessage({ id: IntlKeys.websiteBundleviewUsp3Link })}
                  </Link>
                ),
              },
            )}
          </div>
        </TopTenTopTenDescriptionListItem>
        <TopTenTopTenDescriptionListItem>
          <CheckmarkIcon />
          <div>{formatMessage({ id: IntlKeys.websiteBundleviewUsp4 })}</div>
        </TopTenTopTenDescriptionListItem>
      </TopTenDescriptionList>
    </Wrapper>
  );
}

export default LeftColumn;
