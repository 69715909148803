import React from 'react';
// libs
import { useIntl } from 'react-intl';
// constants
import { IntlKeys } from 'localization/keys';
// images
import promotionalMaterial from 'assets/images/affiliate-page/promotional-material.png';
// styled
import { Wrapper, Container, PromotionalSection, Title, Description, ViewButton, Image } from './styled';

const PromotionalMaterialSection: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Container>
        <PromotionalSection>
          <Title>{formatMessage({ id: IntlKeys.affiliationPagePromotionalMaterialSectionTitle })}</Title>
          <Description>
            {formatMessage({ id: IntlKeys.affiliationPagePromotionalMaterialSectionDescription })}
          </Description>
          <ViewButton>{formatMessage({ id: IntlKeys.affiliationPagePromotionalMaterialSectionButton })}</ViewButton>
        </PromotionalSection>
        <Image src={promotionalMaterial} />
      </Container>
    </Wrapper>
  );
};

export default PromotionalMaterialSection;
