import React from 'react';
// images
import accountVerified from 'assets/images/dashboard/account-verified.svg';
// icons
import { ReactComponent as EuroIcon } from 'assets/icons/euro-alt.svg';
import { ReactComponent as CryptoIcon } from 'assets/icons/crypto.svg';
// styled
import { Container, CryptoButton, Description, EuroButton, Image, Title, Wrapper } from './styled';

export interface VerificationCardProps {
  onEuroClick: () => void;
  onCryptoClick: () => void;
}

const VerificationCard: React.FC<VerificationCardProps> = ({ onEuroClick, onCryptoClick }) => {
  return (
    <Wrapper>
      <Container>
        <Title>Welkom bij Coinmerce!</Title>
        <Description>Your bank account and ID have been approved. You can start trading!</Description>
        <Image src={accountVerified} />
        <EuroButton startIcon={<EuroIcon />} onClick={onEuroClick}>
          Euro storten
        </EuroButton>
        <CryptoButton startIcon={<CryptoIcon />} onClick={onCryptoClick}>
          Crypto storten
        </CryptoButton>
      </Container>
    </Wrapper>
  );
};

export default VerificationCard;
