import React from 'react';
import ReactDOM from 'react-dom/client';
// libs
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Global, ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider as MaterialThemeProvider } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
// store
import store, { persistor } from './store';
// components
import App from './App';
// utils
import reportWebVitals from './reportWebVitals';
// styles
import { materialTheme, GlobalStyles } from './themes';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Global styles={GlobalStyles} />
        <HelmetProvider>
          <MaterialThemeProvider theme={materialTheme}>
            <EmotionThemeProvider theme={materialTheme}>
              <CssBaseline />
              <App />
            </EmotionThemeProvider>
          </MaterialThemeProvider>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
