import { css } from '@emotion/react';
import { createTheme } from '@mui/material/styles';
import { Colors } from 'themes/colors';
import { media } from 'themes/mediaQueries';
// types
import type {} from '@mui/lab/themeAugmentation';
// fonts
import 'themes/fonts.css';

export const materialTheme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'inherit',
          borderRadius: '3px',
          fontFamily: 'VisbyRoundCF SemiBold',
          fontSize: '16px',
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'inherit',
          borderRadius: '3px',
          fontFamily: 'VisbyRoundCF SemiBold',
          fontSize: '16px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderRadius: '3px',
          borderColor: Colors.snowBank,
        },
        root: {
          '&.Mui-focused .MuiInputAdornment-root .MuiTypography-root': {
            color: Colors.brescianBlue,
          },
          '&.Mui-focused .MuiOutlinedInput-input': {
            color: Colors.brescianBlue,
          },
          '&.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.brescianBlue,
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.drWhite,
          },
          '&:hover.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.drWhite,
          },
        },
        input: {
          fontFamily: 'VisbyRoundCF Regular',
          fontSize: '1.25rem',
          color: Colors.blackSpace,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'VisbyRoundCF SemiBold',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          fontFamily: 'VisbyRoundCF SemiBold',
          '.MuiTypography-root': {
            fontFamily: 'VisbyRoundCF Medium',
            color: Colors.blackSpace,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'VisbyRoundCF medium',
          color: Colors.blackSpace,
          fontSize: '1.25rem',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
        variant: 'rectangular',
      },
    },
  },
  palette: {
    primary: {
      light: Colors.wizardWhite,
      main: Colors.brescianBlue,
    },
    error: { main: Colors.incision },
    secondary: {
      main: Colors.bleachedSilk,
      contrastText: Colors.blackSpace,
    },
    common: Object.keys(Colors).reduce((prev, curr) => ({ ...prev, [curr]: Colors[curr as keyof typeof Colors] }), {}),
  },
  typography: {
    fontFamily: '"VisbyRoundCF Medium", Arial, sans-serif',
    fontFamilyVisbyRoundCFRegular: '"VisbyRoundCF Regular", Arial, sans-serif',
    fontFamilyVisbyRoundCFMedium: '"VisbyRoundCF Medium", Arial, sans-serif',
    fontFamilyVisbyRoundCFSemiBold: '"VisbyRoundCF SemiBold", Arial, sans-serif',
    fontFamilyVisbyRoundCFBold: '"VisbyRoundCF Bold", Arial, sans-serif',
  },
  media,
});

export const GlobalStyles = css`
  #root {
    width: 100%;
    min-height: 100vh;
    position: relative;
  }

  body * {
    font-family: 'VisbyRoundCF Medium', Arial, sans-serif;
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }

  h1 {
    font-size: 2.5em;
    margin: 0 0 30px 0;
  }

  h2 {
    font-size: 2em;
    margin-bottom: 25px;
  }

  h3 {
    font-size: 1.5em;
    margin-bottom: 15px;
  }

  h4 {
    font-size: 1.2em;
    margin-bottom: 10px;
  }

  h5 {
    font-size: 1.1em;
    margin-bottom: 10px;
  }

  h6 {
    font-size: 1em;
    font-weight: normal;
    margin-bottom: 10px;
  }

  p {
    line-height: 20px;
    letter-spacing: 0.03em;
    margin-bottom: 8px;
  }

  ul {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button,
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  button {
    background: none;
  }

  body {
    margin: 0;
  }
`;
