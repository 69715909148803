import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 174px;
  background-color: ${({ theme }) => theme.palette.common.whiteOut};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 16px 80px 16px;
`;

export const Container = styled.div`
  width: 1008px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.media.lg`
    width: 100%;
  `}
`;

export const Title = styled.div`
  font-size: 48px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  line-height: 72px;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
  text-align: center;
  margin-bottom: 48px;

  ${({ theme }) => theme.media.md`
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 12px;
  `}
`;

export const QAndASection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

export const Question = styled.div`
  font-size: 28px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  line-height: 36px;
  color: ${({ theme }) => theme.palette.common.brescianBlue};

  ${({ theme }) => theme.media.md`
    font-size: 22px;
    line-height: 28px;
  `}
`;

export const Answer = styled.div`
  font-size: 18px;
  font-family: 'VisbyRoundCF Medium', sans-serif;
  line-height: 36px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  margin-top: 18px;

  ${({ theme }) => theme.media.md`
    font-size: 16px;
    line-height: 28px;
    margin-top: 12px;
  `}
`;
