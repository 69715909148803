import React, { useCallback, useEffect, useState } from 'react';
// libs
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
// components
import RegisterForm from './RegisterForm';
// hooks
import { useRegisterStepperContext } from 'providers/RegisterStepperProvider';
// types
import { RegisterFormType } from './types';
// icons
import { ReactComponent as ArrowForwardIcon } from 'assets/icons/arrow-forward.svg';
// styled
import { CompleteButton, Container, LoginLink, LoginText, Wrapper } from './styled';

export type PasswordHintKeys =
  | 'oneLowercaseChar'
  | 'oneUppercaseChar'
  | 'oneSpecialChar'
  | 'oneNumber'
  | 'minEightChars';

export type PasswordState = Record<PasswordHintKeys, 'error' | 'success' | 'default'>;

const INITIAL_PASSWORD_STATE = {
  oneLowercaseChar: 'default',
  oneUppercaseChar: 'default',
  oneNumber: 'default',
  oneSpecialChar: 'default',
  minEightChars: 'default',
} as PasswordState;

// TODO refactor the component ASAP
const RegisterStep: React.FC = () => {
  const { handleNextStep } = useRegisterStepperContext();

  const [passwordState, setPasswordState] = useState<PasswordState>(INITIAL_PASSWORD_STATE);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordFieldFocused, setIsPasswordFieldFocused] = useState(false);

  const togglePasswordVisibility = useCallback(() => setIsPasswordVisible(!isPasswordVisible), [isPasswordVisible]);

  const handlePasswordFieldFocusChange = useCallback((value: boolean) => () => setIsPasswordFieldFocused(value), []);

  const getValidationSchema = () => {
    return Yup.object().shape({
      fullName: Yup.string()
        .required('Required')
        .test('fullNameTest', '', (value, context) => {
          const regex = new RegExp('[a-zA-Z]\\s[a-zA-Z]');

          if (value && !regex.test(value)) {
            return context.createError({
              path: context.path,
              message: () => 'Should be at least 2 letters with space in between',
            });
          }
          return true;
        }),
      email: Yup.string()
        .required('Required')
        .test('moreThanBidStepSize', '', (value, context) => {
          const emailRegex = new RegExp('[a-z0-9]+@[a-z]+\\.[a-z]{2,3}');

          if (value && !emailRegex.test(value)) {
            return context.createError({
              path: context.path,
              message: () => 'A valid email looks like this@example.com',
            });
          }
          return true;
        }),
      password: Yup.string()
        .required('Required')
        .test('testPassword', '', (value, context) => {
          let hasErrors = false;
          const newPasswordState = { ...passwordState };

          // regexes
          const oneLowercaseCharRegex = new RegExp('(?=.*[a-z])');
          const oneUppercaseCharRegex = new RegExp('(?=.*[A-Z])');
          const oneNumberRegex = new RegExp('(?=.*\\d)');
          const oneSpecialCharRegex = new RegExp('(?=.*\\W)');

          if (value === undefined) {
            setPasswordState(INITIAL_PASSWORD_STATE);
            return false;
          }

          // errors
          if (!oneLowercaseCharRegex.test(value)) {
            newPasswordState.oneLowercaseChar = 'error';
            hasErrors = true;
          }

          if (!oneUppercaseCharRegex.test(value)) {
            newPasswordState.oneUppercaseChar = 'error';
            hasErrors = true;
          }

          if (!oneNumberRegex.test(value)) {
            newPasswordState.oneNumber = 'error';
            hasErrors = true;
          }

          if (!oneSpecialCharRegex.test(value)) {
            newPasswordState.oneSpecialChar = 'error';
            hasErrors = true;
          }

          if (value.length < 8) {
            newPasswordState.minEightChars = 'error';
            hasErrors = true;
          }

          // success
          if (oneLowercaseCharRegex.test(value)) {
            newPasswordState.oneLowercaseChar = 'success';
          }

          if (oneUppercaseCharRegex.test(value)) {
            newPasswordState.oneUppercaseChar = 'success';
          }

          if (oneNumberRegex.test(value)) {
            newPasswordState.oneNumber = 'success';
          }

          if (oneSpecialCharRegex.test(value)) {
            newPasswordState.oneSpecialChar = 'success';
          }

          if (value.length >= 8) {
            newPasswordState.minEightChars = 'success';
          }

          setPasswordState(newPasswordState);

          if (hasErrors) {
            return context.createError({
              path: context.path,
              message: () => 'Password is not strong enough',
            });
          }

          return true;
        }),
    });
  };

  const formik = useFormik<RegisterFormType>({
    validationSchema: getValidationSchema(),
    initialValues: {
      fullName: '',
      email: '',
      password: '',
      referralCode: '',
      usCitizen: true,
      pep: true,
      understand: true,
      agree: true,
    },
    onSubmit: () => handleNextStep(),
  });

  const handleCompleteButtonClick = useCallback(() => {
    formik.submitForm().then(() =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto',
      }),
    );
  }, [formik]);

  const validateForm = useCallback(() => formik.validateForm().then((errors) => errors), [formik]);

  useEffect(() => {
    validateForm().then((errors) => formik.setErrors(errors));
  }, []);

  return (
    <Wrapper>
      <Container>
        <FormikProvider value={formik}>
          <RegisterForm
            passwordState={passwordState}
            isPasswordVisible={isPasswordVisible}
            isPasswordFieldFocused={isPasswordFieldFocused}
            handlePasswordFieldFocusChange={handlePasswordFieldFocusChange}
            togglePasswordVisibility={togglePasswordVisibility}
          />
          <CompleteButton
            endIcon={<ArrowForwardIcon />}
            onClick={handleCompleteButtonClick}
            disabled={!!Object.keys(formik.errors).length}
          >
            Create Account
          </CompleteButton>
        </FormikProvider>
      </Container>
      <LoginText>
        Already have an account? <LoginLink href="#">Login</LoginLink>
      </LoginText>
    </Wrapper>
  );
};

export default RegisterStep;
