import React, { useMemo } from 'react';
// helpers
import { roundTo5Digits } from 'helpers/renderHelpers';
// styled
import {
  CardSkeleton,
  CardSkeletonWrapper,
  Description,
  DescriptionSkeleton,
  Price,
  PriceSkeleton,
  Wrapper,
} from './styled';

export interface PriceCardProps {
  price: number | string;
  description: string;
  digitName?: string;
  currencySymbol?: string;
  isLoading?: boolean;
}

const PriceCard: React.FC<PriceCardProps> = ({
  price,
  description,
  isLoading,
  digitName = '',
  currencySymbol = '€',
}) => {
  const cardSkeleton = useMemo(
    () => (
      <CardSkeletonWrapper>
        <CardSkeleton />
        <PriceSkeleton />
        <DescriptionSkeleton />
      </CardSkeletonWrapper>
    ),
    [],
  );

  const card = useMemo(
    () => (
      <Wrapper>
        <Price>
          {currencySymbol}
          {typeof price === 'string' ? price : roundTo5Digits(price)}
          {digitName}
        </Price>
        <Description>{description}</Description>
      </Wrapper>
    ),
    [currencySymbol, description, digitName, price],
  );

  return <>{isLoading ? cardSkeleton : card}</>;
};

export default PriceCard;
