import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

export const PERSIST_CONFIG_KEY_NAME = 'root';

export const IGNORED_ACTIONS = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER];

export const SLICE_NAMES = {
  AUTH: 'auth',
  NOTIFICATION: 'notification',
  UI: 'ui',
} as const;
