import { RefObject, useEffect } from 'react';

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  refs: RefObject<T>[],
  handler: (event?: MouseEvent) => void,
) {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      let containsTarget = false;
      refs.forEach((r) => {
        if (!r.current || r.current.contains(event.target as Node)) {
          containsTarget = true;
        }
      });
      if (containsTarget) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [refs, handler]);
}

export default useOnClickOutside;
