import styled from '@emotion/styled/macro';
import { focusVisibleStyles } from 'components/styled/common';

export const CommonWrapper = styled.div`
  margin-bottom: 40px;
`;

export const IconLink = styled.a`
  display: inline-block;
  width: fit-content;
  &:focus-visible {
    ${({ theme }) => focusVisibleStyles(theme)}
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const InfoContent = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
`;

export const InfoWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;

export const LoginContainer = styled.div`
  padding: 0 15px;
  flex-grow: 1;
`;

export const LoginTitle = styled.h2`
  font-size: 36px;
  width: 200px;
  display: flex;
  color: ${({ theme }) => theme.palette.common.white};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
`;

export const ListContainer = styled.div`
  padding: 0 15px;
  flex-grow: 0.5;
`;

export const List = styled.ul`
  padding-top: 10px;
`;

export const ListItem = styled.li``;

export const ListItemLink = styled.a`
  color: ${({ theme }) => theme.palette.common.smokyStudio};
  font-size: 14px;
  line-height: 28px;
  transition: 0.2s all;
  position: relative;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFMedium};
  text-decoration: none;
  &:focus-visible {
    ${({ theme }) => focusVisibleStyles(theme)}
  }
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -3px;
    left: 0;
    background-color: ${({ theme }) => theme.palette.primary.main};
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.15s ease-out;
  }
  &:hover {
    color: ${({ theme }) => theme.palette.common.white};
    &::before {
      transform: scaleX(1);
      visibility: visible;
    }
  }
`;

export const ListItemTitle = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 14px;
  line-height: 31px;
  margin-bottom: 10px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
`;

export const Divider = styled.hr`
  display: none;
  width: 100%;
  height: 1px;
  border: 0;
  background-color: ${({ theme }) => theme.palette.common.deadForest};
  line-height: 1px;
  margin-bottom: 18px;
  ${({ theme }) => theme.media.xs`
    display: block;
  `}
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-grow: 2;
  ${({ theme }) => theme.media.xs`
    flex-direction: column;
    padding-left: 12px;
  `}
`;
