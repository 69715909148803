import React from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
// material-ui
import { Button, Theme } from '@mui/material';
// components
import FormTextField from 'components/formik/FormTextField';
import FormCheckbox, { FormCheckboxProps } from 'components/formik/FormCheckbox';
// icons
import checkIcon from 'assets/icons/registerPage/custom-checkbox-checkmark.svg';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Container = styled.div`
  width: 400px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 6px;
  padding: 32px;

  ${({ theme }) => theme.media.xs`
    width: 100%;
    padding: 16px;
  `}
`;

export const Form = styled.form`
  width: 100%;
`;

export const StyledFormTextField = styled(({ success, ...rest }: { success: boolean; isPasswordField?: boolean }) => (
  <FormTextField {...rest} />
))`
  & .MuiOutlinedInput-input.MuiInputBase-input {
    font-size: 16px;
    line-height: 24px;
    font-family: 'VisbyRoundCF SemiBold', sans-serif;
    padding: 16px;
  }

  & .MuiInputAdornment-positionStart + .MuiOutlinedInput-input.MuiInputBase-input {
    padding: 16px 16px 16px 0 !important;
  }

  & .MuiOutlinedInput-notchedOutline,
  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme, success }) =>
      success ? theme.palette.common.coolGreen + ' !important' : theme.palette.common.ancestralWater};
    border-radius: 6px;
  }

  & .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.common.grenadePink};
  }

  & .Mui-focused {
    box-shadow: 0 4px 4px 0 #0023441a;
  }

  & .MuiInputAdornment-root {
    color: ${({ theme, success }) => (success ? theme.palette.common.coolGreen : theme.palette.common.rhapsodyInBlue)};
  }

  & .Mui-error .MuiInputAdornment-root {
    color: ${({ theme }) => theme.palette.common.grenadePink};
  }

  & .Mui-error .MuiInputAdornment-root {
    color: ${({ theme }) => theme.palette.common.grenadePink};
  }

  &:hover {
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme, success }) =>
        success ? theme.palette.common.coolGreen : theme.palette.common.ancestralWater};
      border-radius: 6px;
    }
  }

  & .MuiFormHelperText-root {
    box-shadow: none !important;
  }

  & .MuiFormHelperText-root {
    display: ${({ isPasswordField }) => (isPasswordField ? 'none' : 'block')};
  }

  ${({ theme }) => theme.media.sm`
    max-width: 100%;
  `}
`;

export const Icon = styled.span`
  width: 16px;
  height: 16px;
  border: 1px solid ${({ theme }) => theme.palette.common.shadowMountain};
  border-radius: 3px;
`;

export const IconChecked = styled(Icon)`
  &:before {
    display: block;
    width: 16px;
    height: 16px;

    background-image: url(${checkIcon});
    background-repeat: no-repeat;
    background-position: center;

    content: '';
  }
`;

export const StyledFormCheckbox = styled((props: FormCheckboxProps) => (
  <FormCheckbox disableRipple color="default" icon={<Icon />} checkedIcon={<IconChecked />} {...props} />
))`
  padding-right: 16px;
  padding-top: 4px;
  display: flex;
  align-items: flex-start;

  .MuiTypography-root {
    font-size: 16px;
    line-height: 24px;
    font-family: 'VisbyRoundCF SemiBold', sans-serif;
    color: ${({ theme }) => theme.palette.common.shadowMountain};
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.transparent};
  }
`;

export const PasswordHintText = styled.div`
  font-size: 13.33px;
  line-height: 24px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: inherit;
`;

const getPasswordHintItemStyles = (theme: Theme, hintStatus: 'error' | 'success' | 'default') => {
  switch (hintStatus) {
    case 'error':
      return css`
        color: ${theme.palette.common.grenadePink};
      `;
    case 'success':
      return css`
        color: ${theme.palette.common.coolGreen};
      `;
    case 'default':
      return css`
        color: ${theme.palette.common.ancestralWater};
      `;
  }
};

export const PasswordHintItem = styled.div<{ hintStatus: 'error' | 'success' | 'default' }>`
  display: flex;
  align-items: center;
  ${({ theme, hintStatus }) => getPasswordHintItemStyles(theme, hintStatus)};

  & > svg {
    margin-right: 8px;
  }
`;

export const PasswordHitsLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PasswordHitsRightColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PasswordsHistContainer = styled.div`
  display: flex;
  justify-content: space-between;
  //margin-top: 16px;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CompleteButton = styled(Button)`
  width: 100%;
  height: 56px;
  padding: 12px 32px;
  font-size: 19.2px;
  line-height: 32px;
  font-family: 'VisbyRoundCF Bold', sans-serif;
  color: ${({ theme }) => theme.palette.common.white};
  margin-top: 16px;
  border-radius: 6px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.atmosphere};
  }
`;

export const Link = styled.a`
  font-size: 16px;
  line-height: 24px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
  text-decoration-line: underline;
`;

export const UsCitizenFieldWrapper = styled.div`
  display: flex;
  position: relative;
  width: fit-content;

  & > svg {
    position: absolute;
    top: 3px;
    right: 9%;
  }
`;

export const PepFieldWrapper = styled.div`
  display: flex;
  position: relative;
  width: fit-content;

  & > svg {
    position: absolute;
    top: 3px;
    right: 10%;
  }
`;

export const LoginText = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.shadowMountain};
  margin-top: 16px;
`;

export const LoginLink = styled.a`
  font-size: 16px;
  line-height: 24px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
  text-decoration-line: underline;
`;
