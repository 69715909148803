import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { Step, StepConnector, StepLabel, Stepper } from '@mui/material';

export const Wrapper = styled.div`
  width: 100%;
`;

const StyledIconStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 24px;
  border-radius: 100px;
  font-size: 13.33px;
  font-family: 'VisbyRoundCF Medium', sans-serif;
  line-height: 24px;
`;

export const StyledIcon = styled.div<{ ownerState: { active: boolean } }>`
  & .QontoStepIcon-completedIcon {
    ${StyledIconStyles};
    background-color: ${({ theme }) => theme.palette.common.coolGreen};
    color: ${({ theme }) => theme.palette.common.white};
  }
  & .QontoStepIcon-circle {
    ${StyledIconStyles};
    background-color: ${({ theme, ownerState }) =>
      ownerState.active ? theme.palette.common.brescianBlue : theme.palette.common.ancestralWater};
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const StyledStepLabel = styled(StepLabel)`
  & .MuiStepLabel-label {
    font-size: 16px;
    font-family: 'VisbyRoundCF SemiBold', sans-serif;
    line-height: 24px;
    color: ${({ theme }) => theme.palette.common.rhapsodyInBlue};
  }

  & .Mui-completed {
    color: ${({ theme }) => theme.palette.common.coolGreen} !important;
  }

  & .Mui-disabled {
    color: ${({ theme }) => theme.palette.common.ancestralWater} !important;
  }

  ${({ theme }) => theme.media.sm`
    & .MuiStepLabel-labelContainer {
        display: none;
    }
  `};
`;

export const StyledStepperConnector = styled(StepConnector)`
  display: block;
  width: 24px;

  & .MuiStepConnector-line {
    border: none;
    background-color: ${({ theme }) => theme.palette.common.transparent} !important;
  }

  ${({ theme }) => theme.media.sm`
    width: 6px;
  `};
`;

export const StyledStep = styled(Step)``;

export const StyledStepper = styled(Stepper)``;
