import React from 'react';
// material-ui
import { TextFieldProps, SelectProps as MuiSelectProps } from '@mui/material';
// styled
import { FontIconWrapper, SelectContainer, SelectLabel, StyledLabel, StyledMenuItem, StyledSelect } from './styled';

export type SelectProps = Omit<TextFieldProps, 'label' | 'variant' | 'children' | 'defaultValue'> &
  MuiSelectProps & {
    label?: string;
    onMenuItemClick?: (value: string) => void;
    options: { value: string; label: string; fontIcon?: string }[];
  };

function Select({ label, options, onMenuItemClick, ...rest }: SelectProps) {
  return (
    <SelectContainer>
      {label && <SelectLabel>{label}</SelectLabel>}
      <StyledSelect size="small" variant="outlined" defaultValue={options[0]?.value || ''} select {...rest}>
        {options.map((option) => (
          <StyledMenuItem
            key={option.value}
            value={option.value}
            onClick={onMenuItemClick ? () => onMenuItemClick(option.value) : () => {}}
            divider
          >
            <FontIconWrapper>{option?.fontIcon}</FontIconWrapper>
            <StyledLabel>{option.label}</StyledLabel>
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </SelectContainer>
  );
}

export default Select;
