import React from 'react';
// libs
import { useIntl } from 'react-intl';
// icons
import facebookMessenger from 'assets/icons/facebook-messenger.svg';
import eMail from 'assets/icons/e-mail.svg';
// constants
import { IntlKeys } from 'localization/keys';
// styled
import {
  Container,
  Description,
  InfoSection,
  SocialIcon,
  SocialItem,
  SocialsSection,
  SocialText,
  StyledArrowIcon,
  Title,
  Wrapper,
} from './styled';

const SOCIALS_LIST = [
  {
    id: '1',
    name: IntlKeys.affiliationPageContactUsSectionSocial1,
    imgSrc: facebookMessenger,
  },
  { id: '2', name: IntlKeys.affiliationPageContactUsSectionSocial2, imgSrc: eMail },
];

const ContactUsSection: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Container>
        <InfoSection>
          <Title>{formatMessage({ id: IntlKeys.affiliationPageContactUsSectionTitle })}</Title>
          <Description>{formatMessage({ id: IntlKeys.affiliationPageContactUsSectionDescription })}</Description>
        </InfoSection>
        <SocialsSection>
          {SOCIALS_LIST.map(({ id, name, imgSrc }) => (
            <SocialItem key={id}>
              <SocialIcon src={imgSrc} />
              <SocialText>{formatMessage({ id: name })}</SocialText>
              <StyledArrowIcon />
            </SocialItem>
          ))}
        </SocialsSection>
      </Container>
    </Wrapper>
  );
};

export default ContactUsSection;
