import React from 'react';
// libs
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
// components
import Routes from './Routes';
import DashboardProvider from '../../providers/DashboardProvider';
import RegisterStepperProvider from '../../providers/RegisterStepperProvider';

export const history = createBrowserHistory({ window });

function Router() {
  return (
    <HistoryRouter history={history}>
      <DashboardProvider>
        <RegisterStepperProvider>
          <Routes />
        </RegisterStepperProvider>
      </DashboardProvider>
    </HistoryRouter>
  );
}

export default Router;
