import React from 'react';
// libs
import { useIntl } from 'react-intl';
// constants
import { IntlKeys } from 'localization/keys';
// icons
import checkMark from 'assets/icons/checkmark.svg';
// images
import bots from 'assets/images/bots-page/bots.png';
// styled
import {
  Wrapper,
  Container,
  AboutSection,
  Title,
  Description,
  ItemsList,
  ListItem,
  ItemIcon,
  ItemText,
  SectionImage,
  BotsButton,
} from './styled';

const LIST_ITEMS = [
  { id: '1', text: IntlKeys.botsPageAutomaticTradingSectionListItem1 },
  { id: '2', text: IntlKeys.botsPageAutomaticTradingSectionListItem2 },
  { id: '3', text: IntlKeys.botsPageAutomaticTradingSectionListItem3 },
  { id: '4', text: IntlKeys.botsPageAutomaticTradingSectionListItem4 },
];

const AutomaticTradingSection: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Container>
        <AboutSection>
          <Title>{formatMessage({ id: IntlKeys.botsPageAutomaticTradingSectionTitle })}</Title>
          <Description>{formatMessage({ id: IntlKeys.botsPageAutomaticTradingSectionDescription })}</Description>
          <ItemsList>
            {LIST_ITEMS.map(({ id, text }) => (
              <ListItem key={id}>
                <ItemIcon src={checkMark} />
                <ItemText>{formatMessage({ id: text })}</ItemText>
              </ListItem>
            ))}
          </ItemsList>
          <BotsButton>{formatMessage({ id: IntlKeys.botsPageAutomaticTradingSectionButton })}</BotsButton>
        </AboutSection>
        <SectionImage src={bots} />
      </Container>
    </Wrapper>
  );
};

export default AutomaticTradingSection;
