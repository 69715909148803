import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 172px;

  ${({ theme }) => theme.media.md`
    margin-top: 80px;
  `}
`;

export const Container = styled.div`
  max-width: 1280px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 190px;

  ${({ theme }) => theme.media.lg`
    width: 100%;
    padding: 16px;
  `}

  ${({ theme }) => theme.media.md`
    padding-bottom: 60px;
    flex-direction: column;
  `}
`;

export const Title = styled.div`
  font-size: 48px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  line-height: 54px;
  color: ${({ theme }) => theme.palette.common.brescianBlue};

  ${({ theme }) => theme.media.md`
    font-size: 32px;
    line-height: 36px;
  `}
`;

export const Description = styled.div`
  font-size: 18px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  line-height: 36px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  margin-top: 44px;

  ${({ theme }) => theme.media.md`
    font-size: 16px;
    line-height: 32px;
    margin-top: 18px;
  `}

  ${({ theme }) => theme.media.xs`
    text-align: center;
  `}
`;

export const BotsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 375px;

  ${({ theme }) => theme.media.xxs`
    width: 100%;
  `}
`;

export const BotsSectionBanner = styled.div`
  padding: 4px 6px;
  background-color: ${({ theme }) => theme.palette.common.deadPixel};
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 16px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  letter-spacing: 3px;
  text-transform: uppercase;
`;

export const Divider = styled.div`
  width: 1px;
  height: 25px;
  background-color: ${({ theme }) => theme.palette.common.pixelWhite};
  margin: 12px 0 8px 0;
`;

export const DescriptionSection = styled.div`
  width: 440px;
  display: flex;
  flex-direction: column;
  margin-left: 180px;

  ${({ theme }) => theme.media.lg`
    margin-left: 100px;;
  `}

  ${({ theme }) => theme.media.md`
    width: 100%;
    align-items: center;
    margin: 0;
    margin-top: 18px;
  `}

  ${({ theme }) => theme.media.xs`
    width: 100%;
    align-items: center;
    margin: 0;
    margin-top: 18px;
  `}
`;

export const BotsLink = styled.a`
  font-size: 18px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  line-height: 36px;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
  align-self: flex-end;
  display: flex;
  align-items: center;
  margin-top: 6px;

  & > svg {
    margin-left: 14px;
  }
`;
