import styled from '@emotion/styled/macro';
import { Button } from '@mui/material';

export const Wrapper = styled.div`
  width: 100%;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 2px;

  ${({ theme }) => theme.media.sm`
    
  `};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.div`
  font-size: 24px;
  line-height: 29px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.8px;
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  margin: 16px 0 12px;

  ${({ theme }) => theme.media.xxs`
    margin-bottom: 0;
  `};
`;

export const Image = styled.img`
  width: 100%;
  margin-top: 21px;

  ${({ theme }) => theme.media.xxs`
    margin-top: 16px;
  `};
`;

export const ActionButton = styled(Button)`
  height: 51px;
  font-size: 16px;
  line-height: 19px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  margin-top: 32px;

  ${({ theme }) => theme.media.xxs`
    margin-top: 24px;
  `};
`;
