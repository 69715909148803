import React from 'react';
// libs
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
// store
import { uiLocaleSelector } from 'store/ui';
// components
import ExplorePopover from './popovers/ExplorePopover';
import AccountPopover from './popovers/AccountPopover';
import NFTPopover from './popovers/NFTPopover';
import MobileExploreNav from './MobileExploreNav';
// hooks
import useSubMenus from './hooks/useSubMenus';
// constants
import { COINMERCE_BASE_URL } from 'constants/general';
// icons
import { ArrowDropDown } from '@mui/icons-material';
import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
// styled
import {
  Navigation,
  LogoLink,
  ProfileButton,
  ExplorePopoverWrapper,
  AccountPopoverWrapper,
  NFTPopoverWrapper,
  NavLinksWrapper,
  NavLink,
  NavButton,
} from './styled';

const AuthHeader: React.FC = () => {
  const { formatMessage } = useIntl();
  const locale = useSelector(uiLocaleSelector);

  const {
    isNFTMenuOpened,
    isAccountMenuOpened,
    isExploreMenuOpened,
    isMobileExploreNavOpened,
    menuItems,
    navRef,
    exploreRef,
    toggleMobileExploreNav,
    handleOnAccountMouseOver,
  } = useSubMenus();

  return (
    <Navigation ref={navRef}>
      <LogoLink href={`${COINMERCE_BASE_URL}${locale}`}>
        <LogoIcon />
      </LogoLink>

      <NavLinksWrapper>
        {menuItems.map(({ title, url, icon, ...props }, index) =>
          url ? (
            <NavLink key={index} href={url}>
              {icon} {formatMessage({ id: title })}
            </NavLink>
          ) : (
            <NavButton {...props} key={index} type="button">
              {icon} {formatMessage({ id: title })} <ArrowDropDown fontSize="small" />
            </NavButton>
          ),
        )}
      </NavLinksWrapper>

      <MobileExploreNav onClose={toggleMobileExploreNav} isOpen={isMobileExploreNavOpened} />

      <ProfileButton onMouseOver={handleOnAccountMouseOver}>
        <ProfileIcon />
      </ProfileButton>

      <NFTPopoverWrapper>
        <NFTPopover isOpened={isNFTMenuOpened} />
      </NFTPopoverWrapper>

      <ExplorePopoverWrapper ref={exploreRef}>
        <ExplorePopover isOpened={isExploreMenuOpened} />
      </ExplorePopoverWrapper>

      <AccountPopoverWrapper>
        <AccountPopover isOpened={isAccountMenuOpened} />
      </AccountPopoverWrapper>
    </Navigation>
  );
};

export default AuthHeader;
