import React from 'react';
// libs
import { useIntl } from 'react-intl';
// constants
import { IntlKeys } from 'localization/keys';
// icons
import checkMark from 'assets/icons/checkmark.svg';
// images
import affiliateVisual from 'assets/images/affiliate-page/affiliate-visual.png';
// styled
import {
  Wrapper,
  Container,
  AboutSection,
  Title,
  Description,
  ItemsList,
  ListItem,
  ItemIcon,
  ItemText,
  SectionImage,
  RegisterButton,
} from './styled';

const LIST_ITEMS = [
  { id: '1', text: IntlKeys.affiliationPageAboutSectionListItem1 },
  { id: '2', text: IntlKeys.affiliationPageAboutSectionListItem2 },
  { id: '3', text: IntlKeys.affiliationPageAboutSectionListItem3 },
];

const AffiliateProgramSection: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Container>
        <AboutSection>
          <Title>{formatMessage({ id: IntlKeys.affiliationPageAboutSectionTitle })}</Title>
          <Description>{formatMessage({ id: IntlKeys.affiliationPageAboutSectionDescription })}</Description>
          <ItemsList>
            {LIST_ITEMS.map(({ id, text }) => (
              <ListItem key={id}>
                <ItemIcon src={checkMark} />
                <ItemText>{formatMessage({ id: text })}</ItemText>
              </ListItem>
            ))}
          </ItemsList>
          <RegisterButton>{formatMessage({ id: IntlKeys.affiliationPageAboutSectionButton })}</RegisterButton>
        </AboutSection>
        <SectionImage src={affiliateVisual} />
      </Container>
    </Wrapper>
  );
};

export default AffiliateProgramSection;
