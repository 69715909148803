import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 50px 0 50px;

  ${({ theme }) => theme.media.md`
    padding: 16px;
  `}
`;
