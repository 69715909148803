import React, { useCallback, useMemo, useState } from 'react';
// material-ui
import { InputAdornment } from '@mui/material';
// components
import FormChart from './FormChart';
// libs
import { useIntl } from 'react-intl';
// constants
import { IntlKeys } from 'localization/keys';
// icons
import { ReactComponent as StakingRewardIcon } from 'assets/icons/staking-reward.svg';
import { ReactComponent as VRAIcon } from 'assets/icons/cryptos/vra.svg';
// styled
import {
  Wrapper,
  Title,
  Container,
  InputsContainer,
  CurrencyTextField,
  CryptoTextField,
  TitleContainer,
  YearButton,
  YearButtonsContainer,
  RewardContainer,
  RewardPriceContainer,
  RewardPrice,
  Asterisk,
  RewardCryptoPrice,
  RewardNote,
  FormChartContainer,
  YearsOwnText,
} from './styled';

const CURRENCY_INPUT_PROPS = {
  startAdornment: <InputAdornment position="start">€</InputAdornment>,
};

const CRYPTO_INPUT_PROPS = {
  startAdornment: (
    <InputAdornment position="start">
      <VRAIcon width={20} height={20} />
    </InputAdornment>
  ),
};

const DATA = [
  {
    yearsOwnText: 'Na 1 jaar bezit je',
    earnEuro: '€ 39,93',
    earnCrypto: '48.348,98 VRA*',
    chartData: [
      {
        uv: 0,
      },
      {
        uv: 1,
      },
      {
        uv: 2,
      },
      {
        uv: 4,
      },
      {
        uv: 6,
      },
      {
        uv: 8,
      },
      {
        uv: 10,
      },
      {
        uv: 11,
      },
      {
        uv: 12,
      },
      {
        uv: 14,
      },
      {
        uv: 16,
      },
      {
        uv: 18,
      },
      {
        uv: 21,
      },
    ],
  },
  {
    yearsOwnText: 'Na 2 jaar bezit je',
    earnEuro: '€ 87,93',
    earnCrypto: '113.348,98 VRA*',
    chartData: [
      {
        uv: 0,
      },
      {
        uv: 1,
      },
      {
        uv: 1,
      },
      {
        uv: 2,
      },
      {
        uv: 2.3,
      },
      {
        uv: 2.7,
      },
      {
        uv: 2.9,
      },
      {
        uv: 3.1,
      },
      {
        uv: 4.2,
      },
      {
        uv: 4.8,
      },
      {
        uv: 5.3,
      },
      {
        uv: 6.4,
      },
      {
        uv: 8.1,
      },
    ],
  },
  {
    yearsOwnText: 'Na 5 jaar bezit je',
    earnEuro: '€ 439,93',
    earnCrypto: '754.477,94 VRA*',
    chartData: [
      {
        uv: 0,
      },
      {
        uv: 1,
      },
      {
        uv: 1,
      },
      {
        uv: 2,
      },
      {
        uv: 3,
      },
      {
        uv: 5,
      },
      {
        uv: 8,
      },
      {
        uv: 13,
      },
      {
        uv: 21,
      },
      {
        uv: 34,
      },
      {
        uv: 55,
      },
      {
        uv: 89,
      },
      {
        uv: 144,
      },
    ],
  },
];

const StakingRewardForm: React.FC = () => {
  const { formatMessage } = useIntl();

  const [currencyValue, setCurrencyValue] = useState('');
  const [cryptoValue, setCryptoValue] = useState('');
  const [selectedYear, setSelectedYear] = useState(2);

  const handleCurrencyChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrencyValue(event.target.value);
  }, []);

  const handleCryptoChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCryptoValue(event.target.value);
  }, []);
  ``;

  const yearButtons = useMemo(
    () => [
      { content: IntlKeys.websiteSinglecoinEarn1Year },
      { content: IntlKeys.websiteSinglecoinEarn2Year },
      { content: IntlKeys.websiteSinglecoinEarn5Year },
    ],
    [],
  );

  return (
    <Wrapper>
      <TitleContainer>
        <StakingRewardIcon />
        <Title>{formatMessage({ id: IntlKeys.websiteSinglecoinEarnCalculateRewards })}</Title>
      </TitleContainer>
      <Container>
        <InputsContainer>
          <CurrencyTextField
            value={currencyValue}
            autoComplete="off"
            size="small"
            type="number"
            InputProps={CURRENCY_INPUT_PROPS}
            onChange={handleCurrencyChange}
          />
          <CryptoTextField
            value={cryptoValue}
            autoComplete="off"
            size="small"
            type="number"
            InputProps={CRYPTO_INPUT_PROPS}
            onChange={handleCryptoChange}
          />
          <YearButtonsContainer>
            {yearButtons.map(({ content }, index) => (
              <YearButton
                selected={selectedYear === index}
                onClick={() => setSelectedYear(index)}
                key={index.toString()}
              >
                {formatMessage({ id: content })}
              </YearButton>
            ))}
          </YearButtonsContainer>
        </InputsContainer>
        <RewardContainer>
          <RewardPriceContainer>
            <YearsOwnText>{DATA[selectedYear].yearsOwnText}</YearsOwnText>
            <RewardPrice>
              {DATA[selectedYear].earnEuro} <Asterisk>*</Asterisk>
            </RewardPrice>
            <RewardCryptoPrice>{DATA[selectedYear].earnCrypto}</RewardCryptoPrice>
          </RewardPriceContainer>
          <FormChartContainer>
            <FormChart chartData={DATA[selectedYear].chartData} />
          </FormChartContainer>
          <RewardNote>{formatMessage({ id: IntlKeys.websiteSinglecoinEarnAsterisk })}</RewardNote>
        </RewardContainer>
      </Container>
    </Wrapper>
  );
};

export default StakingRewardForm;
