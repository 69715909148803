import React from 'react';
// libs
import { Outlet } from 'react-router-dom';
// components
import RegisterHeader from '../headers/RegisterHeader';
import UnauthFooter from '../footers/UnauthFooter';
// styled
import { Wrapper } from './styled';

export interface RegisterLayoutProps {}

const RegisterLayout: React.FC<RegisterLayoutProps> = () => {
  return (
    <>
      <RegisterHeader />

      <Wrapper>
        <Outlet />
      </Wrapper>
    </>
  );
};

export default RegisterLayout;
