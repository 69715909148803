import React from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
// material-ui
import { Button, MenuItem, ButtonProps } from '@mui/material';

export const Wrapper = styled.div`
  position: relative;
  display: block;
`;

export const ButtonWrapper = styled.div``;

export const StyledButton = styled(({ isOpen, ...rest }: ButtonProps & { isOpen: boolean }) => <Button {...rest} />)`
  position: relative;
  width: 92px;
  font-size: 16px;
  background-color: ${({ theme }) => theme.palette.common.whiteOut};
  border: 1px solid ${({ theme }) => theme.palette.common.pixelWhite};
  color: ${({ theme }) => theme.palette.common.blackSpace};
  border-radius: 10px;
  transition: all 0.1s ease-out;
  & .Mui-focusVisible,
  &:hover {
    background-color: ${({ theme }) => theme.palette.common.white};
  }
  ${({ isOpen, theme }) =>
    isOpen &&
    css`
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      background-color: ${theme.palette.common.white};
    `}
`;

export const StyledMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.palette.common.blackSpace} !important;
  font-size: 16px;
  padding: 8px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};

  &:last-of-type {
    border-radius: 10px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  & img {
    margin-right: 8px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.brescianBlue};
    color: ${({ theme }) => theme.palette.common.white} !important;
  }

  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.common.brescianBlue10};

    &:hover {
      background-color: ${({ theme }) => theme.palette.common.brescianBlue};
      color: ${({ theme }) => theme.palette.common.white};
    }
  }
`;

export const Menu = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  top: 40px;
  position: absolute;
  font-size: 14px;
  min-width: 92px;
  border-radius: 10px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border: 1px solid ${({ theme }) => theme.palette.common.pixelWhite};
  background-color: ${({ theme }) => theme.palette.common.white};
  z-index: 100;
`;

export const Image = styled.img`
  width: 20px;
  height: 20px;
`;
