import styled from '@emotion/styled/macro';
import { Button } from '@mui/material';

export const Wrapper = styled.div`
  max-width: 1280px;

  ${({ theme }) => theme.media.md`
    width: 100%;
  `}
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.media.md`
    flex-direction: column;
    align-items: center;
  `}
`;

export const AboutSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 620px;

  ${({ theme }) => theme.media.md`
    max-width: 100%;
    margin-top: 32px;
  `}

  ${({ theme }) => theme.media.sm`
    max-width: 100%;
    margin-top: 12px;
  `}
`;

export const Title = styled.div`
  font-size: 48px;
  font-family: 'VisbyRoundCF Bold', sans-serif;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
  line-height: 100%;

  ${({ theme }) => theme.media.md`
    font-size: 32px;
  `}
`;

export const Description = styled.div`
  font-size: 24px;
  font-family: 'VisbyRoundCF Medium', sans-serif;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  line-height: 36px;
  margin-top: 40px;
  max-width: 560px;

  ${({ theme }) => theme.media.md`
    font-size: 18px;
    line-height: 30px;
  `}
`;

export const ItemsList = styled.ul`
  margin-top: 24px;
  gap: 4px;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
`;

export const ItemIcon = styled.img`
  width: 28px;
  height: 18px;
  margin-right: 22px;
`;

export const ItemText = styled.div`
  font-size: 18px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  line-height: 36px;

  ${({ theme }) => theme.media.md`
    font-size: 14px;
    line-height: 32px
  `}
`;

export const RegisterButton = styled(Button)`
  width: fit-content;
  height: 51px;
  padding: 16px 32px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  margin-top: 32px;

  ${({ theme }) => theme.media.md`
    font-size: 14px;
  `}
`;

export const SectionImage = styled.img`
  width: 100%;
  max-width: 653px;
  max-height: 530px;

  ${({ theme }) => theme.media.md`
    margin-top: 60px;
  `}

  ${({ theme }) => theme.media.xs`
    height: 100%;
  `}
`;
