import { useEffect, RefObject } from 'react';

function useOnMouseLeave<T extends HTMLElement = HTMLElement>(refs: RefObject<T>[], handle: () => void) {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      let containsTarget = false;
      refs.forEach((r: RefObject<T>) => {
        if (!r.current || r.current.contains(event.target as Node)) {
          containsTarget = true;
        }
      });
      if (containsTarget) {
        return;
      }
      handle();
    };

    document.addEventListener('mouseover', listener);

    return () => {
      document.removeEventListener('mouseover', listener);
    };
  }, [handle, refs]);
}

export default useOnMouseLeave;
