import styled from '@emotion/styled/macro';
import { Button, Menu, MenuItem } from '@mui/material';

export const Wrapper = styled.div``;

export const DropdownLabel = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  letter-spacing: 0.8px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  margin-bottom: 8px;
`;

export const CryptoLabel = styled(Button)`
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  color: ${({ theme }) => theme.palette.common.blackSpace};
  padding: 8px 10px 8px 16px;
  width: 118px;
  height: 50px;

  & > svg {
    margin-left: 12px;
    width: 20px;
    height: 20px;
  }

  & .MuiButton-startIcon {
    margin-right: 14px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const StyledMenu = styled(Menu)`
  & .MuiMenu-paper {
    width: 118px;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  color: ${({ theme }) => theme.palette.common.blackSpace};
  padding: 12px 16px;
  & > img {
    margin-right: 14px;
  }
`;

export const CryptoLogo = styled.img`
  width: 20px;
  height: 20px;
`;
