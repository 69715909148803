import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ChartContainer = styled.div`
  width: 100%;
  height: 351px;
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 2px;
  position: relative;
`;

export const InfoCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const MoreProductsCard = styled.div`
  width: calc(50% - 8px);
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  padding: 24px;
  background-color: ${({ theme }) => theme.palette.common.brilliance};
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.media.md`
    width: 100%;
  `}
`;

export const MoreProductsCardText = styled.div`
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-size: 21px;
  line-height: 25px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  letter-spacing: 0.8px;
  opacity: 0.25;
`;
