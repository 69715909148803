import React, { useMemo } from 'react';
// icons
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-forward.svg';
// styled
import {
  Amount,
  Container,
  TitleContainer,
  TopContainer,
  Wrapper,
  Title,
  CardSkeletonWrapper,
  CardSkeleton,
  CardTitleSkeleton,
  CardPriceSkeleton,
} from './styled';

export interface InfoCard {
  icon: JSX.Element;
  title: string;
  amount?: number | string;
}

export interface InfoCard {
  isLoading?: boolean;
}

const InfoCard: React.FC<InfoCard> = ({ icon, title, isLoading, amount = '0,00' }) => {
  const cardSkeleton = useMemo(
    () => (
      <CardSkeletonWrapper>
        <CardSkeleton />
        <CardTitleSkeleton />
        <CardPriceSkeleton />
      </CardSkeletonWrapper>
    ),
    [],
  );

  const card = useMemo(
    () => (
      <Wrapper>
        <Container>
          <TopContainer>
            <TitleContainer>
              {icon}
              <Title>{title}</Title>
              <ArrowIcon />
            </TitleContainer>
            <Amount>€{amount}</Amount>
          </TopContainer>
        </Container>
      </Wrapper>
    ),
    [amount, icon, title],
  );

  return <>{isLoading ? cardSkeleton : card}</>;
};

export default InfoCard;
