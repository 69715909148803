import React from 'react';
// styled
import { Wrapper } from './styled';

export interface YoutubeEmbedProps {
  embedId: string;
  width?: string;
  height?: string;
  allowFullScreen?: boolean;
  title?: string;
}

function YoutubeEmbed({
  embedId,
  width = '100%',
  height = '100%',
  allowFullScreen = true,
  title = 'Embedded youtube',
}: YoutubeEmbedProps) {
  return (
    <Wrapper>
      <iframe
        width={width}
        height={height}
        allowFullScreen={allowFullScreen}
        src={`https://www.youtube.com/embed/${embedId}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        title={title}
      />
    </Wrapper>
  );
}

export default YoutubeEmbed;
