import { Country, COUNTRIES } from 'constants/countries';

export const findFlag = (code: string) => {
  return COUNTRIES.find((country: Country) => country.code.toLowerCase() === code.toLowerCase());
};

export const findFlagByDialCode = (dialCode: string) => {
  const filtersFlags = findFlagsByDialCode(dialCode);
  const preferredFlag = filtersFlags.find((country: Country) => country.dialCode === dialCode && country.preferred);
  if (preferredFlag) {
    return preferredFlag;
  }
  return filtersFlags[0] ?? null;
};

export const findFlagsByDialCode = (dialCode: string) => {
  return COUNTRIES.filter((flag) => flag.dialCode === dialCode);
};

export const getList = () => {
  return COUNTRIES;
};

export const getFlag = (code: string) => {
  return COUNTRIES.find((country: Country) => country.code.toLowerCase() === code.toLowerCase())?.flag || '';
};

export const searchFlag = (keyword: string) => {
  return COUNTRIES.filter((flag: Country) => {
    return (
      flag.code.toLowerCase().includes(keyword.toLowerCase()) ||
      flag.name.toLowerCase().includes(keyword.toLowerCase()) ||
      flag.dialCode.toLowerCase().includes(keyword.toLowerCase())
    );
  });
};

export const getPhoneCodeByCountryCode = (code: string) => {
  return COUNTRIES.find((country: Country) => country.code.toLowerCase() === code.toLowerCase())?.dialCode || '';
};
