import React from 'react';
// libs
import { useIntl } from 'react-intl';
// constants
import { IntlKeys } from 'localization/keys';
// images
import riskFactor from 'assets/images/bots-page/risk-factor.svg';
import availability from 'assets/images/bots-page/availability.svg';
import makeAccount from 'assets/images/bots-page/make-account.svg';
// styled
import { Wrapper, Container, Item, ItemDescription, ItemImage, ItemTitle, List, ItemImageWrapper } from './styled';

const LIST = [
  {
    id: '1',
    imageSrc: riskFactor,
    title: IntlKeys.botsPageDescriptionSectionListItemTitle1,
    description: IntlKeys.botsPageDescriptionSectionListItemDescription1,
    imgWidth: 57,
    imgHeight: 48,
  },
  {
    id: '2',
    imageSrc: availability,
    title: IntlKeys.botsPageDescriptionSectionListItemTitle2,
    description: IntlKeys.botsPageDescriptionSectionListItemDescription2,
    imgWidth: 53,
    imgHeight: 53,
  },
  {
    id: '3',
    imageSrc: makeAccount,
    title: IntlKeys.botsPageDescriptionSectionListItemTitle3,
    description: IntlKeys.botsPageDescriptionSectionListItemDescription3,
    imgWidth: 79,
    imgHeight: 52,
  },
];

const DescriptionSection: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Container>
        <List>
          {LIST.map(({ id, imageSrc, title, description, imgWidth, imgHeight }) => (
            <Item key={id}>
              <ItemImageWrapper>
                <ItemImage src={imageSrc} width={imgWidth} height={imgHeight}></ItemImage>
              </ItemImageWrapper>
              <ItemTitle>{formatMessage({ id: title })}</ItemTitle>
              <ItemDescription>{formatMessage({ id: description })}</ItemDescription>
            </Item>
          ))}
        </List>
      </Container>
    </Wrapper>
  );
};

export default DescriptionSection;
