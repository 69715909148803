import styled from '@emotion/styled/macro';
// other
import { focusVisibleStyles } from 'components/styled/common';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 613px;
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  background-color: ${({ theme }) => theme.palette.common.infinity};
  width: 330px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

export const List = styled.ul`
  width: 100%;
`;

export const ListTitle = styled.div`
  font-size: 21px;
  line-height: 90px;
  margin: 0;
  padding: 0 18px;
  color: ${({ theme }) => theme.palette.common.white};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  text-align: left;
  height: 76px;
  border-bottom: 2px solid ${({ theme }) => theme.palette.common.antarcticDeep};
`;

export const ListItemIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 12px;
`;

export const ListItemLink = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  line-height: 76px;
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.palette.common.galacticTint};
  text-decoration: none;
  font-size: 16px;
  &:focus-visible {
    ${({ theme }) => focusVisibleStyles(theme)}
  }
`;

export const ListItemTitle = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 16px;
  transition: 0.2s all;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  height: 76px;
  border-bottom: 2px solid ${({ theme }) => theme.palette.common.antarcticDeep};
  margin: 0 18px;
  padding: 0;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    ${ListItemTitle} {
      color: ${({ theme }) => theme.palette.common.white};
      transform: translateX(12px);
    }
  }
`;
