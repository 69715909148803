import styled from '@emotion/styled/macro';
import { Button } from '@mui/material';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 60px 180px;
  /* margin-bottom: 60px; */
  background-color: ${({ theme }) => theme.palette.common.whiteOut};
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.media.xs`
    padding: 16px;
    padding-bottom: 60px;
    margin-bottom: 0;
  `}
`;

export const Title = styled.div`
  font-size: 47.78px;
  line-height: 56px;
  font-family: 'VisbyRoundCF Bold', sans-serif;
  color: ${({ theme }) => theme.palette.common.rhapsodyInBlue};
  margin-bottom: 16px;

  ${({ theme }) => theme.media.xs`
    font-size: 28px;
  `}
`;

export const BackButtonAbsolute = styled(Button)`
  position: absolute;
  width: fit-content;
  min-width: 71px;
  height: 40px;
  left: 12%;
  top: 70px;
  background-color: ${({ theme }) => theme.palette.common.bleachedSilk};
  box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.common.brescianBlue};
  padding: 6px 14px;
  font-size: 13.33px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
  font-family: 'VisbyRoundCF SemiBold', sans-serif;

  &:hover {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.common.brescianBlue};
    background-color: ${({ theme }) => theme.palette.common.frostyDay};
  }

  ${({ theme }) => theme.media.sm`
    display: none;
  `};
`;

export const BackButton = styled(Button)`
  display: none;
  width: fit-content;
  min-width: 71px;
  height: 40px;
  background-color: ${({ theme }) => theme.palette.common.bleachedSilk};
  box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.common.brescianBlue};
  padding: 6px 14px;
  font-size: 13.33px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
  font-family: 'VisbyRoundCF SemiBold', sans-serif;

  &:hover {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.common.brescianBlue};
    background-color: ${({ theme }) => theme.palette.common.frostyDay};
  }

  ${({ theme }) => theme.media.sm`
    display: flex;
  `};
`;

export const Container = styled.div`
  position: relative;
`;
