export enum EnvVars {
  HOST_URL = 'REACT_APP_HOST_URL',
  API_URL = 'REACT_APP_API_URL',
}

export enum AppMode {
  DEV = 'development',
  TEST = 'testing',
  PROD = 'production',
}

class Env {
  private constructor() {}

  static getInstance() {
    return new Env();
  }

  getItem(key: EnvVars) {
    return process.env[key];
  }
}

const instance = Env.getInstance();

export { instance as Env };
