import React from 'react';
// libs
import { FieldProps } from 'formik';
// material
import { FormControlLabel, Checkbox, FormControlLabelProps, CheckboxProps } from '@mui/material';

export type FormCheckboxProps = Omit<FormControlLabelProps, 'control'> &
  Partial<Pick<FormControlLabelProps, 'control'>> &
  FieldProps &
  CheckboxProps;

function FormCheckbox({ field: { value = false, ...field }, className, ...rest }: FormCheckboxProps) {
  return (
    <FormControlLabel
      className={className}
      control={<Checkbox className={className} checked={value} {...field} {...rest} />}
      {...rest}
    />
  );
}

export default React.memo(FormCheckbox);
