import styled from '@emotion/styled/macro';
import { Button } from '@mui/material';

export const AssetCellContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AssetNamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const AssetName = styled.div`
  position: relative;
  top: -4px;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFBold};
  ${({ theme }) => theme.media.sm`
    display: none;
  `}
`;

export const AssetSubName = styled.div`
  position: relative;
  top: 2px;
  font-size: 12px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
  ${({ theme }) => theme.media.sm`
    top: -2px;
  `}
`;

export const AssetIconWrapper = styled.div`
  margin-right: 8px;
`;

export const PositivePercentage = styled.div`
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  color: ${({ theme }) => theme.palette.common.pedestrianGreen};
  ${({ theme }) => theme.media.xs`
    position: relative;
    top: 3px;
  `}
`;

export const NegativePercentage = styled.div`
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  color: ${({ theme }) => theme.palette.common.grenadinePink};
  ${({ theme }) => theme.media.xs`
    position: relative;
    top: 3px;
  `}
`;

export const BundleRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CryptoLogosContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
`;

export const CryptoLogo = styled.img`
  width: 32px;
  height: 32px;
`;

export const CryptosLeftText = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
  cursor: pointer;
`;

export const BundleBuyButton = styled(Button)`
  min-width: 70px;
  width: fit-content;
  text-align: right;
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  background-color: ${({ theme }) => theme.palette.common.blackSpace};
  padding: 4px 14px;
  white-space: nowrap;
  &:hover {
    background-color: ${({ theme }) => theme.palette.common.blackSpace};
  }
`;

export const BundleButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const THead = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  text-align: left;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
  padding-bottom: 8px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  & > div {
    display: inline-block;
    position: relative;
    top: 2px;
    margin-left: 8px;
  }
`;

export const THeaderContainer = styled.div``;

export const TCell = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  margin: 0;
  padding: 16px 0;

  :last-child {
    border-right: 0;
  }
`;

export const TRow = styled.div`
  :last-child {
    ${TCell} {
      border-bottom: 0;
    }
  }
`;

export const THeadRow = styled(TRow)``;

export const TBody = styled.div``;

export const FlexibleTable = styled.div`
  border-spacing: 0;
  max-width: 100%;
`;
