import React from 'react';
// libs
import { useIntl } from 'react-intl';
// store
import { useSelector } from 'react-redux';
import { uiLocaleSelector } from 'store/ui';
// components
import CollapseNav from './CollapseNav';
import Modal from 'components/Modal';
// constants
import { COINMERCE_BASE_URL } from 'constants/general';
import { ACCOUNT_ITEMS } from '../popovers/AccountPopover';
import { NFTS_ITEMS } from '../popovers/NFTPopover/constants';
import { PRODUCTS_ITEMS, ABOUT_ITEMS } from 'components/layouts/constants';
// icons
import { Close as CloseIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
// other
import { IntlKeys } from 'localization/keys';
// styled
import { Wrapper, LogoLink, Header, CloseButton, NavLink, Section } from './styled';

type MobileExploreNavProps = {
  onClose: () => void;
  isOpen: boolean;
};

const MobileExploreNav: React.FC<MobileExploreNavProps> = ({ onClose, isOpen }) => {
  const { formatMessage } = useIntl();
  const locale = useSelector(uiLocaleSelector);

  return (
    <Modal open={isOpen} onClose={onClose} fullScreenBreakpoint="lg">
      <Wrapper>
        <Header>
          <LogoLink href={`${COINMERCE_BASE_URL}${locale}`}>
            <LogoIcon />
          </LogoLink>

          <CloseButton type="button" onClick={onClose}>
            <CloseIcon /> {formatMessage({ id: IntlKeys.commonClose })}
          </CloseButton>
        </Header>

        <Section>
          <NavLink href={`${COINMERCE_BASE_URL}${locale}/wallets`}>
            {formatMessage({ id: IntlKeys.headerWallets })} <ArrowForwardIcon />
          </NavLink>

          <CollapseNav title={IntlKeys.headerNfts} items={NFTS_ITEMS} onClick={onClose} isRouterLink />

          <CollapseNav title={IntlKeys.headerExplorePopoverProducts} items={PRODUCTS_ITEMS} />
        </Section>

        <Section>
          {ACCOUNT_ITEMS.map(({ title, link }, index) => (
            <NavLink key={index} href={`${COINMERCE_BASE_URL}${locale}/${link}`}>
              {formatMessage({ id: title })} <ArrowForwardIcon />
            </NavLink>
          ))}
        </Section>

        <CollapseNav title={IntlKeys.headerExplorePopoverAbout} items={ABOUT_ITEMS} />
      </Wrapper>
    </Modal>
  );
};

export default MobileExploreNav;
