import React from 'react';
import styled from '@emotion/styled/macro';
import { Button, ButtonProps } from '@mui/material';
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark.svg';

export const Navigation = styled.nav<{ bgColor?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 80px;
  padding: 10px 16px 10px 24px;
  background-color: ${({ theme, bgColor }) => (bgColor ? bgColor : theme.palette.common.white)};
`;

export const LogoLink = styled.a`
  display: flex;
  align-items: center;
`;

export const StepperContainer = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonsContainer = styled.div``;

export const HelpButton = styled((props: ButtonProps) => <Button {...props} endIcon={<QuestionMarkIcon />} />)`
  width: fit-content;
  height: 40px;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 13.33px;
  line-height: 24px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.common.rhapsodyInBlue};

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.shadowMountain};
  }
`;
