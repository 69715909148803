import React, { useCallback, useMemo, useRef, useState } from 'react';
// libs
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
// components
import MenuPopover from './popovers/MenuPopover';
import MobileExploreNav from './MobileExploreNav';
import CoinsPopover from './popovers/CoinsPopover';
// hooks
import useOnMouseLeave from 'hooks/useOnMouseLeave';
// store
import { uiLocaleSelector } from 'store/ui';
// constants
import { COINMERCE_BASE_URL } from 'constants/general';
import { IntlKeys } from 'localization/keys';
// icons
import { ArrowDropDown } from '@mui/icons-material';
import { ReactComponent as LogoIcon } from 'assets/icons/logo-alt.svg';
import { ReactComponent as MobileMenuIcon } from 'assets/icons/mobile-menu-icon.svg';
// styled
import {
  AboutPopoverWrapper,
  BotsPopoverWrapper,
  ButtonsContainer,
  CoinsPopoverWrapper,
  LearnPopoverWrapper,
  LoginButton,
  LogoLink,
  MobileMenuIconWrapper,
  NavButton,
  NavContainer,
  Navigation,
  PassiveIncomePopoverWrapper,
  SignUpButton,
} from './styled';
import LangSelect from './LangSelect';

export interface UnauthHeader {
  bgColor?: string;
}

const INITIAL_POPUPS_STATE = {
  coins: false,
  income: false,
  bots: false,
  learn: false,
  about: false,
};

const UnauthHeader: React.FC<UnauthHeader> = ({ bgColor }) => {
  const [popupsState, setPopupsState] = useState(INITIAL_POPUPS_STATE);

  const [isMobileExploreNavOpened, setMobileExploreNavOpened] = useState(false);

  type KeysOfPopupsType = keyof typeof INITIAL_POPUPS_STATE;

  const locale = useSelector(uiLocaleSelector);
  const { formatMessage } = useIntl();

  const navRef = useRef<HTMLDivElement>(null);
  const coinsRef = useRef<HTMLDivElement>(null);
  const incomeRef = useRef<HTMLDivElement>(null);
  const botsRef = useRef<HTMLDivElement>(null);
  const learnRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);

  const closedPopupsState = useMemo(
    (exceptedPopups?: KeysOfPopupsType[]) =>
      Object.keys(INITIAL_POPUPS_STATE).reduce((previousValue, currentValue) => {
        const currValue = currentValue as KeysOfPopupsType;

        if (exceptedPopups?.includes(currValue)) {
          return { ...previousValue, [currValue]: popupsState[currValue] };
        }

        return { ...previousValue, [currValue]: false };
      }, {}) as typeof INITIAL_POPUPS_STATE,
    [popupsState],
  );

  const handleMouseOver = useCallback(() => setPopupsState(INITIAL_POPUPS_STATE), []);

  const handleMouseOverNavItem = useCallback(
    (itemName: KeysOfPopupsType) => () => setPopupsState({ ...closedPopupsState, [itemName]: true }),
    [closedPopupsState],
  );

  const toggleMobileExploreNav = useCallback(
    () => setMobileExploreNavOpened(!isMobileExploreNavOpened),
    [isMobileExploreNavOpened],
  );

  const handleLoginButtonClick = () => {};

  const menuItems = useMemo(
    () => [
      {
        title: IntlKeys.headerItemCoins,
        popover: (
          <CoinsPopoverWrapper ref={coinsRef}>
            <CoinsPopover isOpened={popupsState.coins} />
          </CoinsPopoverWrapper>
        ),
        onMouseOver: handleMouseOverNavItem('coins'),
      },
      {
        title: IntlKeys.headerItemPassiveIncome,
        popover: (
          <PassiveIncomePopoverWrapper ref={incomeRef}>
            <MenuPopover isOpened={popupsState.income} listType="income" width={292} />
          </PassiveIncomePopoverWrapper>
        ),
        onMouseOver: handleMouseOverNavItem('income'),
      },
      {
        title: IntlKeys.headerItemBots,
        popover: (
          <BotsPopoverWrapper ref={botsRef}>
            <MenuPopover
              isOpened={popupsState.bots}
              listType="bots"
              width={292}
              listLink={{ name: formatMessage({ id: IntlKeys.headerBotsPopoverItemLink }), href: '#' }}
            />
          </BotsPopoverWrapper>
        ),
        onMouseOver: handleMouseOverNavItem('bots'),
      },
      {
        title: IntlKeys.headerItemLearn,
        popover: (
          <LearnPopoverWrapper ref={learnRef}>
            <MenuPopover isOpened={popupsState.learn} listType="learn" width={292} />
          </LearnPopoverWrapper>
        ),
        onMouseOver: handleMouseOverNavItem('learn'),
      },
      {
        title: IntlKeys.headerItemAbout,
        popover: (
          <AboutPopoverWrapper ref={aboutRef}>
            <MenuPopover isOpened={popupsState.about} listType="about" width={292} />
          </AboutPopoverWrapper>
        ),
        onMouseOver: handleMouseOverNavItem('about'),
      },
    ],
    [
      formatMessage,
      handleMouseOverNavItem,
      popupsState.about,
      popupsState.bots,
      popupsState.coins,
      popupsState.income,
      popupsState.learn,
    ],
  );

  useOnMouseLeave([navRef, coinsRef, botsRef, incomeRef, learnRef, aboutRef], handleMouseOver);

  return (
    <Navigation ref={navRef} bgColor={bgColor}>
      <LogoLink href={`${COINMERCE_BASE_URL}${locale}`}>
        <LogoIcon />
      </LogoLink>

      <NavContainer>
        {menuItems.map(({ title, popover, ...props }, index) => (
          <NavButton {...props} key={index} type="button">
            {popover} {formatMessage({ id: title })} <ArrowDropDown fontSize="small" />
          </NavButton>
        ))}
        <LangSelect />
      </NavContainer>

      {/*TODO: add landing mobile menu*/}
      <MobileExploreNav onClose={toggleMobileExploreNav} isOpen={isMobileExploreNavOpened} />

      <ButtonsContainer>
        <LoginButton tabIndex={1} onClick={handleLoginButtonClick}>
          {formatMessage({ id: IntlKeys.headerLoginButton })}
        </LoginButton>
        <SignUpButton tabIndex={1}>{formatMessage({ id: IntlKeys.headerRegisterButton })}</SignUpButton>
      </ButtonsContainer>

      <MobileMenuIconWrapper onClick={toggleMobileExploreNav}>
        <MobileMenuIcon />
      </MobileMenuIconWrapper>
    </Navigation>
  );
};

export default UnauthHeader;
