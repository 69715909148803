import React from 'react';
// icons
import { ArrowDropDown } from '@mui/icons-material';
// styled
import { Wrapper, CryptoLabel, CryptoLogo, StyledMenu, StyledMenuItem, DropdownLabel } from './styled';

type DropdownItems = { icon: string; title: string; value: string };

export interface CoinDropdownProps {
  items: DropdownItems[];
  selected: string;
  onChange: (value: string) => void;
  label: string;
}

const CoinDropdown: React.FC<CoinDropdownProps> = ({ items, selected, onChange, label }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleLabelClick = (event: any) => setAnchorEl(event?.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleItemClick = (value: string) => () => {
    onChange(value);
    handleClose();
  };

  const selectedCoin = items.find(({ value }) => value === selected);

  return (
    <Wrapper>
      <DropdownLabel>{label}</DropdownLabel>
      <CryptoLabel startIcon={<CryptoLogo src={selectedCoin?.icon} alt="" />} disableRipple onClick={handleLabelClick}>
        {selectedCoin?.title}
        <ArrowDropDown />
      </CryptoLabel>
      <StyledMenu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {items.map(({ icon, title, value }) => (
          <StyledMenuItem onClick={handleItemClick(value)} key={value}>
            <CryptoLogo src={icon} alt={title} /> {title}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </Wrapper>
  );
};

export default CoinDropdown;
