import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';

export const Wrapper = styled.div<{ showBorder: boolean; fullWidth: boolean; isModalCard: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 62px;
  padding: 16px;
  border: ${({ showBorder, theme }) =>
    showBorder ? `1px solid ${theme.palette.common.snowBank}` : `1px solid transparent`};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.common.white};
  cursor: pointer;
  width: calc(25% - 12px);
  @media (min-width: 991px) and (max-width: 1139px) {
    width: calc(33.33% - 11px);
  }
  @media (min-width: 475px) and (max-width: 699px) {
    width: calc(33.33% - 11px);
  }
  @media (max-width: 500px) {
    width: calc(50% - 8px);
  }
  ${({ isModalCard }) =>
    isModalCard &&
    css`
      min-width: 155px;
      width: calc(20% - 20px);
      @media (min-width: 991px) and (max-width: 1139px) {
        width: calc(20% - 20px);
      }
      @media (min-width: 831px) and (max-width: 951px) {
        width: calc(25% - 20px);
      }
      @media (max-width: 830px) {
        width: calc(33.33% - 20px);
      }
      @media (max-width: 530px) {
        width: calc(50% - 20px);
      }
      @media (max-width: 375px) {
        width: 100%;
      }
    `}
`;

export const CryptoLogo = styled.img`
  width: 32px;
  height: 32px;
`;

export const CryptoName = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
`;

export const CryptoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CryptoPercentage = styled.div`
  font-size: 21px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;
