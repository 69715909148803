import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { Button } from '@mui/material';

export const Wrapper = styled.div`
  width: 100%;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  padding: 24px;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-size: 24px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  line-height: 29px;
  position: relative;

  & > svg {
    position: relative;
    top: -10px;
    right: -8px;
  }
`;

export const Amount = styled.div`
  margin-top: 16px;
  font-size: 21px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  line-height: 25px;
  letter-spacing: 0.8px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 48px;
  margin-top: 32px;
`;

const commonButtonStyles = css`
  min-width: 152px;
  height: 51px;
  font-size: 16px;
  line-height: 19px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
`;

export const DepositButton = styled(Button)`
  ${commonButtonStyles};

  ${({ theme }) => theme.media.xs`
    min-width: 130px;
  `}
`;

export const RecordButton = styled(Button)`
  ${commonButtonStyles};

  color: ${({ theme }) => theme.palette.common.blackSpace};

  ${({ theme }) => theme.media.xs`
    min-width: 130px;
  `}
`;
