import mask from 'string-mask';
import { POSTAL_CODE_LIST } from 'constants/general';

export const getFormattedPostCode = (postCode: string, country: string) => {
  if (POSTAL_CODE_LIST[country]) {
    const format = POSTAL_CODE_LIST[country]?.format;
    if (postCode.length != format?.length) {
      return mask.apply(postCode, format);
    }
  }
  return postCode;
};
