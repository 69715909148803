import React, { useMemo, useState } from 'react';
// libs
import { useMediaQuery } from 'react-responsive';
import { useIntl } from 'react-intl';
// material-ui
import { Theme, useMediaQuery as useMaterialMediaQuery } from '@mui/material';
// components
import MoreCoinsModal from '../components/MoreCoinsModal';
import CryptoCard from '../components/CryptoCard';
import YoutubeEmbed from '../components/YoutubeEmbed';
import OtherBundlesTable from '../components/OtherBundlesTable';
import ExchangeRateChart from '../components/ExchangeRateChart';
// hooks
import useScreenWidth from 'hooks/useScreenWidth';
// constants
import { breakpoints } from 'themes/mediaQueries';
// icons
import BTCLogo from 'assets/icons/cryptos/btc.svg';
import ETHLogo from 'assets/icons/cryptos/eth.svg';
import USDTLogo from 'assets/icons/cryptos/usdt.svg';
import XRPLogo from 'assets/icons/cryptos/xrp.svg';
import ADALogo from 'assets/icons/cryptos/ada.svg';
import SOLLogo from 'assets/icons/cryptos/sol.svg';
import DOTLogo from 'assets/icons/cryptos/dot.svg';
import TRXLogo from 'assets/icons/cryptos/trx.svg';
import SHIBLogo from 'assets/icons/cryptos/shib.svg';
import AVAXLogo from 'assets/icons/cryptos/avax.svg';
// styled
import {
  ChartContainer,
  CryptoCardsContainer,
  OverhaulDescription,
  OverhaulTitle,
  RedistributeBundleDescription,
  RedistributeBundleTitle,
  TopTenBundleDescription,
  TopTenBundleTitle,
  Wrapper,
  OtherBundlesTableWrapper,
  OtherBundlesTableTitle,
  CryptoCardShowMore,
} from './styled';
import { IntlKeys } from '../../../localization/keys';

function RightColumn() {
  const { formatMessage } = useIntl();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const downSm = useMaterialMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const isLargeMobile = useMediaQuery({ minWidth: 501, maxWidth: 699 });
  const isTablet = useMediaQuery({ minWidth: 700, maxWidth: 990 });
  const isSmallLaptop = useMediaQuery({ minWidth: 991, maxWidth: 1139 });
  const isLaptop = useMediaQuery({ minWidth: 1140 });

  const width = useScreenWidth();
  const xxsScreenSize = useMemo(() => width <= breakpoints.xxs, [width]);

  const cryptoCardsInfo = useMemo(
    () => [
      { cryptoLogo: BTCLogo, cryptoName: 'BTC', cryptoPercentage: 15 },
      { cryptoLogo: ETHLogo, cryptoName: 'ETC', cryptoPercentage: 15 },
      { cryptoLogo: USDTLogo, cryptoName: 'USDT', cryptoPercentage: 15 },
      { cryptoLogo: XRPLogo, cryptoName: 'XRP', cryptoPercentage: 15 },
      { cryptoLogo: ADALogo, cryptoName: 'ADA', cryptoPercentage: 10 },
      { cryptoLogo: SOLLogo, cryptoName: 'SOL', cryptoPercentage: 10 },
      { cryptoLogo: DOTLogo, cryptoName: 'DOT', cryptoPercentage: 5 },
      { cryptoLogo: TRXLogo, cryptoName: 'TRX', cryptoPercentage: 5 },
      { cryptoLogo: SHIBLogo, cryptoName: 'SHIB', cryptoPercentage: 5 },
      { cryptoLogo: AVAXLogo, cryptoName: 'AVAX', cryptoPercentage: 5 },
    ],
    [],
  );

  const cards = useMemo(() => {
    return cryptoCardsInfo.map(({ cryptoLogo, cryptoName, cryptoPercentage }, index) => {
      let maxCards = 3;
      if (isSmallLaptop || isLargeMobile) maxCards = 5;
      if (isLaptop || isTablet) maxCards = 7;
      if (index > maxCards) {
        return null;
      }
      if (index === maxCards) {
        return (
          <CryptoCardShowMore onClick={handleModalOpen}>
            {formatMessage(
              { id: IntlKeys.websiteBundleviewOtherBundlesOtherCoins },
              { amount: cryptoCardsInfo.length - index },
            )}
          </CryptoCardShowMore>
        );
      }
      return (
        <CryptoCard
          key={cryptoName}
          cryptoLogo={cryptoLogo}
          cryptoName={cryptoName}
          cryptoPercentage={cryptoPercentage}
        />
      );
    });
  }, [cryptoCardsInfo, formatMessage, isLaptop, isLargeMobile, isSmallLaptop, isTablet]);

  return (
    <Wrapper>
      <TopTenBundleTitle>{formatMessage({ id: IntlKeys.websiteBundleviewH1 }, { bundle: 'Top 10' })}</TopTenBundleTitle>
      <TopTenBundleDescription>
        Deze bundel is een verdeling van de top 10 coins die op Coinmerce zijn gelist. Hieronder zie je de grafiek en de
        verdeling per coin.
      </TopTenBundleDescription>
      <ChartContainer>
        <ExchangeRateChart />
      </ChartContainer>
      <CryptoCardsContainer>{cards}</CryptoCardsContainer>
      <RedistributeBundleTitle>{formatMessage({ id: IntlKeys.websiteBundleviewH2 })}</RedistributeBundleTitle>
      <RedistributeBundleDescription>
        Rebalancing of the bundles will be done every 2 weeks by Coinmerce. However, rebalancing only happens when the
        coins exceed the bandwidth of 10%. Below an example is shown. When a coin should be 25% of the total bundle, and
        it goes below 22,5% or above 27,5% a rebalance for that coin will be done. The same bandwidth of 10% is applied
        when a coin should be 5% of the bundle.
      </RedistributeBundleDescription>
      <RedistributeBundleDescription>
        Rebalancing happens on the basis of information given by the Dema trading bot. When rebalancing coins will be
        traded against a trading fee of 0.075% on Binance. When all the coins of the bundle fall within the bandwidth,
        except for one, the bundle will be rebalanced with the biggest outliers. When a revision needs to happen, and
        the value of the coin is below the bandwidth, the newly added coin will also be rebalanced.
      </RedistributeBundleDescription>
      <YoutubeEmbed embedId="RA5rM6MXvBU" height={downSm ? '192px' : '469px'} />
      <OverhaulTitle>Reviseren</OverhaulTitle>
      <OverhaulDescription>
        Bundles will be revisioned every two weeks. Withdrawing/adding coins will result in higher amounts of trading,
        and trading fees, than rebalancing.Therefore, to reduce trading fees, Coinmerce has chosen to add two conditions
        for the revision. Due to this the management fee can remain low. The two conditions are the following:
      </OverhaulDescription>
      <OverhaulDescription>
        A coin needs to decrease 20% in market capitalization, measured from when the coin was added to the bundle. When
        it falls outside the top market cap it will be removed and replaced with a coin that has entered the new top
        market cap list. When the coin is still in the top market cap list it will stay in the bundle.
      </OverhaulDescription>
      <OverhaulDescription>
        A coin needs to be outside of the top market cap list for 8 consecutive weeks. The coin will then be replaced
        with a coin that has entered the new top market cap list.
      </OverhaulDescription>
      <OverhaulDescription>
        Coins that are part of the bundle are closely monitored by the Coinmerce team. When a coin is no longer in the
        applicable top market cap it will get withdrawn from the bundle and be replaced with another coin that surpassed
        the former coin in market cap (e.g. in case of a top 5 bundle, when number 5 goes to the sixth place in terms of
        market cap, it will be replaced with the new number 5).
      </OverhaulDescription>
      <OtherBundlesTableWrapper>
        <OtherBundlesTableTitle>
          {formatMessage({ id: IntlKeys.websiteBundleviewOtherBundlesTitle })}
        </OtherBundlesTableTitle>
        <OtherBundlesTable />
      </OtherBundlesTableWrapper>
      <MoreCoinsModal isOpen={isModalOpen} handleClose={handleModalClose}>
        {cryptoCardsInfo.map(({ cryptoLogo, cryptoName, cryptoPercentage }) => {
          return (
            <CryptoCard
              key={cryptoName}
              cryptoLogo={cryptoLogo}
              cryptoName={cryptoName}
              cryptoPercentage={cryptoPercentage}
              showBorder={false}
              fullWidth={xxsScreenSize}
              isModalCard
            />
          );
        })}
      </MoreCoinsModal>
    </Wrapper>
  );
}

export default RightColumn;
