import styled from '@emotion/styled/macro';
// other
import { focusVisibleStyles } from 'components/styled/common';

export const Wrapper = styled.div<{ width?: number }>`
  width: ${({ width }) => (width ? width + 'px' : 'fit-content')};
  margin-top: 0;
  margin-bottom: 0;
  background-color: ${({ theme }) => theme.palette.common.infinity};
  padding: 10px 20px;
  border-radius: 6px;
  text-align: left;
`;

export const ListItem = styled.li`
  display: flex;
  border: 0;
  height: auto;
  padding: 0 4px;
`;

export const ListItemIconWrapper = styled.div`
  margin-right: 20px;
  display: flex;
`;

export const ListItemTitle = styled.div`
  display: inline-block;
  text-align: left;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.common.white};
  transition: 0.2s all;
`;

export const ListItemDescription = styled.div`
  display: block;
  text-align: left;
  margin-top: 3px;
  color: ${({ theme }) => theme.palette.common.nightOut};
  font-size: 12px;
  line-height: 14px;
`;

export const ListItemLink = styled.a`
  display: flex;
  justify-content: left;
  align-items: center;
  line-height: 64px;
  height: 64px;
  text-decoration: none;
  &:focus-visible {
    ${({ theme }) => focusVisibleStyles(theme)}
  }
  &:hover {
    ${ListItemTitle} {
      transform: translateX(12px);
    }
  }
`;

export const Link = styled.a`
  display: inline-block;
  font-size: 15px;
  line-height: 18px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.palette.common.white};
  margin: 18px 0 12px 4px;
`;
