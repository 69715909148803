import React from 'react';
// libs
import { useIntl } from 'react-intl';
// constants
import { IntlKeys } from 'localization/keys';
// styled
import { Answer, Container, QAndASection, Question, Title, Wrapper } from './styled';

const Q_AND_A_LIST = [
  {
    id: '1',
    question: IntlKeys.affiliationPageQuestionsSectionQuestion1,
    answer: IntlKeys.affiliationPageQuestionsSectionAnswer1,
  },
  {
    id: '2',
    question: IntlKeys.affiliationPageQuestionsSectionQuestion2,
    answer: IntlKeys.affiliationPageQuestionsSectionAnswer2,
  },
  {
    id: '3',
    question: IntlKeys.affiliationPageQuestionsSectionQuestion3,
    answer: IntlKeys.affiliationPageQuestionsSectionAnswer3,
  },
  {
    id: '4',
    question: IntlKeys.affiliationPageQuestionsSectionQuestion4,
    answer: IntlKeys.affiliationPageQuestionsSectionAnswer4,
  },
  {
    id: '5',
    question: IntlKeys.affiliationPageQuestionsSectionQuestion5,
    answer: IntlKeys.affiliationPageQuestionsSectionAnswer5,
  },
];

const QuestionsSection: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Title>{formatMessage({ id: IntlKeys.affiliationPageQuestionsSectionTitle })}</Title>
      <Container>
        {Q_AND_A_LIST.map(({ id, question, answer }) => (
          <QAndASection key={id}>
            <Question>{formatMessage({ id: question })}</Question>
            <Answer>{formatMessage({ id: answer })}</Answer>
          </QAndASection>
        ))}
      </Container>
    </Wrapper>
  );
};

export default QuestionsSection;
