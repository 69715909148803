import React from 'react';
// styled
import { CryptoContainer, CryptoLogo, CryptoName, CryptoPercentage, Wrapper } from './styled';

export interface CryptoCardProps {
  cryptoLogo: string;
  cryptoName: string;
  cryptoPercentage: number | string;
  showBorder?: boolean;
  fullWidth?: boolean;
  isModalCard?: boolean;
}

function CryptoCard({
  cryptoLogo,
  cryptoName,
  cryptoPercentage,
  showBorder = true,
  fullWidth = false,
  isModalCard = false,
}: CryptoCardProps) {
  return (
    <Wrapper showBorder={showBorder} fullWidth={fullWidth} isModalCard={isModalCard}>
      <CryptoContainer>
        <CryptoLogo src={cryptoLogo} alt={cryptoName} />
        <CryptoName>{cryptoName}</CryptoName>
      </CryptoContainer>
      <CryptoPercentage>{cryptoPercentage}%</CryptoPercentage>
    </Wrapper>
  );
}

export default CryptoCard;
