import React from 'react';
// contexts
import { useRegisterStepperContext } from 'providers/RegisterStepperProvider';
// styled
import { StyledIcon, StyledStep, StyledStepLabel, StyledStepper, StyledStepperConnector, Wrapper } from './styled';

export interface StepIconProps {
  active: boolean;
  completed: boolean;
  className: string;
  stepNumber: string;
}

const StepIcon: React.FC<StepIconProps> = (props) => {
  const { active, completed, className, stepNumber } = props;

  return (
    <StyledIcon ownerState={{ active }} className={className}>
      {completed ? (
        <div className="QontoStepIcon-completedIcon">{stepNumber}</div>
      ) : (
        <div className="QontoStepIcon-circle">{stepNumber}</div>
      )}
    </StyledIcon>
  );
};

export interface RegisterStepperProps {}

export const RegisterStepper: React.FC<RegisterStepperProps> = () => {
  const { activeStep, steps } = useRegisterStepperContext();

  return (
    <Wrapper>
      <StyledStepper activeStep={activeStep} connector={<StyledStepperConnector />}>
        {steps.map((label, index) => {
          return (
            <StyledStep key={label}>
              <StyledStepLabel StepIconComponent={(props) => <StepIcon stepNumber={index + 1} {...props} />}>
                {label}
              </StyledStepLabel>
            </StyledStep>
          );
        })}
      </StyledStepper>
    </Wrapper>
  );
};

export default RegisterStepper;
