import React, { useState, useRef, useMemo, useCallback } from 'react';
// libs
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// hooks
import useOnClickOutside from 'hooks/useOnClickOutside';
// store
import { uiLocaleSelector } from 'store/ui';
// other
import { Languages } from 'localization';
// icons
// import { ReactComponent as EnLangIcon } from 'assets/icons/enLang.svg';
import worldIcon from 'assets/icons/world-icon-alt.png';
import { ArrowDropDown } from '@mui/icons-material';
// styled
import { ButtonWrapper, StyledButton, Menu, StyledMenuItem, Wrapper, Image } from './styled';

const LANGUAGES = {
  en: { label: 'EN', icon: worldIcon },
  nl: { label: 'NL', icon: worldIcon },
  de: { label: 'DE', icon: worldIcon },
} as Record<keyof typeof Languages, { label: string; icon: string }>;

function LangSelect() {
  const locale = useSelector(uiLocaleSelector);
  const location = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  const handleClick = () => setOpen(!open);

  const handleClose = () => setOpen(false);

  const handleLangChange = useCallback(
    (lang: keyof typeof Languages) => () => {
      const langParam = location.pathname.slice(0, location.pathname.lastIndexOf(`/`));
      const routeWOLangParam = location.pathname.slice(langParam.length, location.pathname.length);
      navigate(`/${lang}${routeWOLangParam}`, { replace: true });
      handleClose();
    },
    [location.pathname, navigate],
  );

  const menuItems = useMemo(
    () =>
      Object.entries(LANGUAGES).map(([key, { label, icon }]) => (
        <StyledMenuItem onClick={handleLangChange(key as keyof typeof Languages)} selected={locale === key} key={key}>
          <Image src={icon} />
          {label}
        </StyledMenuItem>
      )),
    [handleLangChange, locale],
  );

  useOnClickOutside([menuRef, buttonRef], () => setOpen(false));

  return (
    <Wrapper>
      <ButtonWrapper ref={buttonRef}>
        <StyledButton
          startIcon={<Image src={LANGUAGES[locale].icon} />}
          endIcon={<ArrowDropDown />}
          onClick={handleClick}
          disableRipple
          isOpen={open}
        >
          {LANGUAGES[locale].label}
        </StyledButton>
      </ButtonWrapper>
      <Menu isOpen={open} ref={menuRef}>
        {menuItems}
      </Menu>
    </Wrapper>
  );
}

export default LangSelect;
