import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  width: 1006px;
  margin-top: 140px;

  ${({ theme }) => theme.media.md`
    width: 100%;
    margin-top: 80px;
  `}
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 48px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  line-height: 54px;
  color: ${({ theme }) => theme.palette.common.brescianBlue};

  ${({ theme }) => theme.media.md`
    font-size: 32px;
    line-height: 36px;
  `}
`;

export const Description = styled.div`
  font-size: 18px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  line-height: 36px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  margin-top: 40px;

  ${({ theme }) => theme.media.md`
    font-size: 16px;
    line-height: 28px;
  `}
`;

export const AdvantagesList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 46px;

  ${({ theme }) => theme.media.md`
    flex-direction: column;
    align-items: center;
  `}
`;

export const AdvantageItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 292px;

  ${({ theme }) => theme.media.md`
    max-width: 100%;
  `}
`;

export const AdvantageItemImage = styled.img`
  width: 100%;

  ${({ theme }) => theme.media.md`
    max-width: 100%;
  `}
`;

export const AdvantageItemImageWrapper = styled.div`
  width: 90px;
  height: 80px;
  display: flex;
  align-items: flex-end;
`;

export const AdvantageItemTitle = styled.div`
  font-size: 18px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  line-height: 36px;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
  margin-top: 14px;

  ${({ theme }) => theme.media.md`
    font-size: 16px;
    line-height: 34px;
  `}
`;

export const AdvantageItemDescription = styled.div`
  font-size: 16px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  text-align: center;
  margin-top: 14px;

  ${({ theme }) => theme.media.md`
    font-size: 14px;
    line-height: 24px;
  `}
`;

export const DescriptionHelper = styled.span`
  font-size: 18px;
  font-family: 'VisbyRoundCF Bold', sans-serif;
  line-height: 36px;
  color: ${({ theme }) => theme.palette.common.blackSpace};

  ${({ theme }) => theme.media.md`
    font-size: 16px;
    line-height: 28px;
  `}
`;
