import React, { useMemo, useRef, useState } from 'react';
// libs
import { Column, useFlexLayout, useSortBy, useTable } from 'react-table';
import { useIntl } from 'react-intl';
// material-ui
import { Theme, useMediaQuery } from '@mui/material';
// components
import MoreCoinsModal from '../MoreCoinsModal';
import CryptoCard from '../CryptoCard';
// helpers
import { roundTo2Digits } from 'helpers/renderHelpers';
// constants
import { IntlKeys } from 'localization/keys';
// icons
import { ReactComponent as SortingIcon } from 'assets/icons/sorting.svg';
import { ReactComponent as PiggyBankIcon } from 'assets/icons/table/piggy-bank.svg';
import { ReactComponent as MemeIcon } from 'assets/icons/table/meme.svg';
import { ReactComponent as MetaverseIcon } from 'assets/icons/table/metaverse.svg';
import { ReactComponent as NFTIcon } from 'assets/icons/table/nft.svg';
import { ReactComponent as OracleIcon } from 'assets/icons/table/oracle.svg';
import BTCLogo from 'assets/icons/cryptos/btc.svg';
import ETHLogo from 'assets/icons/cryptos/eth.svg';
import USDTLogo from 'assets/icons/cryptos/usdt.svg';
import XRPLogo from 'assets/icons/cryptos/xrp.svg';
import ADALogo from 'assets/icons/cryptos/ada.svg';
import SOLLogo from 'assets/icons/cryptos/sol.svg';
import DOTLogo from 'assets/icons/cryptos/dot.svg';
import TRXLogo from 'assets/icons/cryptos/trx.svg';
import SHIBLogo from 'assets/icons/cryptos/shib.svg';
import AVAXLogo from 'assets/icons/cryptos/avax.svg';
// styled
import {
  AssetCellContainer,
  AssetIconWrapper,
  AssetName,
  AssetNamesContainer,
  AssetSubName,
  BundleButtonContainer,
  BundleBuyButton,
  BundleRowContainer,
  CryptoLogo,
  CryptoLogosContainer,
  CryptosLeftText,
  FlexibleTable,
  NegativePercentage,
  PositivePercentage,
  TBody,
  TCell,
  THead,
  THeaderContainer,
  THeadRow,
  TRow,
} from './styled';

function OtherBundlesTable() {
  const { formatMessage } = useIntl();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalDataIndex, setModalDataIndex] = useState<number | undefined>();

  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const upLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const upXl = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));

  const handleModalOpen = (index: number) => () => {
    setModalDataIndex(index);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalDataIndex(undefined);
  };

  const data = React.useMemo(
    () => [
      {
        asset: { icon: <PiggyBankIcon />, name: 'DeFI Bundle', subName: 'B-DEFI' },
        change: 1.24,
        bundle: [BTCLogo, ETHLogo, USDTLogo, XRPLogo, ADALogo, SOLLogo, DOTLogo, TRXLogo, SHIBLogo, AVAXLogo],
        cryptoCardsInfo: [
          { cryptoLogo: BTCLogo, cryptoName: 'BTC', cryptoPercentage: 15 },
          { cryptoLogo: ETHLogo, cryptoName: 'ETC', cryptoPercentage: 15 },
          { cryptoLogo: USDTLogo, cryptoName: 'USDT', cryptoPercentage: 15 },
          { cryptoLogo: XRPLogo, cryptoName: 'XRP', cryptoPercentage: 15 },
          { cryptoLogo: ADALogo, cryptoName: 'ADA', cryptoPercentage: 10 },
          { cryptoLogo: SOLLogo, cryptoName: 'SOL', cryptoPercentage: 10 },
          { cryptoLogo: DOTLogo, cryptoName: 'DOT', cryptoPercentage: 5 },
          { cryptoLogo: TRXLogo, cryptoName: 'TRX', cryptoPercentage: 5 },
          { cryptoLogo: SHIBLogo, cryptoName: 'SHIB', cryptoPercentage: 5 },
          { cryptoLogo: AVAXLogo, cryptoName: 'AVAX', cryptoPercentage: 5 },
        ],
      },
      {
        asset: { icon: <MemeIcon />, name: 'Meme Bundle', subName: 'B-MEME' },
        change: 5.15,
        bundle: [ADALogo, SOLLogo, DOTLogo, TRXLogo, SHIBLogo],
        cryptoCardsInfo: [
          { cryptoLogo: ADALogo, cryptoName: 'ADA', cryptoPercentage: 10 },
          { cryptoLogo: SOLLogo, cryptoName: 'SOL', cryptoPercentage: 10 },
          { cryptoLogo: DOTLogo, cryptoName: 'DOT', cryptoPercentage: 5 },
          { cryptoLogo: TRXLogo, cryptoName: 'TRX', cryptoPercentage: 5 },
          { cryptoLogo: SHIBLogo, cryptoName: 'SHIB', cryptoPercentage: 5 },
        ],
      },
      {
        asset: { icon: <MetaverseIcon />, name: 'Metaverse Bundle', subName: 'B-META' },
        change: -29.18,
        bundle: [USDTLogo, XRPLogo, ADALogo, SOLLogo, DOTLogo],
        cryptoCardsInfo: [
          { cryptoLogo: USDTLogo, cryptoName: 'USDT', cryptoPercentage: 15 },
          { cryptoLogo: XRPLogo, cryptoName: 'XRP', cryptoPercentage: 15 },
          { cryptoLogo: ADALogo, cryptoName: 'ADA', cryptoPercentage: 10 },
          { cryptoLogo: SOLLogo, cryptoName: 'SOL', cryptoPercentage: 10 },
          { cryptoLogo: DOTLogo, cryptoName: 'DOT', cryptoPercentage: 5 },
        ],
      },
      {
        asset: { icon: <NFTIcon />, name: 'NFT Bundle', subName: 'B-NFT' },
        change: -9.38,
        bundle: [ETHLogo, XRPLogo, SOLLogo, ADALogo, USDTLogo],
        cryptoCardsInfo: [
          { cryptoLogo: ETHLogo, cryptoName: 'ETC', cryptoPercentage: 15 },
          { cryptoLogo: XRPLogo, cryptoName: 'XRP', cryptoPercentage: 15 },
          { cryptoLogo: SOLLogo, cryptoName: 'SOL', cryptoPercentage: 10 },
          { cryptoLogo: ADALogo, cryptoName: 'ADA', cryptoPercentage: 10 },
          { cryptoLogo: USDTLogo, cryptoName: 'USDT', cryptoPercentage: 15 },
        ],
      },
      {
        asset: { icon: <OracleIcon />, name: 'Oracles Bundle', subName: 'B-RCLS' },
        change: 8.24,
        bundle: [SOLLogo, SHIBLogo, TRXLogo, DOTLogo, AVAXLogo],
        cryptoCardsInfo: [
          { cryptoLogo: SOLLogo, cryptoName: 'SOL', cryptoPercentage: 10 },
          { cryptoLogo: SHIBLogo, cryptoName: 'SHIB', cryptoPercentage: 5 },
          { cryptoLogo: TRXLogo, cryptoName: 'TRX', cryptoPercentage: 5 },
          { cryptoLogo: DOTLogo, cryptoName: 'DOT', cryptoPercentage: 5 },
          { cryptoLogo: AVAXLogo, cryptoName: 'AVAX', cryptoPercentage: 5 },
        ],
      },
    ],
    [],
  );

  const refs = useRef<(HTMLDivElement | null)[]>([]);

  const { assetWidth, changeWidth, bundleWidth, actionWidth } = useMemo(() => {
    return {
      assetWidth: 106,
      changeWidth: 86,
      bundleWidth: 120,
      actionWidth: 80,
    };
  }, []);

  const hiddenColumns = useMemo(() => (downSm ? ['bundle'] : []), [downSm]);
  const initialState = { hiddenColumns };

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: formatMessage({ id: IntlKeys.websiteBundleviewOtherBundlesBundle }),
        accessor: 'asset',
        width: assetWidth,
        Cell: (props) => (
          <AssetCellContainer>
            <AssetIconWrapper>{props.value.icon}</AssetIconWrapper>
            <AssetNamesContainer>
              <AssetName>{props.value.name}</AssetName>
              <AssetSubName>{props.value.subName}</AssetSubName>
            </AssetNamesContainer>
          </AssetCellContainer>
        ),
        sortType: (a, b) => {
          if (a.values.asset.name < b.values.asset.name) {
            return -1;
          }
          if (a.values.asset.namee > b.values.asset.name) {
            return 1;
          }
          return 0;
        },
      },
      {
        Header: formatMessage({ id: IntlKeys.websiteBundleviewOtherBundlesChange }),
        accessor: 'change',
        width: changeWidth,
        Cell: (props) => {
          const isPositive = props.value >= 0;
          return isPositive ? (
            <PositivePercentage>+{roundTo2Digits(props.value)}%</PositivePercentage>
          ) : (
            <NegativePercentage>{roundTo2Digits(props.value)}%</NegativePercentage>
          );
        },
        sortType: (a, b) => Number(a.values.change) - Number(b.values.change),
      },
      {
        Header: formatMessage({ id: IntlKeys.websiteBundleviewOtherBundlesCoins }),
        accessor: 'bundle',
        disableSortBy: true,
        width: bundleWidth,
        Cell: (props) => {
          let maxTokens = 2;
          if (upLg) {
            maxTokens = 4;
          }
          if (upXl) {
            maxTokens = 5;
          }
          const tokensLeft = props.value.length - maxTokens;
          return (
            <BundleRowContainer>
              <CryptoLogosContainer ref={(ref) => (refs.current[props.row.index] = ref)}>
                {props.value.map((img: string, index: number) => {
                  if (index >= maxTokens) {
                    return null;
                  }
                  return <CryptoLogo src={img} alt="" key={index} />;
                })}
                {tokensLeft > 0 && (
                  <CryptosLeftText onClick={handleModalOpen(props.row.index)}>
                    {formatMessage({ id: IntlKeys.websiteBundleviewOtherBundlesOtherCoins }, { amount: tokensLeft })}
                  </CryptosLeftText>
                )}
              </CryptoLogosContainer>
            </BundleRowContainer>
          );
        },
      },
      {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        width: actionWidth,
        Cell: () => {
          return (
            <BundleButtonContainer>
              <BundleBuyButton>
                {formatMessage({ id: IntlKeys.websiteBundleviewOtherBundlesBuyButton })}
              </BundleBuyButton>
            </BundleButtonContainer>
          );
        },
      },
    ],
    [actionWidth, assetWidth, bundleWidth, changeWidth, upLg, upXl, hiddenColumns, formatMessage],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState,
    },
    useFlexLayout,
    useSortBy,
  );

  return (
    <>
      <FlexibleTable {...getTableProps()}>
        <THeaderContainer>
          {headerGroups.map((headerGroup, index) => (
            <THeadRow {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, i) => (
                <THead {...column.getHeaderProps(column.getSortByToggleProps())} key={i}>
                  {column.render('Header')}
                  {!column.disableSortBy && (
                    <div>
                      <SortingIcon />
                    </div>
                  )}
                </THead>
              ))}
            </THeadRow>
          ))}
        </THeaderContainer>

        <TBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <TRow {...row.getRowProps()} key={i}>
                {row.cells.map((cell, index) => {
                  return (
                    <TCell {...cell.getCellProps()} key={index}>
                      {cell.render('Cell')}
                    </TCell>
                  );
                })}
              </TRow>
            );
          })}
        </TBody>
      </FlexibleTable>
      <MoreCoinsModal isOpen={isModalOpen} handleClose={handleModalClose}>
        {modalDataIndex !== undefined &&
          data[modalDataIndex].cryptoCardsInfo.map(({ cryptoLogo, cryptoName, cryptoPercentage }) => {
            return (
              <CryptoCard
                key={cryptoName}
                cryptoLogo={cryptoLogo}
                cryptoName={cryptoName}
                cryptoPercentage={cryptoPercentage}
                showBorder={false}
                isModalCard
              />
            );
          })}
      </MoreCoinsModal>
    </>
  );
}

export default OtherBundlesTable;
