import React from 'react';
// helpers
import { roundTo5Digits } from 'helpers/renderHelpers';
// styled
import { Description, Container, Section, Title, Wrapper } from './styled';

export interface PriceCardProps {
  percent: number | string;
  percentDescription: string;
  price: number | string;
  priceDescription: string;
  paymentFrequency: string;
  paymentFrequencyDescription: string;
  currencySymbol?: string;
}

const AboutCoinCard: React.FC<PriceCardProps> = ({
  percent,
  percentDescription,
  price,
  priceDescription,
  paymentFrequency,
  paymentFrequencyDescription,
  currencySymbol = 'VRA',
}) => {
  return (
    <Wrapper>
      <Container>
        <Section>
          <Title>{typeof percent === 'string' ? percent + '%' : roundTo5Digits(percent) + '%'}</Title>
          <Description>{percentDescription}</Description>
        </Section>
        <Section>
          <Title>
            {typeof price === 'string' ? price : roundTo5Digits(price)} {currencySymbol}
          </Title>
          <Description>{priceDescription}</Description>
        </Section>
        <Section>
          <Title>{paymentFrequency}</Title>
          <Description>{paymentFrequencyDescription}</Description>
        </Section>
      </Container>
    </Wrapper>
  );
};

export default AboutCoinCard;
