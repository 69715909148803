import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { http } from 'utils/http';
import { RootState } from '../index';
import { LoginPayload, SignUpResponse } from '../types';
import { SLICE_NAMES } from '../constants';

type AuthState = {
  accessToken: string | null | undefined;
  refreshToken: string | null | undefined;
};

const initialState: AuthState = { accessToken: null, refreshToken: null };

// thunks
export const login = createAsyncThunk('auth/login', async (payload: LoginPayload) => {
  return http.post<LoginPayload>('auth/login', payload).catch((error) => error);
});

export const signIn = createAsyncThunk('auth/signIn', async (payload: LoginPayload) => {
  return http.post<LoginPayload>('auth/sign-in', payload).catch((error) => error);
});

export const refreshToken = createAsyncThunk('auth/refreshToken', async (payload, { getState }) => {
  const state = getState() as RootState;
  const refreshToken = state[SLICE_NAMES.AUTH].refreshToken;
  return http.post('auth/refresh-token', { refreshToken }).catch((error) => error);
});

const authSlice = createSlice({
  name: SLICE_NAMES.AUTH,
  initialState,
  reducers: {},
  extraReducers: {
    // login
    [login.pending.type]: (state) => {
      state.accessToken = initialState.accessToken;
    },
    [login.fulfilled.type]: (state, { payload }) => {
      const { data } = payload;
      state.accessToken = data?.access_token;
    },
    [login.rejected.type]: (state) => {
      state.accessToken = initialState.accessToken;
    },

    // signUp
    [signIn.pending.type]: (state) => {
      state.accessToken = initialState.accessToken;
      state.refreshToken = initialState.refreshToken;
    },
    [signIn.fulfilled.type]: (state, { payload }) => {
      const { data }: { data: SignUpResponse } = payload;
      state.refreshToken = data.refreshToken;
      state.accessToken = data.idToken;
      // state.userId = data?.user?.id;
    },
    [signIn.rejected.type]: (state) => {
      state.accessToken = initialState.accessToken;
      state.refreshToken = initialState.refreshToken;
    },

    // refresh token
    [refreshToken.pending.type]: () => {},
    [refreshToken.fulfilled.type]: (state, { payload }) => {
      const { data }: { data: SignUpResponse } = payload;
      state.refreshToken = data.refreshToken;
      state.accessToken = data.idToken;
    },
    [refreshToken.rejected.type]: (state) => {
      state.accessToken = initialState.accessToken;
      state.refreshToken = initialState.refreshToken;
    },
  },
});

const authSelector = (state: RootState): AuthState => state[SLICE_NAMES.AUTH];

export const authAccessTokenSelector = createSelector(authSelector, (auth) => auth.accessToken);

export default authSlice.reducer;
