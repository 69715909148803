import React, { useCallback } from 'react';
// libs
import { FieldProps } from 'formik';
// material
import { MenuItem, Checkbox, SelectProps } from '@mui/material';
// styled
import { FontIconWrapper, SelectContainer, SelectLabel, StyledMenuItem, StyledSelect } from './styled';

export type FormControlSizes = 'small' | 'medium';

export interface FormSelectComponentProps {
  options: { value: any; label: string; fontIcon?: string }[];
  clearable?: boolean;
  formControlSize: FormControlSizes;
}

export function FormSelectComponent({
  name,
  value = [],
  onBlur,
  onChange,
  label,
  multiple,
  startAdornment,
  options = [],
  clearable = false,
  formControlSize = 'small',
  ...rest
}: SelectProps & FormSelectComponentProps) {
  const renderValue = useCallback(
    (selected: any) => {
      const selectedLabels = (selected as string[]).map(
        (selectedOption) => options.find(({ value }) => value === selectedOption)!.label,
      );

      return selectedLabels.join(', ');
    },
    [options],
  );

  return (
    <SelectContainer>
      {label ? <SelectLabel>{label}</SelectLabel> : null}
      <StyledSelect
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        id={`field-${name}`}
        labelId={`field-${name}`}
        multiple={multiple}
        renderValue={multiple ? renderValue : undefined}
        size={formControlSize}
        startAdornment={startAdornment}
        {...rest}
      >
        {clearable && !multiple && <MenuItem value="">&nbsp;</MenuItem>}

        {options.map(({ label, value: optValue, fontIcon }) => (
          <StyledMenuItem key={optValue} value={optValue}>
            <FontIconWrapper>{fontIcon}</FontIconWrapper>
            {multiple && <Checkbox checked={(value as unknown[]).includes(optValue)} />}
            {label}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </SelectContainer>
  );
}

function FormSelect({
  field: { value, ...field },
  options,
  clearable,
  ...rest
}: SelectProps & FieldProps & FormSelectComponentProps) {
  return <FormSelectComponent value={value} options={options} clearable={clearable} {...field} {...rest} />;
}

export default React.memo(FormSelect);
