import React from 'react';
// libs
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
// components
import BotsTable from './BotsTable';
// store
import { uiLocaleSelector } from 'store/ui';
// constant
import { IntlKeys } from 'localization/keys';
// icons
import { ReactComponent as ArrowForward } from 'assets/icons/arrow-forward-alt.svg';
// styled
import {
  BotsLink,
  BotsSection,
  BotsSectionBanner,
  Container,
  Description,
  DescriptionSection,
  Divider,
  Title,
  Wrapper,
} from './styled';

const FutureSection: React.FC = () => {
  const locale = useSelector(uiLocaleSelector);

  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Container>
        <BotsSection>
          <BotsSectionBanner>{formatMessage({ id: IntlKeys.botsPageFutureSectionTableTitle })}</BotsSectionBanner>
          <Divider />
          <BotsTable />
          <BotsLink href={`https://coinmerce.io/${locale}/bots/`}>
            {formatMessage({ id: IntlKeys.botsPageFutureSectionTableLink })}
            <ArrowForward />
          </BotsLink>
        </BotsSection>
        <DescriptionSection>
          <Title>{formatMessage({ id: IntlKeys.botsPageFutureSectionTitle })}</Title>
          <Description>{formatMessage({ id: IntlKeys.botsPageFutureSectionDescription })}</Description>
        </DescriptionSection>
      </Container>
    </Wrapper>
  );
};

export default FutureSection;
