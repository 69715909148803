import React from 'react';
// libs
import { useIntl } from 'react-intl';
// constants
import { IntlKeys } from 'localization/keys';
// styled
import { Answer, Container, QAndASection, Question, Title, Wrapper } from './styled';

const Q_AND_A_LIST = [
  { id: '1', question: IntlKeys.botsPageFAndQSectionQuestion1, answer: IntlKeys.botsPageFAndQSectionAnswer1 },
  { id: '2', question: IntlKeys.botsPageFAndQSectionQuestion2, answer: IntlKeys.botsPageFAndQSectionAnswer2 },
  { id: '3', question: IntlKeys.botsPageFAndQSectionQuestion3, answer: IntlKeys.botsPageFAndQSectionAnswer3 },
];

const QuestionsSection: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Title>{formatMessage({ id: IntlKeys.affiliationPageQuestionsSectionTitle })}</Title>
      <Container>
        {Q_AND_A_LIST.map(({ id, question, answer }) => (
          <QAndASection key={id}>
            <Question>{formatMessage({ id: question })}</Question>
            <Answer>{formatMessage({ id: answer })}</Answer>
          </QAndASection>
        ))}
      </Container>
    </Wrapper>
  );
};

export default QuestionsSection;
