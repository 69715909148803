import React from 'react';
// components
import Router from 'components/Router';
import Notification from './components/Notification';
import useNotification from './hooks/useNotification';

const App = () => {
  const { messageId, message, type } = useNotification();

  return (
    <>
      <Router />
      <Notification key={messageId} message={message} type={type} />
    </>
  );
};

export default App;
