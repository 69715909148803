import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Row = styled.div`
  padding: 12px;
  height: 60px;
  border: 1px solid ${({ theme }) => theme.palette.common.pixelWhite};
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.palette.common.pixelWhite};
  }
`;

export const BotNameSection = styled.div`
  display: flex;
  align-items: center;
`;

export const BotTypeSection = styled.div`
  display: flex;
  align-items: center;
`;

export const BotIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;

export const Text = styled.div`
  font-size: 16px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;

export const Percents = styled.div<{ isPositive: boolean }>`
  font-size: 16px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  line-height: 19px;
  color: ${({ theme, isPositive }) =>
    isPositive ? theme.palette.common.pedestrianGreen : theme.palette.common.tomato};
  margin-left: 24px;
`;
