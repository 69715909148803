import React from 'react';
// material-ui
import { InputBaseComponentProps } from '@mui/material';
// styled
import { OptionalLabel, TextFieldContainer, TextFieldLabel, TextFiled } from './styled';

export interface FormTextFieldProps extends InputBaseComponentProps {
  optionalLabel?: string;
}

function FormTextField({
  field: _field,
  form,
  className,
  label,
  optionalLabel,
  defaultValue = '',
  size = 'small',
  ...rest
}: FormTextFieldProps) {
  const { value = defaultValue, ...field } = _field;
  const { getFieldMeta } = form;

  const { touched, error } = getFieldMeta(field.name);
  const _error = touched && error;

  return (
    <TextFieldContainer>
      {label && (
        <TextFieldLabel>
          {label}
          {optionalLabel ? <OptionalLabel>{optionalLabel}</OptionalLabel> : null}
        </TextFieldLabel>
      )}
      <TextFiled
        className={className}
        variant="outlined"
        value={value}
        size={size}
        error={Boolean(_error)}
        helperText={_error}
        {...field}
        {...rest}
      />
    </TextFieldContainer>
  );
}

export default React.memo(FormTextField);
