import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  height: fit-content;
  ${({ theme }) => theme.media.md`
    width: 100%;
  `}
`;

export const TopTenBadgeText = styled.div`
  font-size: 10px;
  line-height: 6px;
  color: ${({ theme }) => theme.palette.common.white};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFBold};
`;

export const TopTenBadgeDigit = styled.div`
  font-size: 20px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.common.white};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFBold};
`;

export const TopTenBadge = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.palette.common.grenadinePink};
`;

export const TopTenPrimaryTitle = styled.div`
  font-size: 36px;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
`;

export const TopTenSecondaryTitle = styled.div`
  font-size: 24px;
  display: flex;
  line-height: 30px;
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  align-self: flex-end;
  ${({ theme }) => theme.media.xs`
    display: none;
  `}
`;

export const TopTenContainer = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 8px;
  }
`;

export const TopTenDescription = styled.div`
  font-size: 16px;
  width: 340px;
  padding-top: 16px;
  padding-bottom: 16px;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  ${({ theme }) => theme.media.sm`
    width: 100%;
  `}
`;

export const TopTenDescriptionList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  justify-items: center;
  ${({ theme }) => theme.media.xs`
    display: none;
  `}
`;

export const TopTenTopTenDescriptionListItem = styled.div`
  display: flex;
  align-items: center;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  & > * {
    margin-right: 8px;
  }
`;
