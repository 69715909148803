import React from 'react';
// libs
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
// components
import LangSelect from '../LangSelect';
// store
import { uiLocaleSelector } from 'store/ui';
// constants
import { COINMERCE_BASE_URL } from 'constants/general';
// icons
import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';
// others
import { IntlKeys } from 'localization/keys';
// styled
import {
  ContactsContainer,
  ContactsContent,
  ContactsKVKContainer,
  ContactsLangContainer,
  ContactsListTitle,
  ContactsOfficeContainer,
  ContactsStoreContainer,
  ContactsWrapper,
  Logo,
  LogoContainer,
} from './styled';

function Contacts() {
  const { formatMessage } = useIntl();

  const locale = useSelector(uiLocaleSelector);

  return (
    <ContactsWrapper>
      <ContactsContent>
        <ContactsContainer>
          <LogoContainer>
            <Logo href={`${COINMERCE_BASE_URL}${locale}`} tabIndex={1}>
              <LogoIcon width="194px" height="38px" />
            </Logo>
          </LogoContainer>
          <ContactsKVKContainer>
            KvK / CoC 70036969
            <br />
            support@coinmerce.io
          </ContactsKVKContainer>
          <ContactsStoreContainer>
            <ContactsListTitle>{formatMessage({ id: IntlKeys.footerStore })}</ContactsListTitle>
            <br />
            Binnenhof 27
            <br />
            1181 ZH Amstelveen
            <br />
            The Netherlands
          </ContactsStoreContainer>
          <ContactsOfficeContainer>
            <ContactsListTitle>{formatMessage({ id: IntlKeys.footerOffice })}</ContactsListTitle>
            <br />
            Beechavenue 140
            <br />
            1119 PR Schiphol-Rijk
            <br />
            The Netherlands
          </ContactsOfficeContainer>
          <ContactsLangContainer>
            <LangSelect />
          </ContactsLangContainer>
        </ContactsContainer>
      </ContactsContent>
    </ContactsWrapper>
  );
}

export default Contacts;
