// @ts-ignore
import numberFormatter from 'number-formatter';

const thousandSeparator = '.';
const decimalSeparator = ',';

export const isNumeric = (value: any) => {
  const asNumber = Number(value);
  return asNumber && !isNaN(asNumber) && isFinite(asNumber);
};

export const formatPercentsToNDigits = (n: number) => {
  // 0 - trailing zeros are not omitted / # - omitted
  const decimalSigns = new Array(n).fill('0').join('');
  const mask = `#${thousandSeparator}##0${decimalSeparator}${decimalSigns || '#'}%`;

  return (value: number) => {
    return numberFormatter(mask, Number(value).toFixed(n));
  };
};

export const formatPercentsToNDigitsFloating = (n: number) => {
  // 0 - trailing zeros are not omitted / # - omitted
  const decimalSigns = new Array(n).fill('#').join('');
  const mask = `#${thousandSeparator}##0${decimalSeparator}${decimalSigns || '#'}%`;

  return (value: number) => {
    return value || value === 0 ? numberFormatter(mask, Number(value * 100).toFixed(n)) : value;
  };
};

export const roundToNDigits = (n: number) => {
  // 0 - trailing zeros are not omitted / # - omitted
  const decimalSigns = new Array(n).fill('0').join('');
  const mask = `#${thousandSeparator}##0${decimalSeparator}${decimalSigns || '#'}`;

  return (value: number) => numberFormatter(mask, Number(value).toFixed(n));
};

export const roundToNDigitsFloating = (n: number) => {
  // 0 - trailing zeros are not omitted / # - omitted
  const decimalSigns = new Array(n).fill('#').join('');
  const mask = `#${thousandSeparator}##0${decimalSeparator}${decimalSigns || '#'}`;

  return (value: number) => {
    return numberFormatter(mask, Number(value).toFixed(n));
  };
};

export const suffixFormatter =
  (formatter: (value: number) => any, suffix = '', prefix = '') =>
  (value: number) =>
    formatter(value) ? `${prefix}${formatter(value)}${suffix}` : '';

export const roundToInteger = roundToNDigits(0); // 3.3333 = 3
export const roundTo2Digits = roundToNDigits(2); // 3.3333 = 3.33, 3.3 = 3.30
export const roundTo3Digits = roundToNDigits(3); // 3.3333 = 3.333, 3.3 = 3.300
export const roundTo4Digits = roundToNDigits(4); // 3.3333 = 3.3333, 3.3 = 3.3000
export const roundTo5Digits = roundToNDigits(5); // 3.3333 = 3.3333, 3.3 = 3.3000

export const roundTo1DigitsFloating = roundToNDigitsFloating(1); // 3.3333 = 3
export const roundTo2DigitsFloating = roundToNDigitsFloating(2); // 3.3333 = 3.33, 3.3 = 3.3
export const roundTo3DigitsFloating = roundToNDigitsFloating(3); // 3.3333 = 3.333, 3.3 = 3.3
export const roundTo4DigitsFloating = roundToNDigitsFloating(4); // 3.3333 = 3.3333, 3.3 = 3.3
export const roundTo5DigitsFloating = roundToNDigitsFloating(5); // 3.33335 = 3.33335, 3.3 = 3.3

export const formatPercentsToInteger = formatPercentsToNDigits(0); // 0.033333 = 3%
export const formatPercentsTo1Digits = formatPercentsToNDigits(1); // 0.033333 = 3.3%, 0.03 = 3.0%
export const formatPercentsTo2Digits = formatPercentsToNDigits(2); // 0.033333 = 3.33%, 0.03 = 3.00%

export const formatPercentsTo1DigitsFloating = formatPercentsToNDigitsFloating(1); // 0.033333 = 3.3%, 0.03 = 3%
export const formatPercentsTo2DigitsFloating = formatPercentsToNDigitsFloating(2); // 0.033333 = 3.33%, 0.03 = 3%
