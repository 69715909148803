import React, { memo } from 'react';
import { List, ListItem, Title, Wrapper } from './styled';
import { roundTo2Digits } from 'helpers/renderHelpers';

export interface CustomTooltipProps {
  position: { top: number; left: number };
  visible: boolean;
  data: any;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ data, position, visible }) => {
  return (
    <Wrapper visible={visible} position={position}>
      {data && (
        <>
          <Title>{data.title}</Title>

          <List>
            {data.dataPoints.map((val: any, index: any) => {
              return (
                <ListItem key={index}>
                  {roundTo2Digits(val?.raw)}
                  {val?.dataset.label}
                </ListItem>
              );
            })}
          </List>
        </>
      )}
    </Wrapper>
  );
};

export default memo(CustomTooltip);
