import { IntlKeys } from '../keys';

export const en = {
  // common
  [IntlKeys.commonClose]: 'Close',
  [IntlKeys.commonLorem]: 'Lorem ipsum',
  [IntlKeys.commonAccept]: 'Accept',
  [IntlKeys.commonCancel]: 'Cancel',
  [IntlKeys.commonCreate]: 'Create',

  // header
  [IntlKeys.headerHome]: 'Home',
  [IntlKeys.headerCoins]: 'Coins',
  [IntlKeys.headerNfts]: 'NFTs',
  [IntlKeys.headerWallets]: 'Wallets',
  [IntlKeys.headerOrders]: 'Orders',
  [IntlKeys.headerExplore]: 'Explore',
  [IntlKeys.headerExplorePopoverTitle]: 'An all-in-one platform for the future of money',
  [IntlKeys.headerExplorePopoverProducts]: 'Products',
  [IntlKeys.headerExplorePopoverAbout]: 'About',
  [IntlKeys.headerExplorePopoverProductItemsProofTitle]: 'Proof of State',
  [IntlKeys.headerExplorePopoverProductItemsBotsTitle]: 'COINMERCE Bots',
  [IntlKeys.headerExplorePopoverProductItemsBeginnersTitle]: "Beginner's Trading",
  [IntlKeys.headerExplorePopoverProductItemsAdvancedTitle]: 'Advanced Tools',
  [IntlKeys.headerExplorePopoverProductItemsGiftcardsTitle]: 'Giftcards',
  [IntlKeys.headerExplorePopoverProductItemsProofDescription]: 'Grow your holding',
  [IntlKeys.headerExplorePopoverProductItemsBotsDescription]: 'AI assisted algorithms',
  [IntlKeys.headerExplorePopoverProductItemsBeginnersDescription]: 'Start with Bitcoin and Crypto',
  [IntlKeys.headerExplorePopoverProductItemsAdvancedDescription]: 'Limits, repeating orders, more',
  [IntlKeys.headerExplorePopoverProductItemsGiftcardsDescription]: 'Give crypto as a gift!',
  [IntlKeys.headerExplorePopoverAboutItemsAboutTitle]: 'About Coinmerce',
  [IntlKeys.headerExplorePopoverAboutItemsCryptoTitle]: 'Crypto Information Center',
  [IntlKeys.headerExplorePopoverAboutItemsNewsTitle]: 'News',
  [IntlKeys.headerExplorePopoverAboutItemsLearnTitle]: 'Learn',
  [IntlKeys.headerExplorePopoverAboutItemsHelpTitle]: 'Need Help?',
  [IntlKeys.headerExplorePopoverAboutItemsVacanciesTitle]: 'Vacancies',
  [IntlKeys.headerExplorePopoverAboutItemsAboutDescription]: 'Team, Values, Goals',
  [IntlKeys.headerExplorePopoverAboutItemsCryptoDescription]: 'Our crypto shop',
  [IntlKeys.headerExplorePopoverAboutItemsNewsDescription]: 'The latest about crypto',
  [IntlKeys.headerExplorePopoverAboutItemsLearnDescription]: 'Tutorials and Protips',
  [IntlKeys.headerExplorePopoverAboutItemsHelpDescription]: 'Support and Contact',
  [IntlKeys.headerExplorePopoverAboutItemsVacanciesDescription]: 'Available jobs',
  [IntlKeys.headerAccountPopoverTitle]: 'My Account',
  [IntlKeys.headerAccountPopoverProfile]: 'Profile',
  [IntlKeys.headerAccountPopoverAccountLevel]: 'Account Level',
  [IntlKeys.headerAccountPopoverSecurity]: 'Security',
  [IntlKeys.headerAccountPopoverSettings]: 'Settings',
  [IntlKeys.headerAccountPopoverFriends]: 'Friends',
  [IntlKeys.headerAccountPopoverWithdrawAddresses]: 'Withdraw Addresses',
  [IntlKeys.headerAccountPopoverLogout]: 'Logout',
  [IntlKeys.headerNFTPopoverCreate]: 'Create',
  [IntlKeys.headerNFTPopoverMarketplace]: 'Marketplace',
  [IntlKeys.headerNFTPopoverMyCollections]: 'My Collections',
  [IntlKeys.headerNFTPopoverLaunchpad]: 'Launchpad',
  [IntlKeys.headerItemCoins]: 'Coins',
  [IntlKeys.headerItemProducts]: 'Products',
  [IntlKeys.headerItemGetTheApp]: 'Get the app',
  [IntlKeys.headerCoinsPopoverMenuLink]: 'Bekijk alle cryptocurrencies',
  [IntlKeys.headerItemPassiveIncome]: 'Passief inkomen',
  [IntlKeys.headerPassiveIncomePopoverItemTitle1]: 'Earn',
  [IntlKeys.headerPassiveIncomePopoverItemTitle2]: 'Staking',
  [IntlKeys.headerPassiveIncomePopoverItemTitle3]: 'Affiliateprogramma',
  [IntlKeys.headerPassiveIncomePopoverItemDescription1]: 'Uitlenen wordt beloond',
  [IntlKeys.headerPassiveIncomePopoverItemDescription2]: 'Laat je bezit groeien',
  [IntlKeys.headerPassiveIncomePopoverItemDescription3]: 'Verwijs vrienden en verdien',
  [IntlKeys.headerItemBots]: 'Bots',
  [IntlKeys.headerBotsPopoverItemTitle1]: 'Wat zijn bots?',
  [IntlKeys.headerBotsPopoverItemDescription1]: 'Uitleg over algoritmisch handelen',
  [IntlKeys.headerBotsPopoverItemLink]: 'Bekijk alle Bots',
  [IntlKeys.headerItemLearn]: 'Leren',
  [IntlKeys.headerLearnPopoverItemTitle1]: 'Nieuws',
  [IntlKeys.headerLearnPopoverItemTitle2]: 'Kennisbank',
  [IntlKeys.headerLearnPopoverItemTitle3]: 'Meestgestelde vragen',
  [IntlKeys.headerLearnPopoverItemTitle4]: 'Handelen voor beginners',
  [IntlKeys.headerLearnPopoverItemTitle5]: 'Geavanceerde tools',
  [IntlKeys.headerLearnPopoverItemDescription1]: 'Het laatste cryptonieuws',
  [IntlKeys.headerLearnPopoverItemDescription2]: 'Definities, tutorials en pro tips',
  [IntlKeys.headerLearnPopoverItemDescription3]: 'Support en contact',
  [IntlKeys.headerLearnPopoverItemDescription4]: 'Start met Bitcoin en crypto',
  [IntlKeys.headerLearnPopoverItemDescription5]: 'Limieten, herhaalorders & meer',
  [IntlKeys.headerItemAbout]: 'Over',
  [IntlKeys.headerAboutPopoverItemTitle1]: 'Download app',
  [IntlKeys.headerAboutPopoverItemTitle2]: 'Handleiding',
  [IntlKeys.headerAboutPopoverItemTitle3]: 'Over Coinmerce',
  [IntlKeys.headerAboutPopoverItemTitle4]: 'Vacatures',
  [IntlKeys.headerAboutPopoverItemDescription1]: 'Je portfolio in je broekzak',
  [IntlKeys.headerAboutPopoverItemDescription2]: 'Voor een goede start',
  [IntlKeys.headerAboutPopoverItemDescription3]: 'Team, waarden & doelen',
  [IntlKeys.headerAboutPopoverItemDescription4]: 'Kom je bij ons werken?',
  [IntlKeys.headerLoginButton]: 'Log in',
  [IntlKeys.headerRegisterButton]: 'Registreer nu',

  // footer
  [IntlKeys.footerLoginTitle]: 'Crypto is easy now.',
  [IntlKeys.footerLoginButton]: 'Log In',
  [IntlKeys.footerSignupButton]: 'Sign Up',
  [IntlKeys.footerListGeneral]: 'General',
  [IntlKeys.footerListGeneralAboutUs]: 'About us',
  [IntlKeys.footerListGeneralCareers]: 'Careers',
  [IntlKeys.footerListGeneralCoins]: 'Coins',
  [IntlKeys.footerListGeneralFraudPrevention]: 'Fraud prevention',
  [IntlKeys.footerListGeneralBugFound]: 'Bug found?',
  [IntlKeys.footerListGeneralApi]: 'API',
  [IntlKeys.footerListGeneralSitemap]: 'Sitemap',
  [IntlKeys.footerListPopular]: 'Popular',
  [IntlKeys.footerListPopularBuyBitcoin]: 'Buy Bitcoin',
  [IntlKeys.footerListPopularBitcoinPrice]: 'Bitcoin Price',
  [IntlKeys.footerListPopularBuyEthereum]: 'Buy Ethereum',
  [IntlKeys.footerListPopularEthereumPrice]: 'Ethereum Price',
  [IntlKeys.footerListPopularBuyRipple]: 'Buy Ripple',
  [IntlKeys.footerListPopularRipplePrice]: 'Ripple Price',
  [IntlKeys.footerListPopularBuyLota]: 'Buy lota',
  [IntlKeys.footerListPopularLotaPrice]: 'lota Price',
  [IntlKeys.footerListLegal]: 'Legal',
  [IntlKeys.footerListLegalTermsAndConditions]: 'Terms & Conditions',
  [IntlKeys.footerListLegalPrivacyPolicy]: 'Privacy Policy',
  [IntlKeys.footerListLegalRiskDisclaimer]: 'Risk Disclaimer',
  [IntlKeys.footerListJumpTo]: 'Jump to',
  [IntlKeys.footerListJumpToRegister]: 'Register',
  [IntlKeys.footerListJumpToLogin]: 'Login',
  [IntlKeys.footerListJumpToNeedHelp]: 'Need help?',
  [IntlKeys.footerListJumpToLearn]: 'Learn',
  [IntlKeys.footerListJumpToNewsAndBlog]: 'News & Blog',
  [IntlKeys.footerStore]: 'Store',
  [IntlKeys.footerOffice]: 'Office',
  [IntlKeys.footerStartInvestmentTitle]: 'Start met investeren',
  [IntlKeys.footerRegisterButton]: 'Registreer nu',
  [IntlKeys.footerLoginText]: 'Heb je al een account?',
  [IntlKeys.footerLoginLinkText]: 'Log in',
  [IntlKeys.footerListTradeTitle]: 'Handel',
  [IntlKeys.footerListTradeItem1]: 'Bots',
  [IntlKeys.footerListTradeItem2]: 'Cryptocurrencies',
  [IntlKeys.footerListTradeItem3]: 'Earn',
  [IntlKeys.footerListTradeItem4]: 'Staking',
  [IntlKeys.footerListPopularTitle]: 'Popular',
  [IntlKeys.footerListPopularItem1]: 'Bitcoin kopen',
  [IntlKeys.footerListPopularItem2]: 'Dent kopen',
  [IntlKeys.footerListPopularItem3]: 'Ethereum kopen',
  [IntlKeys.footerListPopularItem4]: 'HNT kopen',
  [IntlKeys.footerListPopularItem5]: 'Litecoin kopen',
  [IntlKeys.footerListPopularItem6]: 'Matic kopen',
  [IntlKeys.footerListPopularItem7]: 'Polkadot kopen',
  [IntlKeys.footerListPopularItem8]: 'Ripple kopen',
  [IntlKeys.footerListPopularItem9]: 'Solana kopen',
  [IntlKeys.footerListPopularItem10]: 'Verasity kopen',
  [IntlKeys.footerListLearnTitle]: 'Leren',
  [IntlKeys.footerListLearnItem1]: 'Nieuws',
  [IntlKeys.footerListLearnItem2]: 'Kennisbank',
  [IntlKeys.footerListLearnItem3]: 'Meestgestelde vragen',
  [IntlKeys.footerListLearnItem4]: 'Geavanceerde tools',
  [IntlKeys.footerListLearnItem5]: 'Handelen voor beginners',
  [IntlKeys.footerListLegalTitle]: 'Juridisch',
  [IntlKeys.footerListLegalItem1]: 'Algemene voorwaarden',
  [IntlKeys.footerListLegalItem2]: 'Privacy policy',
  [IntlKeys.footerListLegalItem3]: 'Risicodisclaimer',
  [IntlKeys.footerListGeneralTitle]: 'Algemeen',
  [IntlKeys.footerListGeneralItem1]: 'Affiliateprogramma',
  [IntlKeys.footerListGeneralItem2]: 'Bug gevonden?',
  [IntlKeys.footerListGeneralItem3]: 'Handleiding',
  [IntlKeys.footerListGeneralItem4]: 'Ons verhaal',
  [IntlKeys.footerListGeneralItem5]: 'Vacatures',

  // single coin view
  [IntlKeys.websiteSinglecoinBreadcrumbCoins]: 'Coins',
  [IntlKeys.websiteSinglecoinIntro]: 'Buy {coin} safely with SEPA, iDEAL, Bancontact, SEPA, Sofort or credit card on Coinmerce.',
  [IntlKeys.websiteSinglecoinUsp1]: 'Safely buy 200+ cryptocurrencies',
  [IntlKeys.websiteSinglecoinUsp2]: 'EUR platform founded in 2017',
  [IntlKeys.websiteSinglecoinUsp3]: '100,000+ users preceded you',
  [IntlKeys.websiteSinglecoinUsp4]: 'Helpful support team',
  [IntlKeys.websiteSinglecoinH1]: 'Buy {coin} with iDEAL or SEPA',
  [IntlKeys.websiteSinglecoinH2Price]: '{coin} price',
  [IntlKeys.websiteSinglecoinH2PriceCoin]: '{coin} price in euro',
  [IntlKeys.websiteSinglecoinH2PriceVolume]: '{coin} volume (24h)',
  [IntlKeys.websiteSinglecoinH2PriceMarketcap]: 'Market cap',
  [IntlKeys.websiteSinglecoinEarnH2]: 'Earn {coin}',
  [IntlKeys.websiteSinglecoinEarnRewards]: 'Est. yearly rewards',
  [IntlKeys.websiteSinglecoinEarnMinimum]: 'Minimum holding',
  [IntlKeys.websiteSinglecoinEarnFrequencyTitle]: 'Pay frequency',
  [IntlKeys.websiteSinglecoinEarnFrequencyTime]: 'Weekly',
  [IntlKeys.websiteSinglecoinEarnCalculateRewards]: 'Calculate Earn rewards',
  [IntlKeys.websiteSinglecoinEarn1Year]: '1 year',
  [IntlKeys.websiteSinglecoinEarn2Year]: '2 years',
  [IntlKeys.websiteSinglecoinEarn5Year]: '5 years',
  [IntlKeys.websiteSinglecoinEarnAsterisk]: '*Rewards are calculated weekly. Percentages are not guaranteed.',
  [IntlKeys.websiteSinglecoinEarnButton]: 'Activate Earn now',
  [IntlKeys.websiteSinglecoinStakingH2]: 'Stake {amount}',
  [IntlKeys.websiteSinglecoinStakingRewards]: 'Est. yearly rewards',
  [IntlKeys.websiteSinglecoinStakingMinimum]: 'Minimum holding',
  [IntlKeys.websiteSinglecoinStakingFrequencyTitle]: 'Pay frequency',
  [IntlKeys.websiteSinglecoinStakingFrequencyTime]: 'Weekly',
  [IntlKeys.websiteSinglecoinStakingCalculateRewards]: 'Calculate staking rewards',
  [IntlKeys.websiteSinglecoinStaking1Year]: '1 year',
  [IntlKeys.websiteSinglecoinStaking2Year]: '2 years',
  [IntlKeys.websiteSinglecoinStaking5Year]: '5 years',
  [IntlKeys.websiteSinglecoinStakingAsterisk]: '*Rewards are calculated weekly. Percentages are not guaranteed.',
  [IntlKeys.websiteSinglecoinStakingButton]: 'Start flexible staking now',
  [IntlKeys.websiteSinglecoinAboutTitle]: 'About {coin}',
  [IntlKeys.websiteSinglecoinAboutWhat]: 'What is {coin}?',
  [IntlKeys.websiteSinglecoinReadmore]: 'Read more',
  [IntlKeys.websiteSinglecoinAblutHow]: 'How does {coin} work?',
  [IntlKeys.websiteSinglecoinAblutBuy]: 'How to buy {coin}?',
  [IntlKeys.websiteSinglecoinPopularTitle]: 'Popular assets',
  [IntlKeys.websiteSinglecoinPopularDescription]: 'Find the most traded assets on Coinmerce below or view {link}.',
  [IntlKeys.websiteSinglecoinPopularDescriptionAllCrypto]: 'all cryptocurrencies',
  [IntlKeys.websiteSinglecoinPopularAsset]: 'Asset',
  [IntlKeys.websiteSinglecoinPopularBalance]: 'Balance',
  [IntlKeys.websiteSinglecoinPopularPrice]: 'Price',
  [IntlKeys.websiteSinglecoinPopularChange]: 'Change',
  [IntlKeys.websiteSinglecoinPopularType]: 'Type',
  [IntlKeys.websiteSinglecoinPopularActiveEarn]: 'Activate Earn',
  [IntlKeys.websiteSinglecoinPopularActiveStaking]: 'Activate Staking',
  [IntlKeys.websiteSinglecoinPopularBuy]: 'Buy',
  [IntlKeys.websiteSinglecoinPopularSell]: 'Sell',
  [IntlKeys.websiteSinglecoinPopularDeposit]: 'Deposit',
  [IntlKeys.websiteSinglecoinPopularWithdraw]: 'Withdraw',
  [IntlKeys.websiteSinglecoinPopularViewAllCrypto]: 'View all cryptocurrencies',
  [IntlKeys.websiteSinglecoinBuyButtonMobile]: 'View all cryptocurrencies',
  [IntlKeys.websiteSinglecoinBuyItem]: 'Buy',
  [IntlKeys.websiteSinglecoinSellItem]: 'Sell',
  [IntlKeys.websiteSinglecoinBuyButton]: 'Buy now',
  [IntlKeys.websiteSinglecoinSellButton]: 'Sell now',
  [IntlKeys.websiteSinglecoinBuyButtonCoin]: 'Buy {coin} now',
  [IntlKeys.websiteSinglecoinOrderLimit]: 'Order stop limit',
  [IntlKeys.websiteSinglecoinRepeatOrder]: 'Repeat order',
  [IntlKeys.websiteSinglecoinSwapItem]: 'Swap',
  [IntlKeys.websiteSinglecoinSwapButton]: 'Swap now',

  // single bundle view
  [IntlKeys.websiteBundleviewBreadcrumbBundles]: 'Bundles',
  [IntlKeys.websiteBundleviewDescription]: 'Buy the {bundle} bundle ({bundleShort}) with iDEAL, Bancontact, credit card or Coinmerce Coins (CM).',
  [IntlKeys.websiteBundleviewBuyItem]: 'Buy',
  [IntlKeys.websiteBundleviewSellItem]: 'Sell',
  [IntlKeys.websiteBundleviewBuyButton]: 'Buy now',
  [IntlKeys.websiteBundleviewSellButton]: 'Sell now',
  [IntlKeys.websiteBundleviewBuyButtonMobile]: 'Buy {bundle} now',
  [IntlKeys.websiteBundleviewMoreButtonMobile]: 'More',
  [IntlKeys.websiteBundleviewMax]: 'Max',
  [IntlKeys.websiteBundleviewUsp1]: 'Diversified investing',
  [IntlKeys.websiteBundleviewUsp2]: 'Automatically rebalanced',
  [IntlKeys.websiteBundleviewUsp3]: '4.2/5 based on {link}',
  [IntlKeys.websiteBundleviewUsp3Link]: '350 reviews',
  [IntlKeys.websiteBundleviewUsp4]: 'Trusted by 100,000+ users',
  [IntlKeys.websiteBundleviewOtherBundlesTitle]: 'Other bundles',
  [IntlKeys.websiteBundleviewOtherBundlesDescription]: 'A selection of available bundles at Coinmerce. Reduce risk through diversification. Easily invest in selection of a specific cryptocurrency category. ',
  [IntlKeys.websiteBundleviewOtherBundlesBundle]: 'Bundle',
  [IntlKeys.websiteBundleviewOtherBundlesChange]: 'Change',
  [IntlKeys.websiteBundleviewOtherBundlesCoins]: 'Coins included',
  [IntlKeys.websiteBundleviewOtherBundlesBuyButton]: 'Buy now',
  [IntlKeys.websiteBundleviewOtherBundlesOtherCoins]: '+{amount} others',
  [IntlKeys.websiteBundleviewH1]: '{bundle} bundle rate',
  [IntlKeys.websiteBundleviewH2]: 'Redistribute Bundles',

  // toasts
  [IntlKeys.toastBidSubmitted]: 'Bid is successfully submitted',
  [IntlKeys.toastSomethingWentWrong]: 'Something went wrong',
  [IntlKeys.toastBidCancelled]: 'Bid is successfully cancelled',
  [IntlKeys.toastListingCreated]: 'Listing is successfully created',
  [IntlKeys.toastListingUpdated]: 'Listing is successfully updated',
  [IntlKeys.toastBidApproved]: 'Bid is successfully approved',
  [IntlKeys.toastAllBidsDeclined]: 'All active bids are successfully declined',
  [IntlKeys.toastCollectionCreated]: 'Collection is successfully created',
  [IntlKeys.toastCollectionUpdated]: 'Collection is successfully updated',
  [IntlKeys.toastCollectionNameUpdated]: 'Collection name is successfuly updated',
  [IntlKeys.toastListingRemoved]: 'Listing is successfully removed',

  // validation
  [IntlKeys.validationRequired]: 'Required',
  [IntlKeys.validationBidShouldBeEqualOrBigger]: 'Bid should be equal or bigger than {value}',
  [IntlKeys.validationShouldBeBiggerThanStartingPrice]: 'Should be bigger than starting price',

  // affiliation page
  [IntlKeys.affiliationPageAboutSectionTitle]: 'Het beste crypto affiliate-programma in Nederland',
  [IntlKeys.affiliationPageAboutSectionDescription]: 'Help jij ons om de wereld kennis te laten maken met crypto? Word een affiliate partner en bouw ongelimiteerde commissies op.',
  [IntlKeys.affiliationPageAboutSectionListItem1]: 'Hoogste commissiepercentage in Nederland',
  [IntlKeys.affiliationPageAboutSectionListItem2]: 'Levenslange commissies',
  [IntlKeys.affiliationPageAboutSectionListItem3]: 'Geen grenzen op wat je kan verdienen',
  [IntlKeys.affiliationPageAboutSectionListItem4]: 'Uniek wereldwijd: 10% commissie op staking',
  [IntlKeys.affiliationPageAboutSectionButton]: 'Aanmelden als partner',
  [IntlKeys.affiliationPageAdvantagesSectionTitle]: 'De voordelen',
  [IntlKeys.affiliationPageAdvantagesSectionDescription]: 'Bouw een passief inkomen op en geef niet alleen jouw geld toekomst, maar ook die van je referrals.',
  [IntlKeys.affiliationPageAdvantagesSectionAdvantageTitle1]: 'Hoge commissies',
  [IntlKeys.affiliationPageAdvantagesSectionAdvantageTitle2]: 'No limits',
  [IntlKeys.affiliationPageAdvantagesSectionAdvantageTitle3]: 'Levenslang voordeel',
  [IntlKeys.affiliationPageAdvantagesSectionAdvantageDescription1]: 'Verwijs al je vrienden, collega’s, buren of familie en krijg {helper} van alle transactiekosten.',
  [IntlKeys.affiliationPageAdvantagesSectionAdvantageDescription2]: 'Inkomsten via het affiliateprogramma zijn {helper} geldig. Er zit geen limiet op wat je kan verdienen.',
  [IntlKeys.affiliationPageAdvantagesSectionAdvantageDescription3]: 'De commissies lopen een leven lang door. Je blijft beloningen ontvangen zolang jij een account hebt.',
  [IntlKeys.affiliationPageAdvantagesSectionAdvantageDescriptionHelper1]: '0,30%',
  [IntlKeys.affiliationPageAdvantagesSectionAdvantageDescriptionHelper2]: 'ongelimiteerd',
  [IntlKeys.affiliationPageWhyCoinmerceSectionTitle]: 'Waarom Coinmerce?',
  [IntlKeys.affiliationPageWhyCoinmerceSectionDescription]: 'Het in Nederland opgerichte cryptoplatform met een focus op eenvoud en veiligheid.',
  [IntlKeys.affiliationPageWhyCoinmerceSectionListItem1]: 'Eenvoudig crypto kopen',
  [IntlKeys.affiliationPageWhyCoinmerceSectionListItem2]: 'Meer dan 200 crypto’s',
  [IntlKeys.affiliationPageWhyCoinmerceSectionListItem3]: 'Behulpzame supportafdeling',
  [IntlKeys.affiliationPageWhyCoinmerceSectionListItem4]: 'Snel afrekenen met iDEAL en Bancontact',
  [IntlKeys.affiliationPageWhyCoinmerceSectionButton]: 'Registreer nu',
  [IntlKeys.affiliationPageThreeStepsSectionTitle]: 'In 3 stappen starten',
  [IntlKeys.affiliationPageThreeStepsSectionDescription]: 'Verdien geld vanuit je luie stoel! Coinmerce biedt verschillende opties om slapend rijk te worden.',
  [IntlKeys.affiliationPageThreeStepsSectionStepTitle1]: 'Maak een account',
  [IntlKeys.affiliationPageThreeStepsSectionStepTitle2]: 'Deel jouw link',
  [IntlKeys.affiliationPageThreeStepsSectionStepTitle3]: 'Verdien commissies',
  [IntlKeys.affiliationPageThreeStepsSectionStepDescription1]: 'Maak een eigen account aan op Coinmerce. Ervaar de voordelen die Coinmerce jou te bieden heeft en ontvang jouw eigen unieke referral link.',
  [IntlKeys.affiliationPageThreeStepsSectionStepDescription2]: 'Deel jouw unieke referral link met jouw netwerk. Nodig je vrienden, collega’s, buren en familie uit of verwerk jouw link op je YouTube-kanaal, blog of socialmediaprofiel.',
  [IntlKeys.affiliationPageThreeStepsSectionStepDescription3]: 'Het ontvangen van de affiliate commissies is nu van start gegaan! Hoe meer mensen gebruikmaken van Coinmerce via jouw unieke link, hoe meer commissie jij ontvangt.',
  [IntlKeys.affiliationPagePromotionalMaterialSectionTitle]: 'Promotiemateriaal',
  [IntlKeys.affiliationPagePromotionalMaterialSectionDescription]: 'Zelf geen tijd of niet heel handig met Photoshop? Geen probleem! We hebben een uitgebreide omgeving met promotiemateriaal. Afbeelingen, HTML banners en video’s. Mis je iets? Laat het weten! Dan gaan we regelen dat het er komt.',
  [IntlKeys.affiliationPagePromotionalMaterialSectionButton]: 'Bekijk promotiemateriaal',
  [IntlKeys.affiliationPageCryptoRateWidgetSectionTitle]: 'Cryptokoers widget',
  [IntlKeys.affiliationPageCryptoRateWidgetSectionDescription]: 'De koers van een coin op je website laten zien? Dat doe je makkelijk met een zogeheten embed. In het Nederlands wordt dat ook wel insluiten genoemd. Je laat met een link eenvoudig de statistieken van je gewenste coin(s) zien. Geen developers voor nodig! Je doet dat eenvoudig door een linkje te plaatsen.',
  [IntlKeys.affiliationPageCryptoRateWidgetSectionButton]: 'Ga naar uitleg',
  [IntlKeys.affiliationPageQuestionsSectionTitle]: 'Vragen over het programma',
  [IntlKeys.affiliationPageQuestionsSectionQuestion1]: 'Waar vind ik mijn unieke referral link?',
  [IntlKeys.affiliationPageQuestionsSectionQuestion2]: 'Wat houdt commissie op staking in?',
  [IntlKeys.affiliationPageQuestionsSectionQuestion3]: 'Wanneer ontvang ik mijn commissies die ik heb verdiend via het programma?',
  [IntlKeys.affiliationPageQuestionsSectionQuestion4]: 'Wat zijn de makkelijkste manieren om zoveel mogelijk affiliates te bereiken?',
  [IntlKeys.affiliationPageQuestionsSectionQuestion5]: 'Kan ik referrals via een zakelijk account ontvangen?',
  [IntlKeys.affiliationPageQuestionsSectionAnswer1]: 'Via de Coinmerce app of website vind je heel makkelijk jouw eigen unieke referral link. Deze link is weergeven in jouw Coinmerce-account onder ‘Beloningen’. Op deze pagina kan je ook makkelijk jouw referral link delen met jouw netwerk.',
  [IntlKeys.affiliationPageQuestionsSectionAnswer2]: 'Staking is een manier om passief inkomen te genereren. Crypto verdienen met crypto. Als je referral crypto staket, ontvang je daar 10% commissie op. Staket jouw referral 10.000 coins met 10% aan beloningen op jaarbasis, dan krijgt hij 1.000 coins en jij 100. Die commissie krijg je volledig van ons, dat gaat niet van de beloningen van je referral af.',
  [IntlKeys.affiliationPageQuestionsSectionAnswer3]: 'Je beloning wordt direct verwerkt en verschijnt binnen enkele seconden na het afronden van een transactie in jouw account.',
  [IntlKeys.affiliationPageQuestionsSectionAnswer4]: 'Er zijn verschillende manieren om een affiliate marketing plan voor jezelf op te zetten. Coinmerce raad aan om te kijken naar waar jouw netwerk het meest actief is. Dit kan bijvoorbeeld zijn op socialmediakanalen, een blog, prijsvergelijker of nieuwsbrief. Daarnaast is het belangrijk om de unieke voordelen van het Coinmerce affiliate program te melden aan jouw netwerk.',
  [IntlKeys.affiliationPageQuestionsSectionAnswer5]: 'Ja en nee. We bieden momenteel geen zakelijke accounts aan. Wel kan je een persoonlijk account openen en via ons op jouw bedrijfsnaam laten zetten. Let op: je kan niet traden met dit soort accounts, alleen referralcommissies naar je bankrekening sturen.',
  [IntlKeys.affiliationPageContactUsSectionTitle]: 'Vragen?',
  [IntlKeys.affiliationPageContactUsSectionDescription]: 'Ons supportteam zit klaar voor je om al je vragen te beantwoorden.',
  [IntlKeys.affiliationPageContactUsSectionSocial1]: 'Facebook Messenger',
  [IntlKeys.affiliationPageContactUsSectionSocial2]: 'Email',

  // bots page
  [IntlKeys.botsPageAutomaticTradingSectionTitle]: 'Bots: automatisch handelen in crypto',
  [IntlKeys.botsPageAutomaticTradingSectionDescription]: 'De makkelijkste manier om met crypto te beginnen. Algoritmes die voor je handelen, gebaseerd op marktsignalen en -trends.',
  [IntlKeys.botsPageAutomaticTradingSectionListItem1]: 'Makkelijke manier om te beginnen met crypto',
  [IntlKeys.botsPageAutomaticTradingSectionListItem2]: 'Geen voorkennis nodig',
  [IntlKeys.botsPageAutomaticTradingSectionListItem3]: 'Geen instapkosten',
  [IntlKeys.botsPageAutomaticTradingSectionListItem4]: 'Bots slapen nooit; Mis nooit meer een kans',
  [IntlKeys.botsPageAutomaticTradingSectionButton]: 'Start met bots',
  [IntlKeys.botsPageHowItWorksSectionTitle]: 'Hoe werkt het?',
  [IntlKeys.botsPageHowItWorksSectionDescription]: 'Professionele traders gebruiken algoritmes om sneller te denken en te handelen dan een mens ooit zou kunnen. Bots zijn algoritmes die sneller handelen dan mensen, gebaseerd op marktbewegingen.',
  [IntlKeys.botsPageDescriptionSectionListItemTitle1]: 'Risk factor',
  [IntlKeys.botsPageDescriptionSectionListItemTitle2]: 'Beschikbaarheid',
  [IntlKeys.botsPageDescriptionSectionListItemTitle3]: 'Maker',
  [IntlKeys.botsPageDescriptionSectionListItemDescription1]: 'Elke bot heeft een andere strategie. Sommige bots zijn agressief, terwijl andere bots liever een veiligere aanpak aanhouden.',
  [IntlKeys.botsPageDescriptionSectionListItemDescription2]: 'Om volledig te kunnen presteren, laten bots een maximumbedrag toe. Zorg ervoor dat je een plekje claimt voordat de bots hun limiet bereiken.',
  [IntlKeys.botsPageDescriptionSectionListItemDescription3]: 'Elke bot heeft zijn eigen maker. Je kan meer informatie over de bot en zijn maker vinden op elke botpagina. ',
  [IntlKeys.botsPageFutureSectionTableTitle]: 'Onze Bots',
  [IntlKeys.botsPageFutureSectionTitle]: 'De toekomst is hier',
  [IntlKeys.botsPageFutureSectionDescription]: 'Bots slapen nooit en verdienen geld wanneer je slaapt. Bots handelen op basis van markttrends en -signalen. Automatisch handelen stelt bots in staat sneller en beter te handelen dan mensen.',
  [IntlKeys.botsPageFutureSectionTableLink]: 'Bekijk alle bots',
  [IntlKeys.botsPageFAndQSectionTitle]: 'Meestgestelde vragen',
  [IntlKeys.botsPageFAndQSectionLink]: 'Vind alle gestelde vragen over bots',
  [IntlKeys.botsPageFAndQSectionQuestion1]: 'Wat is een bot?',
  [IntlKeys.botsPageFAndQSectionQuestion2]: 'Hoe kan ik gebruik maken van een bot?',
  [IntlKeys.botsPageFAndQSectionQuestion3]: 'Is het gebruik van Bots veilig?',
  [IntlKeys.botsPageFAndQSectionAnswer1]: 'Een bot is een vastgestelde handelsstrategie voor het kopen en verkopen van cryptocurrencies. Een bot werkt via een computerprogamma dat is ontworpen door traders en ontwikkelaars. Een bot is ontwikkelt op basis van indicatoren: markttrends en -signalen.',
  [IntlKeys.botsPageFAndQSectionAnswer2]: 'Wij respecteren onze users en staan voor de best mogelijke ervaring. We zijn overal en toegankelijk voor iedereen, in begrijpelijke taal. Ongeacht vermogen, inleg of ervaring. Als het kan doen wij er een schepje bovenop, wij geloven dat we juist daar het verschil maken.',
  [IntlKeys.botsPageFAndQSectionAnswer3]: 'Veiligheid is de #1 prioriteit van Coinmerce. Wij streven ernaar om jou de meest veilige crypto-ervaring te laten beleven. De bot heeft alleen toegang tot jouw unieke API-sleutels. De bot heeft geen toegang tot jouw fondsen. Hierdoor houd je altijd zelf de controle over je fondsen. Voor extra veiligheid raden we jou altijd aan om je 2FA in te schakelen.',
  [IntlKeys.botsPageFAndQSectionQAndALink]: 'Lees verder',

  // registration page
  [IntlKeys.registrationPageRegisterStepFormTermsAndConditions]: 'I agree to the {firstLink} and {secondLink}',
};
