// TODO: check keys with dictionary so it match number of lines and
// each key match its place in keys and dictionary files
export enum IntlKeys {
  // common
  commonClose = 'common.close',
  commonLorem = 'common.lorem',
  commonAccept = 'common.accept',
  commonCancel = 'common.cancel',
  commonCreate = 'common.create',

  // header
  headerHome = 'header.home',
  headerCoins = 'header.coins',
  headerNfts = 'header.nfts',
  headerWallets = 'header.wallets',
  headerOrders = 'header.orders',
  headerExplore = 'header.explore',
  headerExplorePopoverTitle = 'header.explorePopoverTitle',
  headerExplorePopoverProducts = 'header.explorePopoverProducts',
  headerExplorePopoverAbout = 'header.explorePopoverAbout',
  headerExplorePopoverProductItemsProofTitle = 'header.explorePopoverProductItemsProofTitle',
  headerExplorePopoverProductItemsBotsTitle = 'header.explorePopoverProductItemsBotsTitle',
  headerExplorePopoverProductItemsBeginnersTitle = 'header.explorePopoverProductItemsBeginnersTitle',
  headerExplorePopoverProductItemsAdvancedTitle = 'header.explorePopoverProductItemsAdvancedTitle',
  headerExplorePopoverProductItemsGiftcardsTitle = 'header.explorePopoverProductItemsGiftcardsTitle',
  headerExplorePopoverProductItemsProofDescription = 'header.explorePopoverProductItemsProofDescription',
  headerExplorePopoverProductItemsBotsDescription = 'header.explorePopoverProductItemsBotsDescription',
  headerExplorePopoverProductItemsBeginnersDescription = 'header.explorePopoverProductItemsBeginnersDescription',
  headerExplorePopoverProductItemsAdvancedDescription = 'header.explorePopoverProductItemsAdvancedDescription',
  headerExplorePopoverProductItemsGiftcardsDescription = 'header.explorePopoverProductItemsGiftcardsDescription',
  headerExplorePopoverAboutItemsAboutTitle = 'header.explorePopoverAboutItemsAboutTitle',
  headerExplorePopoverAboutItemsCryptoTitle = 'header.explorePopoverAboutItemsCryptoTitle',
  headerExplorePopoverAboutItemsNewsTitle = 'header.explorePopoverAboutItemsNewsTitle',
  headerExplorePopoverAboutItemsLearnTitle = 'header.explorePopoverAboutItemsLearnTitle',
  headerExplorePopoverAboutItemsHelpTitle = 'header.explorePopoverAboutItemsHelpTitle',
  headerExplorePopoverAboutItemsVacanciesTitle = 'header.explorePopoverAboutItemsVacanciesTitle',
  headerExplorePopoverAboutItemsAboutDescription = 'header.explorePopoverAboutItemsAboutDescription',
  headerExplorePopoverAboutItemsCryptoDescription = 'header.explorePopoverAboutItemsCryptoDescription',
  headerExplorePopoverAboutItemsNewsDescription = 'header.explorePopoverAboutItemsNewsDescription',
  headerExplorePopoverAboutItemsLearnDescription = 'header.explorePopoverAboutItemsLearnDescription',
  headerExplorePopoverAboutItemsHelpDescription = 'header.explorePopoverAboutItemsHelpDescription',
  headerExplorePopoverAboutItemsVacanciesDescription = 'header.explorePopoverAboutItemsVacanciesDescription',
  headerAccountPopoverTitle = 'header.accountPopoverTitle',
  headerAccountPopoverProfile = 'header.accountPopoverProfile',
  headerAccountPopoverAccountLevel = 'header.accountPopoverAccountLevel',
  headerAccountPopoverSecurity = 'header.accountPopoverSecurity',
  headerAccountPopoverSettings = 'header.accountPopoverSettings',
  headerAccountPopoverFriends = 'header.accountPopoverFriends',
  headerAccountPopoverWithdrawAddresses = 'header.accountPopoverWithdrawAddresses',
  headerAccountPopoverLogout = 'header.accountPopoverLogout',
  headerNFTPopoverCreate = 'header.nftPopoverCreate',
  headerNFTPopoverMarketplace = 'header.nftPopoverMarketplace',
  headerNFTPopoverMyCollections = 'header.nftPopoverMyCollection',
  headerNFTPopoverLaunchpad = 'header.nftPopoverLaunchpad',
  headerItemCoins = 'header.itemCoins',
  headerItemProducts = 'header.itemProducts',
  headerItemGetTheApp = 'header.itemGetTheApp',
  headerCoinsPopoverMenuLink = 'header.coinsPopoverMenuLink',
  headerItemPassiveIncome = 'header.itemPassiveIncome',
  headerPassiveIncomePopoverItemTitle1 = 'header.passiveIncomePopoverItemTitle1',
  headerPassiveIncomePopoverItemTitle2 = 'header.passiveIncomePopoverItemTitle2',
  headerPassiveIncomePopoverItemTitle3 = 'header.passiveIncomePopoverItemTitle3',
  headerPassiveIncomePopoverItemDescription1 = 'header.passiveIncomePopoverItemDescription1',
  headerPassiveIncomePopoverItemDescription2 = 'header.passiveIncomePopoverItemDescription2',
  headerPassiveIncomePopoverItemDescription3 = 'header.passiveIncomePopoverItemDescription3',
  headerItemBots = 'header.itemBots',
  headerBotsPopoverItemTitle1 = 'header.botsPopoverItemTitle1',
  headerBotsPopoverItemDescription1 = 'header.botsPopoverItemDescription1',
  headerBotsPopoverItemLink = 'header.botsPopoverItemLink',
  headerItemLearn = 'header.itemLearn',
  headerLearnPopoverItemTitle1 = 'header.learnPopoverItemTitle1',
  headerLearnPopoverItemTitle2 = 'header.learnPopoverItemTitle2',
  headerLearnPopoverItemTitle3 = 'header.learnPopoverItemTitle3',
  headerLearnPopoverItemTitle4 = 'header.learnPopoverItemTitle4',
  headerLearnPopoverItemTitle5 = 'header.learnPopoverItemTitle5',
  headerLearnPopoverItemDescription1 = 'header.learnPopoverItemDescription1',
  headerLearnPopoverItemDescription2 = 'header.learnPopoverItemDescription2',
  headerLearnPopoverItemDescription3 = 'header.learnPopoverItemDescription3',
  headerLearnPopoverItemDescription4 = 'header.learnPopoverItemDescription4',
  headerLearnPopoverItemDescription5 = 'header.learnPopoverItemDescription5',
  headerItemAbout = 'header.itemAbout',
  headerAboutPopoverItemTitle1 = 'header.aboutPopoverItemTitle1',
  headerAboutPopoverItemTitle2 = 'header.aboutPopoverItemTitle2',
  headerAboutPopoverItemTitle3 = 'header.aboutPopoverItemTitle3',
  headerAboutPopoverItemTitle4 = 'header.aboutPopoverItemTitle4',
  headerAboutPopoverItemDescription1 = 'header.aboutPopoverItemDescription1',
  headerAboutPopoverItemDescription2 = 'header.aboutPopoverItemDescription2',
  headerAboutPopoverItemDescription3 = 'header.aboutPopoverItemDescription3',
  headerAboutPopoverItemDescription4 = 'header.aboutPopoverItemDescription4',
  headerLoginButton = 'header.loginButton',
  headerRegisterButton = 'header.registerButton',

  // footer
  footerLoginTitle = 'footer.loginTitle',
  footerLoginButton = 'footer.loginButton',
  footerSignupButton = 'footer.signupButton',
  footerListGeneral = 'footer.listGeneral',
  footerListGeneralAboutUs = 'footer.listGeneralAboutUs',
  footerListGeneralCareers = 'footer.listGeneralCareers',
  footerListGeneralCoins = 'footer.listGeneralCoins',
  footerListGeneralFraudPrevention = 'footer.listGeneralFraudPrevention',
  footerListGeneralBugFound = 'footer.listGeneralBugFound',
  footerListGeneralApi = 'footer.listGeneralApi',
  footerListGeneralSitemap = 'footer.listGeneralSitemap',
  footerListPopular = 'footer.listPopular',
  footerListPopularBuyBitcoin = 'footer.listPopularBuyBitcoin',
  footerListPopularBitcoinPrice = 'footer.listPopularBitcoinPrice',
  footerListPopularBuyEthereum = 'footer.listPopularBuyEthereum',
  footerListPopularEthereumPrice = 'footer.listPopularEthereumPrice',
  footerListPopularBuyRipple = 'footer.listPopularBuyRipple',
  footerListPopularRipplePrice = 'footer.listPopularRipplePrice',
  footerListPopularBuyLota = 'footer.listPopularBuyLota',
  footerListPopularLotaPrice = 'footer.listPopularLotaPrice',
  footerListLegal = 'footer.listLegal',
  footerListLegalTermsAndConditions = 'footer.listLegalTermsAndConditions',
  footerListLegalPrivacyPolicy = 'footer.listLegalPrivacyPolicy',
  footerListLegalRiskDisclaimer = 'footer.listLegalRiskDisclaimer',
  footerListJumpTo = 'footer.listJumpTo',
  footerListJumpToRegister = 'footer.listJumpToRegister',
  footerListJumpToLogin = 'footer.listJumpToLogin',
  footerListJumpToNeedHelp = 'footer.listJumpToNeedHelp',
  footerListJumpToLearn = 'footer.listJumpToLearn',
  footerListJumpToNewsAndBlog = 'footer.listJumpToNewsAndBlog',
  footerStore = 'footer.store',
  footerOffice = 'footer.office',
  footerStartInvestmentTitle = 'footer.startInvestmentTitle',
  footerRegisterButton = 'footer.registerButton',
  footerLoginText = 'footer.loginText',
  footerLoginLinkText = 'footer.loginLinkText',
  footerListTradeTitle = 'footer.listTradeTitle',
  footerListTradeItem1 = 'footer.listTradeItem1',
  footerListTradeItem2 = 'footer.listTradeItem2',
  footerListTradeItem3 = 'footer.listTradeItem3',
  footerListTradeItem4 = 'footer.listTradeItem4',
  footerListPopularTitle = 'footer.listPopularTitle',
  footerListPopularItem1 = 'footer.listPopularItems1',
  footerListPopularItem2 = 'footer.listPopularItems2',
  footerListPopularItem3 = 'footer.listPopularItems3',
  footerListPopularItem4 = 'footer.listPopularItems4',
  footerListPopularItem5 = 'footer.listPopularItems5',
  footerListPopularItem6 = 'footer.listPopularItems6',
  footerListPopularItem7 = 'footer.listPopularItems7',
  footerListPopularItem8 = 'footer.listPopularItems8',
  footerListPopularItem9 = 'footer.listPopularItems9',
  footerListPopularItem10 = 'footer.listPopularItems10',
  footerListLearnTitle = 'footer.listLearnTitle',
  footerListLearnItem1 = 'footer.listLearnItem1',
  footerListLearnItem2 = 'footer.listLearnItem2',
  footerListLearnItem3 = 'footer.listLearnItem3',
  footerListLearnItem4 = 'footer.listLearnItem4',
  footerListLearnItem5 = 'footer.listLearnItem5',
  footerListLegalTitle = 'footer.listLegalTitle',
  footerListLegalItem1 = 'footer.listLegalItem1',
  footerListLegalItem2 = 'footer.listLegalItem2',
  footerListLegalItem3 = 'footer.listLegalItem3',
  footerListGeneralTitle = 'footer.listGeneralTitle',
  footerListGeneralItem1 = 'footer.listGeneralItem1',
  footerListGeneralItem2 = 'footer.listGeneralItem2',
  footerListGeneralItem3 = 'footer.listGeneralItem3',
  footerListGeneralItem4 = 'footer.listGeneralItem4',
  footerListGeneralItem5 = 'footer.listGeneralItem5',

  // single coin view
  websiteSinglecoinBreadcrumbCoins = 'websiteSingloecoin.bredcrumbCoins',
  websiteSinglecoinIntro = 'websiteSingloecoin.intro',
  websiteSinglecoinUsp1 = 'websiteSingloecoin.usp1',
  websiteSinglecoinUsp2 = 'websiteSingloecoin.usp2',
  websiteSinglecoinUsp3 = 'websiteSingloecoin.usp3',
  websiteSinglecoinUsp4 = 'websiteSingloecoin.usp4',
  websiteSinglecoinH1 = 'websiteSingloecoin.h1',
  websiteSinglecoinH2Price = 'websiteSingloecoin.h2Price',
  websiteSinglecoinH2PriceCoin = 'websiteSingloecoin.h2PriceCoin',
  websiteSinglecoinH2PriceVolume = 'websiteSingloecoin.h2PriceVolume',
  websiteSinglecoinH2PriceMarketcap = 'websiteSingloecoin.h2PriceMarketcap',
  websiteSinglecoinEarnH2 = 'websiteSingloecoin.earnH2',
  websiteSinglecoinEarnRewards = 'websiteSingloecoin.earnRewards',
  websiteSinglecoinEarnMinimum = 'websiteSingloecoin.earnMinimum',
  websiteSinglecoinEarnFrequencyTitle = 'websiteSingloecoin.earnFrequencyTitle',
  websiteSinglecoinEarnFrequencyTime = 'websiteSingloecoin.earnFrequencyTime',
  websiteSinglecoinEarnCalculateRewards = 'websiteSingloecoin.earnCalculateRewards',
  websiteSinglecoinEarn1Year = 'websiteSingloecoin.earn1Year',
  websiteSinglecoinEarn2Year = 'websiteSingloecoin.earn2Year',
  websiteSinglecoinEarn5Year = 'websiteSingloecoin.earn5Year',
  websiteSinglecoinEarnAsterisk = 'websiteSingloecoin.earnAsterisk',
  websiteSinglecoinEarnButton = 'websiteSingloecoin.earnButton',
  websiteSinglecoinStakingH2 = 'websiteSingloecoin.stakingH2',
  websiteSinglecoinStakingRewards = 'websiteSingloecoin.stakingRewards',
  websiteSinglecoinStakingMinimum = 'websiteSingloecoin.stakingMinimum',
  websiteSinglecoinStakingFrequencyTitle = 'websiteSingloecoin.stakingFrequencyTitle',
  websiteSinglecoinStakingFrequencyTime = 'websiteSingloecoin.stakingFrequencyTime',
  websiteSinglecoinStakingCalculateRewards = 'websiteSingloecoin.stakingCalculateRewards',
  websiteSinglecoinStaking1Year = 'websiteSingloecoin.staking1Year',
  websiteSinglecoinStaking2Year = 'websiteSingloecoin.staking2Year',
  websiteSinglecoinStaking5Year = 'websiteSingloecoin.staking5Year',
  websiteSinglecoinStakingAsterisk = 'websiteSingloecoin.stakingAsterisk',
  websiteSinglecoinStakingButton = 'websiteSingloecoin.stakingButton',
  websiteSinglecoinAboutTitle = 'websiteSingloecoin.aboutTitle',
  websiteSinglecoinAboutWhat = 'websiteSingloecoin.aboutWhat',
  websiteSinglecoinReadmore = 'websiteSingloecoin.readmore',
  websiteSinglecoinAblutHow = 'websiteSingloecoin.aboutHow',
  websiteSinglecoinAblutBuy = 'websiteSingloecoin.aboutBuy',
  websiteSinglecoinPopularTitle = 'websiteSingloecoin.popularTitle',
  websiteSinglecoinPopularDescription = 'websiteSingloecoin.popularDescription',
  websiteSinglecoinPopularDescriptionAllCrypto = 'websiteSingloecoin.popularDescriptionAllCrypto',
  websiteSinglecoinPopularAsset = 'websiteSingloecoin.populaAsset',
  websiteSinglecoinPopularBalance = 'websiteSingloecoin.popularBalance',
  websiteSinglecoinPopularPrice = 'websiteSingloecoin.popularPrice',
  websiteSinglecoinPopularChange = 'websiteSingloecoin.popularChange',
  websiteSinglecoinPopularType = 'websiteSingloecoin.popularType',
  websiteSinglecoinPopularActiveStaking = 'websiteSingloecoin.popularActiveStaking',
  websiteSinglecoinPopularActiveEarn = 'websiteSingloecoin.popularActiveEarn',
  websiteSinglecoinPopularBuy = 'websiteSingloecoin.popularBuy',
  websiteSinglecoinPopularSell = 'websiteSingloecoin.popularSell',
  websiteSinglecoinPopularDeposit = 'websiteSingloecoin.popularDeposit',
  websiteSinglecoinPopularWithdraw = 'websiteSingloecoin.popularWithdraw',
  websiteSinglecoinPopularViewAllCrypto = 'websiteSingloecoin.popularViewAllCrypto',
  websiteSinglecoinBuyButtonMobile = 'websiteSinglecoin.buyButtonMobile',
  websiteSinglecoinBuyItem = 'websiteSinglecoin.buyItem',
  websiteSinglecoinSellItem = 'websiteSinglecoin.sellItem',
  websiteSinglecoinBuyButton = 'websiteSinglecoin.buyButton',
  websiteSinglecoinSellButton = 'websiteSinglecoin.sellButton',
  websiteSinglecoinBuyButtonCoin = 'websiteSinglecoin.buyButtonCoin',
  websiteSinglecoinOrderLimit = 'websiteSinglecoin.orderLimit',
  websiteSinglecoinRepeatOrder = 'websiteSinglecoin.repeatOrder',
  websiteSinglecoinSwapItem = 'websiteSinglecoin.swapItem',
  websiteSinglecoinSwapButton = 'websiteSinglecoin.swapItem',

  // single bundle view
  websiteBundleviewBreadcrumbBundles = 'websiteBundleview.BreadcrumbBundles',
  websiteBundleviewDescription = 'websiteBundleview.description',
  websiteBundleviewBuyItem = 'websiteBundleview.buyItem',
  websiteBundleviewSellItem = 'websiteBundleview.sellItem',
  websiteBundleviewBuyButton = 'websiteBundleview.buyButton',
  websiteBundleviewSellButton = 'websiteBundleview.sellButton',
  websiteBundleviewBuyButtonMobile = 'websiteBundleview.buyButtonMobile',
  websiteBundleviewMoreButtonMobile = 'websiteBundleview.moreButtonMobile',
  websiteBundleviewMax = 'websiteBundleview.max',
  websiteBundleviewUsp1 = 'websiteBundleview.usp1',
  websiteBundleviewUsp2 = 'websiteBundleview.usp2',
  websiteBundleviewUsp3 = 'websiteBundleview.usp3',
  websiteBundleviewUsp3Link = 'websiteBundleview.usp3Link',
  websiteBundleviewUsp4 = 'websiteBundleview.usp4',
  websiteBundleviewOtherBundlesTitle = 'websiteBundleview.otherBundlesTitle',
  websiteBundleviewOtherBundlesDescription = 'websiteBundleview.otherBundlesDescription',
  websiteBundleviewOtherBundlesBundle = 'websiteBundleview.otherBundlesBundle',
  websiteBundleviewOtherBundlesChange = 'websiteBundleview.otherBundlesChange',
  websiteBundleviewOtherBundlesCoins = 'websiteBundleview.otherBundlesCoins',
  websiteBundleviewOtherBundlesBuyButton = 'websiteBundleview.otherBundlesBuyButton',
  websiteBundleviewOtherBundlesOtherCoins = 'websiteBundleview.otherBundlesOtherCoins',
  websiteBundleviewH1 = 'websiteBundleview.h1',
  websiteBundleviewH2 = 'websiteBundleview.h2',

  // toasts
  toastBidSubmitted = 'toast.bidSubmitted',
  toastSomethingWentWrong = 'toast.somethingWentWrong',
  toastBidCancelled = 'toast.bidCancelled',
  toastListingCreated = 'toast.listingCreated',
  toastListingUpdated = 'toast.listingUpdated',
  toastBidApproved = 'toast.bidApproved',
  toastAllBidsDeclined = 'toast.allBidsDeclined',
  toastCollectionCreated = 'toast.collectionCreated',
  toastCollectionUpdated = 'toast.collectionUpdated',
  toastCollectionNameUpdated = 'toast.collectionNameUpdated',
  toastListingRemoved = 'toast.listingRemoved',

  // validation
  validationRequired = 'validation.required',
  validationBidShouldBeEqualOrBigger = 'validation.bidShouldBeEqualOrBigger',
  validationShouldBeBiggerThanStartingPrice = 'validation.shouldBeBiggerThanStartingPrice',

  // affiliation page
  affiliationPageAboutSectionTitle = 'affiliationPage.aboutSectionTitle',
  affiliationPageAboutSectionDescription = 'affiliationPage.aboutSectionDescription',
  affiliationPageAboutSectionListItem1 = 'affiliationPage.aboutSectionListItem1',
  affiliationPageAboutSectionListItem2 = 'affiliationPage.aboutSectionListItem2',
  affiliationPageAboutSectionListItem3 = 'affiliationPage.aboutSectionListItem3',
  affiliationPageAboutSectionListItem4 = 'affiliationPage.aboutSectionListItem4',
  affiliationPageAboutSectionButton = 'affiliationPage.aboutSectionButton',
  affiliationPageAdvantagesSectionTitle = 'affiliationPage.advantagesSectionTitle',
  affiliationPageAdvantagesSectionDescription = 'affiliationPage.advantagesSectionDescription',
  affiliationPageAdvantagesSectionAdvantageTitle1 = 'affiliationPage.advantagesSectionAdvantageTitle1',
  affiliationPageAdvantagesSectionAdvantageTitle2 = 'affiliationPage.advantagesSectionAdvantageTitle2',
  affiliationPageAdvantagesSectionAdvantageTitle3 = 'affiliationPage.advantagesSectionAdvantageTitle3',
  affiliationPageAdvantagesSectionAdvantageDescription1 = 'affiliationPage.advantagesSectionAdvantageDescription1',
  affiliationPageAdvantagesSectionAdvantageDescription2 = 'affiliationPage.advantagesSectionAdvantageDescription2',
  affiliationPageAdvantagesSectionAdvantageDescription3 = 'affiliationPage.advantagesSectionAdvantageDescription3',
  affiliationPageAdvantagesSectionAdvantageDescriptionHelper1 = 'affiliationPage.advantagesSectionAdvantageDescriptionHelper1',
  affiliationPageAdvantagesSectionAdvantageDescriptionHelper2 = 'affiliationPage.advantagesSectionAdvantageDescriptionHelper2',
  affiliationPageWhyCoinmerceSectionTitle = 'affiliationPage.whyCoinmerceSectionTitle',
  affiliationPageWhyCoinmerceSectionDescription = 'affiliationPage.whyCoinmerceSectionDescription',
  affiliationPageWhyCoinmerceSectionListItem1 = 'affiliationPage.whyCoinmerceSectionListItem1',
  affiliationPageWhyCoinmerceSectionListItem2 = 'affiliationPage.whyCoinmerceSectionListItem2',
  affiliationPageWhyCoinmerceSectionListItem3 = 'affiliationPage.whyCoinmerceSectionListItem3',
  affiliationPageWhyCoinmerceSectionListItem4 = 'affiliationPage.whyCoinmerceSectionListItem4',
  affiliationPageWhyCoinmerceSectionButton = 'affiliationPage.whyCoinmerceSectionButton',
  affiliationPageThreeStepsSectionTitle = 'affiliationPage.threeStepsSectionTitle',
  affiliationPageThreeStepsSectionDescription = 'affiliationPage.threeStepsSectionDescription',
  affiliationPageThreeStepsSectionStepTitle1 = 'affiliationPage.threeStepsSectionStepTitle1',
  affiliationPageThreeStepsSectionStepTitle2 = 'affiliationPage.threeStepsSectionStepTitle2',
  affiliationPageThreeStepsSectionStepTitle3 = 'affiliationPage.threeStepsSectionStepTitle3',
  affiliationPageThreeStepsSectionStepDescription1 = 'affiliationPage.threeStepsSectionStepDescription1',
  affiliationPageThreeStepsSectionStepDescription2 = 'affiliationPage.threeStepsSectionStepDescription2',
  affiliationPageThreeStepsSectionStepDescription3 = 'affiliationPage.threeStepsSectionStepDescription3',
  affiliationPagePromotionalMaterialSectionTitle = 'affiliationPage.promotionalMaterialSectionTitle',
  affiliationPagePromotionalMaterialSectionDescription = 'affiliationPage.promotionalMaterialSectionDescription',
  affiliationPagePromotionalMaterialSectionButton = 'affiliationPage.promotionalMaterialSectionButton',
  affiliationPageCryptoRateWidgetSectionTitle = 'affiliationPage.cryptoRateWidgetSectionTitle',
  affiliationPageCryptoRateWidgetSectionDescription = 'affiliationPage.cryptoRateWidgetSectionDescription',
  affiliationPageCryptoRateWidgetSectionButton = 'affiliationPage.cryptoRateWidgetSectionButton',
  affiliationPageQuestionsSectionTitle = 'affiliationPage.questionsSectionTitle',
  affiliationPageQuestionsSectionQuestion1 = 'affiliationPage.questionsSectionQuestion1',
  affiliationPageQuestionsSectionQuestion2 = 'affiliationPage.questionsSectionQuestion2',
  affiliationPageQuestionsSectionQuestion3 = 'affiliationPage.questionsSectionQuestion3',
  affiliationPageQuestionsSectionQuestion4 = 'affiliationPage.questionsSectionQuestion4',
  affiliationPageQuestionsSectionQuestion5 = 'affiliationPage.questionsSectionQuestion5',
  affiliationPageQuestionsSectionAnswer1 = 'affiliationPage.questionsSectionAnswer1',
  affiliationPageQuestionsSectionAnswer2 = 'affiliationPage.questionsSectionAnswer2',
  affiliationPageQuestionsSectionAnswer3 = 'affiliationPage.questionsSectionAnswer3',
  affiliationPageQuestionsSectionAnswer4 = 'affiliationPage.questionsSectionAnswer4',
  affiliationPageQuestionsSectionAnswer5 = 'affiliationPage.questionsSectionAnswer5',
  affiliationPageContactUsSectionTitle = 'affiliationPage.contactUsSectionTitle',
  affiliationPageContactUsSectionDescription = 'affiliationPage.contactUsSectionDescription',
  affiliationPageContactUsSectionSocial1 = 'affiliationPage.contactUsSectionSocial1',
  affiliationPageContactUsSectionSocial2 = 'affiliationPage.contactUsSectionSocial2',

  // bots page
  botsPageAutomaticTradingSectionTitle = 'botsPage.automaticTradingSectionTitle',
  botsPageAutomaticTradingSectionDescription = 'botsPage.automaticTradingSectionDescription',
  botsPageAutomaticTradingSectionListItem1 = 'affiliationPage.automaticTradingSectionListItem1',
  botsPageAutomaticTradingSectionListItem2 = 'affiliationPage.automaticTradingSectionListItem2',
  botsPageAutomaticTradingSectionListItem3 = 'affiliationPage.automaticTradingSectionListItem3',
  botsPageAutomaticTradingSectionListItem4 = 'affiliationPage.automaticTradingSectionListItem4',
  botsPageAutomaticTradingSectionButton = 'affiliationPage.automaticTradingSectionButton',
  botsPageHowItWorksSectionTitle = 'botsPage.howItWorksSectionTitle',
  botsPageHowItWorksSectionDescription = 'botsPage.howItWorksSectionDescription',
  botsPageDescriptionSectionListItemTitle1 = 'botsPage.descriptionSectionListItemTitle1',
  botsPageDescriptionSectionListItemTitle2 = 'botsPage.descriptionSectionListItemTitle2',
  botsPageDescriptionSectionListItemTitle3 = 'botsPage.descriptionSectionListItemTitle3',
  botsPageDescriptionSectionListItemDescription1 = 'botsPage.descriptionSectionListItemDescription1',
  botsPageDescriptionSectionListItemDescription2 = 'botsPage.descriptionSectionListItemDescription2',
  botsPageDescriptionSectionListItemDescription3 = 'botsPage.descriptionSectionListItemDescription3',
  botsPageFutureSectionTableTitle = 'botsPage.futureSectionTableTitle',
  botsPageFutureSectionTitle = 'botsPage.futureSectionTitle',
  botsPageFutureSectionDescription = 'botsPage.futureSectionDescription',
  botsPageFutureSectionTableLink = 'botsPage.futureSectionTableLink',
  botsPageFAndQSectionTitle = 'botsPage.fAndQSectionTitle',
  botsPageFAndQSectionLink = 'botsPage.fAndQSectionLink',
  botsPageFAndQSectionQuestion1 = 'botsPage.fAndQSectionQuestion1',
  botsPageFAndQSectionQuestion2 = 'botsPage.fAndQSectionQuestion2',
  botsPageFAndQSectionQuestion3 = 'botsPage.fAndQSectionQuestion3',
  botsPageFAndQSectionAnswer1 = 'botsPage.fAndQSectionAnswer1',
  botsPageFAndQSectionAnswer2 = 'botsPage.fAndQSectionAnswer2',
  botsPageFAndQSectionAnswer3 = 'botsPage.fAndQSectionAnswer3',
  botsPageFAndQSectionQAndALink = 'botsPage.fAndQSectionQAndALink',

  // registration page
  registrationPageRegisterStepFormTermsAndConditions = 'registrationPage.registerStepFormTermsAndConditions',
}
