import styled from '@emotion/styled/macro';
// libs
import { Link } from 'react-router-dom';
// other
import { focusVisibleStyles } from 'components/styled/common';

export const Wrapper = styled.div`
  padding: 16px;
  background-color: ${({ theme }) => theme.palette.common.kuretakeBlackManga};
  width: fit-content;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

export const List = styled.ul`
  width: 100%;
`;

export const ListItem = styled.li`
  display: flex;
  border: 0;
  height: auto;
  line-height: 36px;
`;

export const ListItemLink = styled(Link)`
  display: flex;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.common.white};
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.palette.common.galacticTint};
  }

  &:focus-visible {
    ${({ theme }) => focusVisibleStyles(theme)}
  }
`;
