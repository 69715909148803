import React, { useState, ReactElement } from 'react';
// styled
import { FormTab, FormTabsContainer, TabContent } from './styled';

export interface FormTabsProps {
  children: ReactElement[];
}

function FormTabs({ children }: FormTabsProps) {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  return (
    <>
      <FormTabsContainer>
        {children.map((item, index) => (
          <FormTab key={index} selected={selectedTab === index} onClick={() => setSelectedTab(index)}>
            {item.props.title}
          </FormTab>
        ))}
      </FormTabsContainer>
      <TabContent>{children[selectedTab]}</TabContent>
    </>
  );
}

export default FormTabs;
