import styled from '@emotion/styled/macro';
import { Button } from '@mui/material';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Container = styled.div`
  width: 420px;
  padding: 32px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.common.white};

  ${({ theme }) => theme.media.xs`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `};
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.shadowMountain};
  margin-bottom: 8px;
`;

export const PasteButton = styled(Button)`
  height: 56px;
  font-size: 19.2px;
  line-height: 32px;
  font-family: 'VisbyRoundCF Bold', sans-serif;
  padding: 12px 24px;
  background-color: ${({ theme }) => theme.palette.common.rhapsodyInBlue};
  border-radius: 6px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.meissenBlue};
  }
`;

export const VerifyCodeContainer = styled.div`
  display: flex;
  gap: 8px;

  ${({ theme }) => theme.media.xs`
    flex-direction: column;
    align-items: center;
  `};
`;

export const NextButton = styled(Button)`
  height: 56px;
  font-size: 19.2px;
  line-height: 32px;
  font-family: 'VisbyRoundCF Bold', sans-serif;
  padding: 12px 32px;
  border-radius: 6px;
  width: fit-content;
  margin-top: 16px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.atmosphere};
  }
`;

export const ResendText = styled.div`
  font-size: 13.33px;
  line-height: 24px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.ancestralWater};
  margin-top: 8px;
`;

export const Description = styled.div`
  font-size: 13.33px;
  line-height: 24px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.argent};
  margin-top: 8px;

  ${({ theme }) => theme.media.xs`
    text-align: center;
  `};
`;

export const ResendLink = styled.a`
  display: inline-block;
  font-size: 13.33px;
  line-height: 24px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
  text-decoration-line: underline;
  cursor: pointer;
  margin-top: 8px;
`;
