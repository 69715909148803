import React from 'react';
import { ActionButton, Container, Description, Image, Title, Wrapper } from './styled';

export interface VerificationCardProps {
  title: string;
  description: string;
  images: string[];
  buttonTitle: string;
  onButtonClick: () => void;
}

const VerificationCard: React.FC<VerificationCardProps> = ({
  title,
  description,
  images,
  buttonTitle,
  onButtonClick,
}) => {
  return (
    <Wrapper>
      <Container>
        <Title>{title}</Title>
        <Description>{description}</Description>
        {images.map((image, index) => (
          <Image src={image} key={index.toString()} />
        ))}
        <ActionButton onClick={onButtonClick}>{buttonTitle}</ActionButton>
      </Container>
    </Wrapper>
  );
};

export default VerificationCard;
