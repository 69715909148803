import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  max-width: 400px;

  ${({ theme }) => theme.media.md`
    max-width: 100%;
  `}
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;

  ${({ theme }) => theme.media.md`
    gap: 16px;
  `}
`;
