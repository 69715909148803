import { useCallback, useEffect, useState } from 'react';

const useGetMousePosition = () => {
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });

  const handleWindowMouseMove = useCallback((event: MouseEvent) => {
    setCoordinates({
      x: event.clientX,
      y: event.screenY,
    });
  }, []);

  useEffect(() => {
    window.addEventListener('mousemove', handleWindowMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleWindowMouseMove);
    };
  }, [handleWindowMouseMove]);

  return { coordinates };
};

export default useGetMousePosition;
