import React, { useEffect, useState } from 'react';
// libs
import { useMediaQuery } from 'react-responsive';
// components
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
import WalletsTable from './components/WalletsTable';
// providers
import { DashboardState, useDashboardContext } from 'providers/DashboardProvider';
// constants
import { breakpoints } from 'themes/mediaQueries';
// styled
import { Container, ConvertButton, ConvertButtonWrapper, WalletsTableWrapper, Wrapper } from './styled';

const DashboardPage: React.FC = () => {
  const { dashboardState } = useDashboardContext();

  const isXs = useMediaQuery({ maxWidth: breakpoints.xs });

  // simulated loading code start
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 5000);

    return () => clearTimeout(timer);
  }, []);
  // simulated loading code end

  return (
    <Wrapper>
      <Container>
        <LeftColumn />
        <RightColumn isLoading={isLoading} />
      </Container>
      <WalletsTableWrapper>
        <WalletsTable isLoading={isLoading} />
      </WalletsTableWrapper>
      <ConvertButtonWrapper>
        {!isXs && dashboardState === DashboardState.InUse && <ConvertButton>Convert small assets to CM</ConvertButton>}
      </ConvertButtonWrapper>
    </Wrapper>
  );
};

export default DashboardPage;
