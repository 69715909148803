import React, { useMemo } from 'react';
// libs
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
// material-ui
import { Button, Stack } from '@mui/material';
// store
import { uiLocaleSelector } from 'store/ui';
// constants
import { FOOTER_LISTS } from '../constants';
// icons
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ReactComponent as AppleStoreIcon } from 'assets/icons/apple-store.svg';
import { ReactComponent as GooglePlayIcon } from 'assets/icons/google-play.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg';
// other
import { IntlKeys } from 'localization/keys';
// styled
import {
  CommonWrapper,
  IconLink,
  InfoContainer,
  InfoContent,
  InfoWrapper,
  LoginContainer,
  LoginTitle,
  List,
  ListContainer,
  ListItem,
  ListItemLink,
  ListItemTitle,
  Divider,
  ListWrapper,
} from './styled';

function Info() {
  const { formatMessage } = useIntl();

  const locale = useSelector(uiLocaleSelector);

  const footerLists = useMemo(
    () => (
      <ListWrapper>
        {FOOTER_LISTS(locale).map((list) => {
          return (
            <ListContainer key={list[0].title}>
              <List>
                {list.map(({ title, link }, index) => {
                  return (
                    <ListItem key={title}>
                      {!index ? (
                        <ListItemTitle>{formatMessage({ id: title })}</ListItemTitle>
                      ) : (
                        <ListItemLink href={link} tabIndex={1}>
                          {formatMessage({ id: title })}
                        </ListItemLink>
                      )}
                    </ListItem>
                  );
                })}
              </List>
            </ListContainer>
          );
        })}
      </ListWrapper>
    ),
    [locale, formatMessage],
  );

  return (
    <InfoWrapper>
      <InfoContent>
        <InfoContainer>
          <LoginContainer>
            <CommonWrapper>
              <LoginTitle>{formatMessage({ id: IntlKeys.footerLoginTitle })}</LoginTitle>
            </CommonWrapper>
            <CommonWrapper>
              <Stack direction="row" spacing={1}>
                <Button variant="text" color="secondary" startIcon={<LockIcon />} tabIndex={1}>
                  {formatMessage({ id: IntlKeys.footerLoginButton })}
                </Button>
                <Button startIcon={<LockIcon />} tabIndex={1}>
                  {formatMessage({ id: IntlKeys.footerSignupButton })}
                </Button>
              </Stack>
            </CommonWrapper>
            <CommonWrapper>
              <Stack direction="column" spacing={2}>
                <IconLink href="#" tabIndex={1}>
                  <AppleStoreIcon />
                </IconLink>
                <IconLink href="#" tabIndex={1}>
                  <GooglePlayIcon />
                </IconLink>
              </Stack>
            </CommonWrapper>
            <CommonWrapper>
              <Stack direction="row" spacing={3}>
                <IconLink href="#" tabIndex={1}>
                  <TelegramIcon />
                </IconLink>
                <IconLink href="#" tabIndex={1}>
                  <FacebookIcon />
                </IconLink>
              </Stack>
            </CommonWrapper>
          </LoginContainer>
          <Divider />
          {footerLists}
        </InfoContainer>
      </InfoContent>
    </InfoWrapper>
  );
}

export default Info;
