import React from 'react';
// components
import AffiliateProgramSection from './AffiliateProgramSection';
import AdvantagesSection from './AdvantagesSection';
import WhyCoinmerceSection from './WhyCoinmerceSection';
import ThreeStepsSection from './ThreeStepsSection';
import PromotionalMaterialSection from './PromotionalMaterialSection';
import CryptoRateWidgetSection from './CryptoRateWidgetSection';
import QuestionsSection from './QuestionsSection';
import ContactUsSection from './ContactUsSection';
// styled
import { Container, Wrapper } from './styled';

const AffiliateProgramPage: React.FC = () => {
  return (
    <Wrapper>
      <Container>
        <AffiliateProgramSection />
        <AdvantagesSection />
        <WhyCoinmerceSection />
        <ThreeStepsSection />
        <PromotionalMaterialSection />
        <CryptoRateWidgetSection />
        <QuestionsSection />
      </Container>
      <ContactUsSection />
    </Wrapper>
  );
};

export default AffiliateProgramPage;
