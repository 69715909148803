import React from 'react';
// material-ui
import { Dialog, DialogProps, Theme, useMediaQuery, Breakpoint } from '@mui/material';

type ModalProps = DialogProps & { fullScreenBreakpoint?: Breakpoint };

const Modal: React.FC<ModalProps> = ({ children, fullScreenBreakpoint = 'sm', ...rest }) => {
  const matchMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.down(fullScreenBreakpoint));

  return (
    <Dialog maxWidth="xl" fullScreen={matchMediaQuery} {...rest}>
      {children}
    </Dialog>
  );
};

export default Modal;
