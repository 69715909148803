import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LinkButton = styled.a`
  width: 100%;
  height: 276px;
  background-color: ${({ theme }) => theme.palette.common.brescianBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 48px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  letter-spacing: 0.8px;

  & > svg {
    margin-left: 54px;
    margin-top: 8px;
    width: 36px;
    height: 36px;
  }

  ${({ theme }) => theme.media.xs`
    font-size: 32px;
    height: 200px;
    
    & > svg {
      margin-left: 48px;
      margin-top: 6px;
      width: 28px;
      height: 28px;
    }
  `}
`;
