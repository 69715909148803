import React from 'react';
// libs
import { useIntl } from 'react-intl';
// components
import FormTabs from './FormTabs';
import FormTabContent from './FormTabContent';
// constants
import { IntlKeys } from 'localization/keys';
// styled
import { FormTab, Wrapper } from './styled';

function ExchangeForm() {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <FormTabs>
        <FormTab title={formatMessage({ id: IntlKeys.websiteBundleviewBuyItem })}>
          <FormTabContent />
        </FormTab>
        <FormTab title={formatMessage({ id: IntlKeys.websiteBundleviewSellItem })}>
          <FormTabContent isSelling />
        </FormTab>
      </FormTabs>
    </Wrapper>
  );
}

export default ExchangeForm;
