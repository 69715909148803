import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { Skeleton } from '@mui/material';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  canvas {
    width: 100% !important;
  }
`;

export const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 24px 144px;
  canvas {
    width: 100% !important;
  }
  ${({ theme }) => theme.media.xs`
    padding-bottom: 184px;
    padding-left: 8px;
    padding-right: 0;
  `}
`;

export const Tab = styled.button<{ isActive: boolean }>`
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
  width: 43px;
  height: 43px;
  border: 1px solid transparent;
  font-size: 12px;
  transition: 0.2s all;
  border-radius: 3px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  ${({ isActive, theme }) =>
    isActive &&
    css`
      border: 1px solid ${theme.palette.common.snowBank};
      color: ${theme.palette.common.brescianBlue};
      transition: 0.2s all;
    `}
`;

export const TabsContainer = styled.div`
  display: flex;
`;

export const PercentChangeLabel = styled.div`
  font-size: 21px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  color: ${({ theme }) => theme.palette.common.pedestrianGreen};
  line-height: 19px;
`;

export const PriceChangeLabel = styled.div`
  font-size: 21px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  color: ${({ theme }) => theme.palette.common.pedestrianGreen};
  line-height: 19px;
`;

export const Divider = styled.div`
  width: 2px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.palette.common.ultimateGrey};
`;

export const ChangeContainer = styled.div`
  display: flex;
  width: fit-content;
  justify-content: space-between;
  gap: 8px;
`;

export const PriceLabel = styled.div`
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-size: 21px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
`;

export const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 0 24px;
  ${({ theme }) => theme.media.xs`
    padding: 24px 0 0 24px;
    flex-direction: column;
    gap: 8px;
  `}
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChartTitle = styled.div`
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 29px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;

export const SkeletonWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const ChartSkeleton = styled(Skeleton)`
  width: 100%;
  height: 349px;
  position: relative;
`;

export const CurrentPriceSkeleton = styled(Skeleton)`
  width: 86px;
  height: 24px;
  position: absolute;

  top: 30px;
  left: 22px;
`;

export const PercentChangeSkeleton = styled(Skeleton)`
  width: 60px;
  height: 24px;
  position: absolute;

  top: 60px;
  left: 22px;
`;

export const PriceChangeSkeleton = styled(Skeleton)`
  width: 100px;
  height: 24px;
  position: absolute;

  top: 60px;
  left: 120px;
`;

export const SkeletonDivider = styled(Skeleton)`
  width: 2px;
  height: 19px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.palette.common.ultimateGrey};
  position: absolute;

  top: 62.5px;
  left: 100px;
`;
