import React, { useCallback, useContext, useState } from 'react';

export interface IRegisterStepperContext {
  activeStep: number;
  steps: string[];
  handleNextStep: () => void;
  handleBackStep: () => void;
  setActiveStep: (activeStep: number) => void;
}

export interface RegisterStepperProviderProps {
  children: React.ReactNode;
}

const RegisterStepperContext = React.createContext<IRegisterStepperContext>(null as unknown as IRegisterStepperContext);

export const useRegisterStepperContext = () => useContext(RegisterStepperContext);

const REGISTER_STEPS = ['Register', 'Check Email', 'Account Details'];

const RegisterStepperProvider: React.FC<RegisterStepperProviderProps> = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [steps] = useState(REGISTER_STEPS);

  const handleNextStep = useCallback(
    () =>
      setActiveStep((prevStep) => {
        if (prevStep >= steps.length) {
          return prevStep;
        }
        return prevStep + 1;
      }),
    [steps],
  );

  const handleBackStep = useCallback(
    () =>
      setActiveStep((prevStep) => {
        if (prevStep === 0) {
          return prevStep;
        }
        return prevStep - 1;
      }),
    [],
  );

  const handleSetActiveState = useCallback((activeStep: number) => setActiveStep(activeStep), []);

  return (
    <RegisterStepperContext.Provider
      value={{
        activeStep,
        handleNextStep,
        handleBackStep,
        steps,
        setActiveStep: handleSetActiveState,
      }}
    >
      {children}
    </RegisterStepperContext.Provider>
  );
};

export default RegisterStepperProvider;
