import React from 'react';
// libs
import { useIntl } from 'react-intl';
// constants
import { IntlKeys } from 'localization/keys';
// images
import highCommission from 'assets/images/affiliate-page/high-commission.svg';
import noLimits from 'assets/images/affiliate-page/no-limits.svg';
import line from 'assets/images/affiliate-page/line.svg';
// styled
import {
  Container,
  Wrapper,
  Title,
  Description,
  AdvantagesList,
  AdvantageItem,
  AdvantageItemImage,
  AdvantageItemTitle,
  AdvantageItemDescription,
  AdvantageItemImageWrapper,
  DescriptionHelper,
} from './styled';

const ADVANTAGES_LIST = [
  {
    id: '1',
    imageSrc: highCommission,
    title: IntlKeys.affiliationPageAdvantagesSectionAdvantageTitle1,
    description: IntlKeys.affiliationPageAdvantagesSectionAdvantageDescription1,
    descriptionHelper: IntlKeys.affiliationPageAdvantagesSectionAdvantageDescriptionHelper1,
  },
  {
    id: '2',
    imageSrc: noLimits,
    title: IntlKeys.affiliationPageAdvantagesSectionAdvantageTitle2,
    description: IntlKeys.affiliationPageAdvantagesSectionAdvantageDescription2,
    descriptionHelper: IntlKeys.affiliationPageAdvantagesSectionAdvantageDescriptionHelper2,
  },
  {
    id: '3',
    imageSrc: line,
    title: IntlKeys.affiliationPageAdvantagesSectionAdvantageTitle3,
    description: IntlKeys.affiliationPageAdvantagesSectionAdvantageDescription3,
    descriptionHelper: '',
  },
];

const AdvantagesSection: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Container>
        <Title>{formatMessage({ id: IntlKeys.affiliationPageAdvantagesSectionTitle })}</Title>
        <Description>{formatMessage({ id: IntlKeys.affiliationPageAdvantagesSectionDescription })}</Description>
        <AdvantagesList>
          {ADVANTAGES_LIST.map(({ id, imageSrc, title, description, descriptionHelper }) => (
            <AdvantageItem key={id}>
              <AdvantageItemImageWrapper>
                <AdvantageItemImage src={imageSrc}></AdvantageItemImage>
              </AdvantageItemImageWrapper>
              <AdvantageItemTitle>{formatMessage({ id: title })}</AdvantageItemTitle>
              <AdvantageItemDescription>
                {descriptionHelper
                  ? formatMessage(
                      { id: description },
                      { helper: <DescriptionHelper>{formatMessage({ id: descriptionHelper })}</DescriptionHelper> },
                    )
                  : formatMessage({ id: description })}
              </AdvantageItemDescription>
            </AdvantageItem>
          ))}
        </AdvantagesList>
      </Container>
    </Wrapper>
  );
};

export default AdvantagesSection;
