import React from 'react';
import { AreaChart, Area, ResponsiveContainer } from 'recharts';

export interface FormChartProps {
  chartData: { uv: number }[];
}

const FormChart: React.FC<FormChartProps> = ({ chartData }) => {
  return (
    <ResponsiveContainer width="100%" height="100%" minHeight="200px" debounce={50}>
      <AreaChart
        data={chartData}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#cce6ff" stopOpacity={1} />
            <stop offset="100%" stopColor="#cce6ff" stopOpacity={0.3} />
          </linearGradient>
        </defs>
        <Area type="monotone" dataKey="uv" stroke="none" fillOpacity={1} fill="url(#colorUv)" />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default FormChart;
