import styled from '@emotion/styled/macro';
import { Button } from '@mui/material';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 16px 80px 80px;
  ${({ theme }) => theme.media.lg`
    padding: 8px 16px;
  `}
  ${({ theme }) => theme.media.sm`
    padding: 8px 16px;
  `}
  ${({ theme }) => theme.media.xs`
    padding: 8px 8px;
  `}
`;

export const PrimaryNavTab = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;

export const SecondaryNavTab = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
`;

export const Nav = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
  & > svg {
    font-size: 14px;
  }
  & > * {
    margin-right: 16px;
  }
  ${({ theme }) => theme.media.md`
    margin-top: 8px;
  `}
`;

export const Container = styled.div`
  width: 100%;
  padding-top: 20px;
  display: flex;
  align-items: flex-start;
  gap: 40px;
  ${({ theme }) => theme.media.md`
    flex-direction: column;
  `}
`;

export const SideContent = styled.aside``;

export const LeftColumnWrapper = styled.div`
  width: 100%;
`;

export const ButtonBuy = styled(Button)`
  width: 100%;
  height: 51px;
  font-size: 16px;
`;

export const BuyButtonsContainer = styled.div<{ isHidden: boolean; containerWidth?: number }>`
  display: none;
  justify-content: space-between;
  position: fixed;
  width: ${({ containerWidth }) => (containerWidth ? containerWidth + 'px' : '100%')};
  z-index: 1;
  ${({ theme, isHidden }) => theme.media.md`
    display: flex;
    bottom: 14px;
    ${isHidden && `display: none;`}
  `}
  ${({ theme, isHidden }) => theme.media.sm`
    bottom: 88px;
    ${isHidden && `display: none;`}
  `}
`;

export const BuyButtonsContainerStatic = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 41px;
  margin-bottom: 7px;
`;
