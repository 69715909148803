import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  display: flex;
  gap: 8.65px;
`;

export const Input = styled.input`
  width: 33.79px;
  height: 56px;
  padding: 0;
  border-radius: 6px;
  font-size: 16px;
  line-height: 24px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  text-align: center;
  border: 0;
  color: ${({ theme }) => theme.palette.common.rhapsodyInBlue};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.common.ancestralWater};
  outline: 0;
  transition: box-shadow 0.2s ease-out;

  &:focus {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.common.brescianBlue};
  }
`;
