import styled from '@emotion/styled/macro';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  min-width: 480px;
  min-height: 360px;
  max-width: 920px;
  ${({ theme }) => theme.media.sm`
    min-width: 100%;
    max-width: 100%;
    align-self: center;
  `}
`;

export const ModalTitle = styled.div`
  font-size: 24px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  color: ${({ theme }) => theme.palette.common.blackSpace};
  margin-bottom: 20px;
  margin-left: 18px;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const ModalCloseButtonWrapper = styled.div`
  margin-right: 18px;
  cursor: pointer;
`;

export const ModalCoinsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  column-gap: 24px;
`;
