import React from 'react';
// libs
import { useIntl } from 'react-intl';
// material-ui
import { Collapse } from '@mui/material';
// constants
import { NFTS_ITEMS } from './constants';
// styled
import { Wrapper, List, ListItem, ListItemLink } from './styled';

export type NFTPopoverProps = {
  isOpened: boolean;
};

const NFTPopover: React.FC<NFTPopoverProps> = ({ isOpened }) => {
  const { formatMessage } = useIntl();

  return (
    <Collapse in={isOpened} unmountOnExit>
      <Wrapper>
        <List>
          {NFTS_ITEMS.map(({ title, link }, index) => (
            <ListItem key={index}>
              <ListItemLink to={link} tabIndex={1}>
                {formatMessage({ id: title })}
              </ListItemLink>
            </ListItem>
          ))}
        </List>
      </Wrapper>
    </Collapse>
  );
};

export default NFTPopover;
