import styled from '@emotion/styled/macro';
import { Button } from '@mui/material';

export const Wrapper = styled.div`
  width: 1008px;
  margin-top: 142px;

  ${({ theme }) => theme.media.md`
    width: 100%;
    margin-top: 80px;
  `}
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  ${({ theme }) => theme.media.md`
    flex-direction: column;
    align-items: center;
  `}
`;

export const PromotionalSection = styled.div`
  width: 430px;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.md`
    width: 100%;
  `}
`;

export const Title = styled.div`
  font-size: 48px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  line-height: 72px;
  color: ${({ theme }) => theme.palette.common.brescianBlue};

  ${({ theme }) => theme.media.md`
    font-size: 32px;
    line-height: 56px;
  `}
`;

export const Description = styled.div`
  font-size: 18px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  line-height: 36px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  margin-top: 40px;

  ${({ theme }) => theme.media.md`
    font-size: 16px;
    line-height: 30px;
    margin-top: 20px;
  `}
`;

export const ViewButton = styled(Button)`
  width: fit-content;
  height: 51px;
  padding: 16px 32px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  margin-top: 32px;

  ${({ theme }) => theme.media.md`
    font-size: 14px;
    margin-top: 24px;
  `}
`;

export const Image = styled.img`
  width: 414px;
  height: 487px;

  ${({ theme }) => theme.media.xs`
    width: 100%;
    height: 100%;
    margin-top: 28px;
  `}
`;
