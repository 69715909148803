// constants
import { COINMERCE_BASE_URL } from 'constants/general';
// other
import { IntlKeys } from 'localization/keys';

export type FooterListItem = {
  title: IntlKeys;
  link: string;
};

export type FooterList = FooterListItem[];

export const FOOTER_LISTS = (locale: string) =>
  [
    [
      { title: IntlKeys.footerListGeneral, link: '' },
      { title: IntlKeys.footerListGeneralAboutUs, link: `${COINMERCE_BASE_URL}${locale}/about-us` },
      { title: IntlKeys.footerListGeneralCareers, link: '' },
      { title: IntlKeys.footerListGeneralCoins, link: '' },
      { title: IntlKeys.footerListGeneralFraudPrevention, link: '' },
      { title: IntlKeys.footerListGeneralBugFound, link: '' },
      { title: IntlKeys.footerListGeneralApi, link: '' },
      { title: IntlKeys.footerListGeneralSitemap, link: '' },
    ],
    [
      { title: IntlKeys.footerListPopular, link: '' },
      { title: IntlKeys.footerListPopularBuyBitcoin, link: `${COINMERCE_BASE_URL}${locale}/about-us` },
      { title: IntlKeys.footerListPopularBitcoinPrice, link: '' },
      { title: IntlKeys.footerListPopularBuyEthereum, link: `${COINMERCE_BASE_URL}${locale}/ethereum` },
      { title: IntlKeys.footerListPopularEthereumPrice, link: '' },
      { title: IntlKeys.footerListPopularBuyRipple, link: `${COINMERCE_BASE_URL}${locale}/ripple` },
      { title: IntlKeys.footerListPopularRipplePrice, link: '' },
      { title: IntlKeys.footerListPopularBuyLota, link: '' },
      { title: IntlKeys.footerListPopularLotaPrice, link: '' },
    ],
    [
      { title: IntlKeys.footerListLegal, link: '' },
      {
        title: IntlKeys.footerListLegalTermsAndConditions,
        link: `${COINMERCE_BASE_URL}${locale}/terms-and-conditions`,
      },
      { title: IntlKeys.footerListLegalPrivacyPolicy, link: `${COINMERCE_BASE_URL}${locale}/privacy-policy` },
      { title: IntlKeys.footerListLegalRiskDisclaimer, link: `${COINMERCE_BASE_URL}${locale}/risk-disclaimer` },
    ],
    [
      { title: IntlKeys.footerListJumpTo, link: '' },
      { title: IntlKeys.footerListJumpToRegister, link: '' },
      { title: IntlKeys.footerListJumpToLogin, link: '' },
      { title: IntlKeys.footerListJumpToNeedHelp, link: `${COINMERCE_BASE_URL}${locale}/need-help` },
      { title: IntlKeys.footerListJumpToLearn, link: `${COINMERCE_BASE_URL}${locale}/learn` },
    ],
  ] as FooterList[];
