import React, { useCallback } from 'react';
// libs
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
// material-ui
import { Collapse } from '@mui/material';
// store
import { uiLocaleSelector } from 'store/ui';
// constants
import { COINMERCE_BASE_URL } from 'constants/general';
// icons
import { ReactComponent as MyProfileIcon } from 'assets/icons/my-profile.svg';
import { ReactComponent as AccountLevelIcon } from 'assets/icons/account-level.svg';
import { ReactComponent as SecurityIcon } from 'assets/icons/security.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
// other
import { IntlKeys } from 'localization/keys';
// styled
import { Wrapper, List, ListTitle, ListItem, ListItemIconWrapper, ListItemLink, ListItemTitle } from './styled';

export interface AccountPopoverProps {
  isOpened: boolean;
}

export const ACCOUNT_ITEMS = [
  { title: IntlKeys.headerAccountPopoverProfile, icon: MyProfileIcon, link: 'profile' },
  { title: IntlKeys.headerAccountPopoverAccountLevel, icon: AccountLevelIcon, link: 'verification' },
  { title: IntlKeys.headerAccountPopoverSecurity, icon: SecurityIcon, link: 'security' },
  { title: IntlKeys.headerAccountPopoverSettings, icon: SettingsIcon, link: 'settings' },
  // TODO: there is no appropriate icon for the item, change it when you have appropriate one
  { title: IntlKeys.headerAccountPopoverFriends, icon: AccountLevelIcon, link: 'rewards' },
  // TODO: there is no appropriate icon for the item, change it when you have appropriate one
  { title: IntlKeys.headerAccountPopoverWithdrawAddresses, icon: SettingsIcon, link: 'withdraw-addresses' },
  { title: IntlKeys.headerAccountPopoverLogout, icon: LogoutIcon, link: '#' },
];

function AccountPopover({ isOpened }: AccountPopoverProps) {
  const { formatMessage } = useIntl();

  const locale = useSelector(uiLocaleSelector);

  const getCoinMerceLink = useCallback((subLink: string) => `${COINMERCE_BASE_URL}${locale}/${subLink}`, [locale]);

  return (
    <Collapse in={isOpened} unmountOnExit>
      <Wrapper>
        <ListTitle>{formatMessage({ id: IntlKeys.headerAccountPopoverTitle })}</ListTitle>
        <List>
          {ACCOUNT_ITEMS.map(({ title, icon, link }) => {
            const Icon = icon;
            return (
              <ListItem key={title}>
                <ListItemLink href={getCoinMerceLink(link)} tabIndex={1}>
                  <ListItemIconWrapper>
                    <Icon />
                  </ListItemIconWrapper>
                  <ListItemTitle>{formatMessage({ id: title })}</ListItemTitle>
                </ListItemLink>
              </ListItem>
            );
          })}
        </List>
      </Wrapper>
    </Collapse>
  );
}

export default AccountPopover;
