import React, { useMemo } from 'react';
// libs
import { useFormikContext } from 'formik';
// material-ui
import { InputAdornment } from '@mui/material';
// types
import { RegisterFormType } from '../../types';
// icons
import { ReactComponent as CrossIcon } from 'assets/icons/registerPage/cross.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/registerPage/check.svg';
import { ReactComponent as ReferralIcon } from 'assets/icons/registerPage/referal.svg';
import { ReactComponent as EnvelopeIcon } from 'assets/icons/registerPage/envelope.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/registerPage/eye.svg';

export interface Params {
  isPasswordFieldFocused: boolean;
  togglePasswordVisibility: () => void;
}

const useInputsAdornments = ({ isPasswordFieldFocused, togglePasswordVisibility }: Params) => {
  const { errors, touched } = useFormikContext<RegisterFormType>();

  const fullNameInputAdornment = useMemo(() => {
    const isError = Object.keys(errors).some((error) => error === 'fullName');
    const isTouched = Object.keys(touched).some((field) => field === 'fullName');

    if (isError && isTouched) {
      return {
        endAdornment: (
          <InputAdornment position="end">
            <CrossIcon />
          </InputAdornment>
        ),
      };
    }

    if (!isError && isTouched) {
      return {
        endAdornment: (
          <InputAdornment position="end">
            <CheckIcon />
          </InputAdornment>
        ),
      };
    }

    return {};
  }, [errors, touched]);

  const referralInputAdornment = useMemo(
    () => ({
      startAdornment: (
        <InputAdornment position="start">
          <ReferralIcon />
        </InputAdornment>
      ),
    }),
    [],
  );

  const emailInputAdornment = useMemo(() => {
    const defaultValue = {
      startAdornment: (
        <InputAdornment position="start">
          <EnvelopeIcon />
        </InputAdornment>
      ),
    };

    const isError = Object.keys(errors).some((error) => error === 'email');
    const isTouched = Object.keys(touched).some((field) => field === 'email');

    if (isError && isTouched) {
      return {
        ...defaultValue,
        endAdornment: (
          <InputAdornment position="end">
            <CrossIcon />
          </InputAdornment>
        ),
      };
    }

    if (!isError && isTouched) {
      return {
        ...defaultValue,
        endAdornment: (
          <InputAdornment position="end">
            <CheckIcon />
          </InputAdornment>
        ),
      };
    }

    return defaultValue;
  }, [errors, touched]);

  const passwordInputAdornment = useMemo(() => {
    const isError = Object.keys(errors).some((error) => error === 'password');
    const isTouched = Object.keys(touched).some((field) => field === 'password');

    if (isError && isTouched && !isPasswordFieldFocused) {
      return {
        startAdornment: (
          <InputAdornment position="start">
            <EnvelopeIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <CrossIcon />
          </InputAdornment>
        ),
      };
    }

    if (!isError && isTouched && !isPasswordFieldFocused) {
      return {
        startAdornment: (
          <InputAdornment position="start">
            <EnvelopeIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <CheckIcon />
          </InputAdornment>
        ),
      };
    }

    return {
      startAdornment: (
        <InputAdornment position="start">
          <EnvelopeIcon />
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment
          position="end"
          sx={{ cursor: 'pointer' }}
          onClick={togglePasswordVisibility}
          onMouseDown={(event) => event.preventDefault()}
        >
          <EyeIcon />
        </InputAdornment>
      ),
    };
  }, [errors, touched, isPasswordFieldFocused, togglePasswordVisibility]);

  return { fullNameInputAdornment, emailInputAdornment, passwordInputAdornment, referralInputAdornment };
};

export default useInputsAdornments;
