import React from 'react';
// libs
import { useIntl } from 'react-intl';
// constants
import { IntlKeys } from 'localization/keys';
// icons
import checkMark from 'assets/icons/checkmark.svg';
// image
import mobileVisual from 'assets/images/affiliate-page/mobile-visual-alt.png';
// styled
import {
  Wrapper,
  Container,
  Image,
  ListSection,
  Title,
  Description,
  ItemIcon,
  ItemsList,
  ItemText,
  ListItem,
  RegisterButton,
} from './styled';

const LIST_ITEMS = [
  { id: '1', text: IntlKeys.affiliationPageWhyCoinmerceSectionListItem1 },
  { id: '2', text: IntlKeys.affiliationPageWhyCoinmerceSectionListItem2 },
  { id: '3', text: IntlKeys.affiliationPageWhyCoinmerceSectionListItem3 },
  { id: '4', text: IntlKeys.affiliationPageWhyCoinmerceSectionListItem4 },
];

const WhyCoinmerceSection: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Container>
        <Image src={mobileVisual} />
        <ListSection>
          <Title>{formatMessage({ id: IntlKeys.affiliationPageWhyCoinmerceSectionTitle })}</Title>
          <Description>{formatMessage({ id: IntlKeys.affiliationPageWhyCoinmerceSectionDescription })}</Description>
          <ItemsList>
            {LIST_ITEMS.map(({ id, text }) => (
              <ListItem key={id}>
                <ItemIcon src={checkMark} />
                <ItemText>{formatMessage({ id: text })}</ItemText>
              </ListItem>
            ))}
          </ItemsList>
          <RegisterButton>{formatMessage({ id: IntlKeys.affiliationPageWhyCoinmerceSectionButton })}</RegisterButton>
        </ListSection>
      </Container>
    </Wrapper>
  );
};

export default WhyCoinmerceSection;
