import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media.sm`
    align-items: center;
  `}
`;

export const ContentTitle = styled.div`
  width: 100%;
  font-size: 24px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  margin-bottom: 16px;
`;

export const ContentDescription = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  margin-bottom: 16px;
`;

export const TopTenBundleTitle = styled(ContentTitle)``;

export const TopTenBundleDescription = styled(ContentDescription)``;

export const ChartContainer = styled.div`
  width: 100%;
  height: 341px;
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  background-color: ${({ theme }) => theme.palette.common.white};
  margin-bottom: 16px;
  border-radius: 2px;
  //padding: 24px;
  //padding-bottom: 68px;
  position: relative;
  // ${({ theme }) => theme.media.xs`
  //   padding-bottom: 98px;
  //   padding-left: 8px;
  //   padding-right: 0;
  // `}
`;

export const CryptoCardsContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 40px;
  ${({ theme }) => theme.media.sm`
    justify-content: flex-start;
  `}
  ${({ theme }) => theme.media.xs`
    justify-content: center;
  `}
`;

export const CryptoCardShowMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.common.white};
  cursor: pointer;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  width: calc(25% - 12px);
  @media (min-width: 991px) and (max-width: 1139px) {
    width: calc(33.33% - 11px);
  }
  @media (min-width: 501px) and (max-width: 699px) {
    width: calc(33.33% - 11px);
  }
  @media (max-width: 500px) {
    width: calc(50% - 8px);
  }
`;

export const RedistributeBundleTitle = styled(ContentTitle)``;

export const RedistributeBundleDescription = styled(ContentDescription)`
  margin-bottom: 30px;
`;

export const OverhaulTitle = styled(ContentTitle)`
  margin-top: 40px;
`;

export const OverhaulDescription = styled(ContentDescription)`
  margin-bottom: 30px;
`;

export const OtherBundlesTableTitle = styled.div`
  width: 100%;
  font-size: 24px;
  line-height: 28px;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  color: ${({ theme }) => theme.palette.common.blackSpace};
  margin-bottom: 16px;
`;

export const OtherBundlesTableWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 24px;
  padding-bottom: 8px;
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  border-radius: 2px;
  justify-self: center;
  ${({ theme }) => theme.media.sm`
    align-self: center;
  `}
`;
