import React from 'react';
// libs
import { useIntl } from 'react-intl';
// components
import ExchangeForm from '../components/ExchangeForm';
import TrustBoxWitget from './TrustBoxWidget';
// constants
import { IntlKeys } from 'localization/keys';
// icons
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg';
import { ReactComponent as VRAIcon } from 'assets/icons/cryptos/vra.svg';
// styled
import {
  Description,
  DescriptionList,
  DescriptionListItem,
  SubTitle,
  Title,
  TitleContainer,
  TrustBoxWidgetWrapper,
  Wrapper,
} from './styled';

function LeftColumn() {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <TitleContainer>
        <VRAIcon width={40} height={40} />
        <Title>Verasity</Title>
        <SubTitle>VRA</SubTitle>
      </TitleContainer>
      <Description>{formatMessage({ id: IntlKeys.websiteSinglecoinIntro }, { coin: 'Bitcoin (BTC)' })}</Description>
      <ExchangeForm />
      <DescriptionList>
        <DescriptionListItem>
          <CheckmarkIcon />
          <div>{formatMessage({ id: IntlKeys.websiteSinglecoinUsp1 })}</div>
        </DescriptionListItem>
        <DescriptionListItem>
          <CheckmarkIcon />
          <div>{formatMessage({ id: IntlKeys.websiteSinglecoinUsp2 })}</div>
        </DescriptionListItem>
        <DescriptionListItem>
          <CheckmarkIcon />
          <div>{formatMessage({ id: IntlKeys.websiteSinglecoinUsp3 })}</div>
        </DescriptionListItem>
        <DescriptionListItem>
          <CheckmarkIcon />
          <div>{formatMessage({ id: IntlKeys.websiteSinglecoinUsp4 })}</div>
        </DescriptionListItem>
      </DescriptionList>
      <TrustBoxWidgetWrapper>
        <TrustBoxWitget />
      </TrustBoxWidgetWrapper>
    </Wrapper>
  );
}

export default LeftColumn;
