import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
// material
import { Theme } from '@mui/material';
// other
import { focusVisibleStyles } from 'components/styled/common';

const commonListStyles = css`
  display: inline-block;
  vertical-align: top;
  width: 366px;
  max-width: 49%;
  padding-left: 28px;
`;

const commonListIHeadItemStyles = (theme: Theme) => css`
  color: ${theme.palette.common.white};
  font-size: 21px;
  letter-spacing: 1px;
  cursor: default;
  height: 64px;
  display: flex;
  align-items: center;
  font-family: ${theme.typography.fontFamilyVisbyRoundCFSemiBold};
`;

export const Wrapper = styled.div`
  width: 800px;
  height: 632px;
  margin-top: 0;
  margin-bottom: 0;
  background-color: ${({ theme }) => theme.palette.common.infinity};
  padding: 30px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

export const Title = styled.div`
  font-size: 24px;
  line-height: 29px;
  background-color: ${({ theme }) => theme.palette.common.draculaOrchid};
  border-radius: 6px;
  margin-bottom: 30px;
  padding: 30px 20px;
  color: ${({ theme }) => theme.palette.common.white};
  text-align: center;
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
`;

export const Navigation = styled.div`
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
`;

export const ProductsList = styled.ul`
  border-right: 1px solid ${({ theme }) => theme.palette.common.deadForest};
  ${commonListStyles}
`;

export const ProductListHeadItem = styled.li`
  ${({ theme }) => commonListIHeadItemStyles(theme)}
`;

export const AboutList = styled.ul`
  ${commonListStyles}
`;

export const AboutListHeadItem = styled.li`
  display: inline-block;
  border: 0;
  height: auto;
  text-align: left;
  margin: 0 18px;
  padding: 0 4px;
  ${({ theme }) => commonListIHeadItemStyles(theme)}
`;

export const ListItem = styled.li`
  display: flex;
  border: 0;
  height: auto;
  padding: 0 4px;
`;

export const ListItemIconWrapper = styled.div`
  margin-right: 20px;
  display: flex;
`;

export const ListItemTitle = styled.div`
  display: inline-block;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.common.white};
  transition: 0.2s all;
`;

export const ListItemDescription = styled.div`
  display: block;
  margin-top: 3px;
  color: ${({ theme }) => theme.palette.common.nightOut};
  font-size: 12px;
  line-height: 14px;
`;

export const ListItemLink = styled.a`
  display: flex;
  justify-content: left;
  align-items: center;
  line-height: 64px;
  height: 64px;
  text-decoration: none;
  &:focus-visible {
    ${({ theme }) => focusVisibleStyles(theme)}
  }
  &:hover {
    ${ListItemTitle} {
      transform: translateX(12px);
    }
  }
`;
