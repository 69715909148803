import styled from '@emotion/styled/macro';
import { Skeleton } from '@mui/material';

export const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  border-radius: 2px;
  width: calc(33.33% - 27px);

  @media (min-width: 991px) and (max-width: 1180px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Price = styled.div`
  font-size: 21px;
  line-height: 25px;
  letter-spacing: 0.8px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  white-space: nowrap;
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  white-space: nowrap;
`;

export const CardSkeletonWrapper = styled.div`
  width: calc(33.33% - 27px);
  position: relative;

  @media (min-width: 991px) and (max-width: 1180px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CardSkeleton = styled(Skeleton)`
  width: 100%;
  height: 114px;
  border-radius: 2px;
`;

export const PriceSkeleton = styled(Skeleton)`
  width: 100px;
  height: 22px;
  position: absolute;
  top: 36px;
  left: calc(50% - 50px);

  @media (min-width: 991px) and (max-width: 1180px) {
  }
  @media (max-width: 768px) {
  }
`;

export const DescriptionSkeleton = styled(Skeleton)`
  width: 120px;
  height: 18px;
  position: absolute;
  top: 68px;
  left: calc(50% - 60px);

  @media (min-width: 991px) and (max-width: 1180px) {
  }
  @media (max-width: 768px) {
  }
`;
