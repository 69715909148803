import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  height: fit-content;
  ${({ theme }) => theme.media.md`
    width: 100%;
  `}
`;

export const Title = styled.div`
  font-size: 36px;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
`;

export const SubTitle = styled.div`
  font-size: 24px;
  display: flex;
  line-height: 30px;
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  align-self: flex-end;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 8px;
  }
`;

export const Description = styled.div`
  font-size: 16px;
  width: 360px;
  padding-top: 16px;
  padding-bottom: 16px;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  ${({ theme }) => theme.media.sm`
    width: 100%;
  `}
`;

export const DescriptionList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  justify-items: center;
  ${({ theme }) => theme.media.xs`
    display: none;
  `}
`;

export const DescriptionListItem = styled.div`
  display: flex;
  align-items: center;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  & > * {
    margin-right: 8px;
  }
`;

export const TrustBoxWidgetWrapper = styled.div`
  position: relative;
  display: flex;
  width: 260px;
  top: 28px;
  left: -18px;
  ${({ theme }) => theme.media.md`
    margin-bottom: 28px;
  `}
  ${({ theme }) => theme.media.xs`
    display: none;
  `}
`;
