import React from 'react';
// components
import RegisterStep from './steps/RegisterStep';
import CheckEmailStep from './steps/CheckEmailStep';
import AccountDetailsStep from './steps/AccountDetailsStep';
// contexts
import { useRegisterStepperContext } from 'providers/RegisterStepperProvider';
// icons
import { ReactComponent as ArrowBackIcon } from 'assets/icons/arrow-back.svg';
// styled
import { BackButton, BackButtonAbsolute, Container, Title, Wrapper } from './styled';

const STEPS = [<RegisterStep key="register" />, <CheckEmailStep key="email" />, <AccountDetailsStep key="account" />];

const STEP_TITLES = ['Register', 'Check Email', 'Welcome[Name]!'];

const RegisterPage: React.FC = () => {
  const { handleBackStep, activeStep, steps } = useRegisterStepperContext();

  return (
    <Wrapper>
      {activeStep > 0 ? (
        <BackButtonAbsolute onClick={handleBackStep} startIcon={<ArrowBackIcon />}>
          Back
        </BackButtonAbsolute>
      ) : null}
      <Container>
        {activeStep > 0 ? (
          <BackButton onClick={handleBackStep} startIcon={<ArrowBackIcon />}>
            Back
          </BackButton>
        ) : null}
        <Title>{STEP_TITLES?.[activeStep]}</Title>
        {STEPS.length === steps.length ? STEPS?.[activeStep] : null}
      </Container>
    </Wrapper>
  );
};

export default React.memo(RegisterPage);
