import React from 'react';
// libs
import { Routes as ReactRouterDomRoutes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// components
import MainLayout from 'components/layouts/MainLayout';
import RegisterLayout from 'components/layouts/RegisterLayout';
import LangRoute from '../LangRoute';
// hooks
import useScrollToTop from 'hooks/useScrollToTop';
// store
import { uiLocaleSelector } from 'store/ui';
// constants
import { Colors } from 'themes/colors';
// pages
import BundlesPage from 'pages/BundlesPage';
import SingleCoinViewPage from 'pages/SingleCoinViewPage';
import DashboardPage from 'pages/DashboardPage';
import AffiliateProgramPage from 'pages/AffiliateProgramPage';
import BotsPage from 'pages/BotsPage';
import RegisterPage from 'pages/RegisterPage';

const Routes = () => {
  const locale = useSelector(uiLocaleSelector);

  useScrollToTop();

  return (
    <ReactRouterDomRoutes>
      <Route path="/">
        <Route index element={<Navigate to={`/${locale}/affiliate-program`} replace />} />

        <Route path=":locale" element={<LangRoute />}>
          <Route index element={<Navigate to={`/${locale}/affiliate-program`} replace />} />
          <Route element={<MainLayout />}>
            <Route path="bundles" element={<BundlesPage />} />
            <Route path="coins" element={<SingleCoinViewPage />} />
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="affiliate-program" element={<AffiliateProgramPage />} />
          </Route>

          <Route element={<MainLayout unauthHeaderBgColor={Colors.whiteOut} />}>
            <Route path="bots" element={<BotsPage />} />
          </Route>

          <Route element={<RegisterLayout />}>
            <Route path="register" element={<RegisterPage />} />
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<Navigate to={`/${locale}/affiliate-program`} replace />} />
    </ReactRouterDomRoutes>
  );
};

export default Routes;
