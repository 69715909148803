import React, { useCallback } from 'react';
// libs
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
// material-ui
import { Collapse } from '@mui/material';
// store
import { uiLocaleSelector } from 'store/ui';
// constants
import { PRODUCTS_ITEMS, ABOUT_ITEMS, ListItemsType } from 'components/layouts/constants';
import { COINMERCE_BASE_URL } from 'constants/general';
// other
import { IntlKeys } from 'localization/keys';
// styled
import {
  Wrapper,
  Title,
  Navigation,
  ProductsList,
  ProductListHeadItem,
  AboutList,
  AboutListHeadItem,
  ListItem,
  ListItemDescription,
  ListItemTitle,
  ListItemLink,
  ListItemIconWrapper,
} from './styled';

export interface ExplorePopoverProps {
  isOpened: boolean;
}

function ExplorePopover({ isOpened }: ExplorePopoverProps) {
  const { formatMessage } = useIntl();

  const locale = useSelector(uiLocaleSelector);

  const getCoinMerceLink = useCallback((subLink: string) => `${COINMERCE_BASE_URL}${locale}/${subLink}`, [locale]);

  const renderListItems = useCallback(
    (list: ListItemsType) =>
      list.map(({ title, description, icon, link }) => {
        const Icon = icon;
        return (
          <ListItem key={title}>
            <ListItemLink href={getCoinMerceLink(link)} tabIndex={1}>
              <ListItemIconWrapper>
                <Icon />
              </ListItemIconWrapper>
              <ListItemTitle>
                {formatMessage({ id: title })}
                <ListItemDescription>{formatMessage({ id: description })}</ListItemDescription>
              </ListItemTitle>
            </ListItemLink>
          </ListItem>
        );
      }),
    [formatMessage, getCoinMerceLink],
  );

  return (
    <Collapse in={isOpened} unmountOnExit>
      <Wrapper>
        <Title>{formatMessage({ id: IntlKeys.headerExplorePopoverTitle })}</Title>
        <Navigation>
          <ProductsList>
            <>
              <ProductListHeadItem>{formatMessage({ id: IntlKeys.headerExplorePopoverProducts })}</ProductListHeadItem>
              {renderListItems(PRODUCTS_ITEMS)}
            </>
          </ProductsList>
          <AboutList>
            <>
              <AboutListHeadItem>{formatMessage({ id: IntlKeys.headerExplorePopoverAbout })}</AboutListHeadItem>
              {renderListItems(ABOUT_ITEMS)}
            </>
          </AboutList>
        </Navigation>
      </Wrapper>
    </Collapse>
  );
}

export default ExplorePopover;
