import React from 'react';
// libs
import { useIntl } from 'react-intl';
// material-ui
import { InputAdornment } from '@mui/material';
// icons
import { ReactComponent as ArrowForwardIcon } from 'assets/icons/arrow-forward.svg';
import { ReactComponent as IDealLogo } from 'assets/icons/i-deal-logo.svg';
import { ReactComponent as SofortLogo } from 'assets/icons/sofort-logo.svg';
import { ReactComponent as SepaLogo } from 'assets/icons/sepa-logo.svg';
import { ReactComponent as BancontactLogo } from 'assets/icons/bancontact-logo.svg';
// styled
import {
  ActionButton,
  ActionButtonWrapper,
  CryptoContainer,
  CryptoLabel,
  CurrencyTextField,
  LogosContainer,
} from './styled';
import { IntlKeys } from '../../../../../localization/keys';

export interface FormTabContent {
  isSelling?: boolean;
}

const CURRENCY_INPUT_PROPS = {
  startAdornment: <InputAdornment position="start">€</InputAdornment>,
};

function FormTabContent({ isSelling }: FormTabContent) {
  const { formatMessage } = useIntl();

  return (
    <>
      <CryptoContainer>
        <CurrencyTextField autoComplete="off" size="small" type="number" InputProps={CURRENCY_INPUT_PROPS} />
        <CryptoLabel>{formatMessage({ id: IntlKeys.websiteBundleviewMax })}</CryptoLabel>
      </CryptoContainer>
      <ActionButtonWrapper>
        <ActionButton fullWidth endIcon={<ArrowForwardIcon />}>
          {formatMessage({
            id: isSelling ? IntlKeys.websiteBundleviewSellButton : IntlKeys.websiteBundleviewBuyButton,
          })}
        </ActionButton>
      </ActionButtonWrapper>
      <LogosContainer>
        <IDealLogo />
        <SofortLogo />
        <SepaLogo />
        <BancontactLogo />
      </LogosContainer>
    </>
  );
}

export default FormTabContent;
