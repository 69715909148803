import React from 'react';
// libs
import { useIntl } from 'react-intl';
// constants
import { IntlKeys } from 'localization/keys';
// images
import cryptoRate from 'assets/images/affiliate-page/crypto-rate.png';
// styled
import {
  Container,
  Description,
  DescriptionSection,
  GoToExplanationButton,
  Image,
  ImageSection,
  Title,
  Wrapper,
} from './styled';

const CryptoRateWidgetSection: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Title>{formatMessage({ id: IntlKeys.affiliationPageCryptoRateWidgetSectionTitle })}</Title>
      <Container>
        <ImageSection>
          <Image src={cryptoRate} />
        </ImageSection>
        <DescriptionSection>
          <Description>{formatMessage({ id: IntlKeys.affiliationPageCryptoRateWidgetSectionDescription })}</Description>
          <GoToExplanationButton>
            {formatMessage({ id: IntlKeys.affiliationPageCryptoRateWidgetSectionButton })}
          </GoToExplanationButton>
        </DescriptionSection>
      </Container>
    </Wrapper>
  );
};

export default CryptoRateWidgetSection;
