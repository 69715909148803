import styled from '@emotion/styled/macro';
import { Button } from '@mui/material';
import { StyledTextField } from '../styled';

export const ActionButton = styled(Button)`
  font-size: 16px;
  & .MuiButton-endIcon {
    margin-top: 4px;
    font-size: 16px;
  }
`;

export const ActionButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 51px;
`;

export const CryptoContainer = styled.div`
  display: flex;
  margin-bottom: 12px;
  column-gap: 19px;
`;

export const CryptoLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76px;
  padding: 16px;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  height: 50px;
  border-radius: 3px;
  cursor: pointer;
  & > img {
    margin-right: 16px;
  }
`;

export const CurrencyTextField = styled(StyledTextField)`
  margin-bottom: 20px;
  & .MuiInputAdornment-root > p {
    margin-bottom: 1.5px;
  }
`;

export const LogosContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin-right: 8px;
  }
`;
