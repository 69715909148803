import React, { useState, useCallback, useMemo, useEffect } from 'react';
// libs
import { addSeconds, differenceInSeconds } from 'date-fns';
// hooks
import { useRegisterStepperContext } from 'providers/RegisterStepperProvider';
// components
import VerifyCodeInput, { VerificationCodeHandle } from './VerifyCodeInput';
// icons
import { ReactComponent as ArrowForwardIcon } from 'assets/icons/arrow-forward.svg';
// styled
import {
  Container,
  Description,
  NextButton,
  PasteButton,
  ResendText,
  Title,
  VerifyCodeContainer,
  Wrapper,
  ResendLink,
} from './styled';

const CheckEmailStep: React.FC = () => {
  const { handleNextStep } = useRegisterStepperContext();
  const [emailVerificationCode, setEmailVerificationCode] = useState('');
  const [resendTime, setResendTime] = useState<Date>(addSeconds(Date.now(), 16));
  const [seconds, setSeconds] = useState(0);
  const verifyCodeEl = React.useRef<VerificationCodeHandle>(null);

  const handleVerifyCodeInputChange = useCallback((value: string) => {
    setEmailVerificationCode(value);
  }, []);

  const handlePasteClick = useCallback(async () => {
    const clipboardText = await navigator.clipboard.readText();
    setEmailVerificationCode(clipboardText);
    verifyCodeEl?.current?.forceUpdateValue(clipboardText);
  }, []);

  useEffect(() => {
    console.log(emailVerificationCode);
  }, [emailVerificationCode]);

  const isNextButtonDisabled = useMemo(() => emailVerificationCode?.length < 6, [emailVerificationCode]);

  const updateSeconds = useCallback(() => {
    const time = resendTime.getTime() - Date.now();
    setSeconds(Math.floor((time / 1000) % 60));
  }, [resendTime]);

  const handleResendTimeClick = useCallback(() => {
    setResendTime(addSeconds(Date.now(), 16));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => updateSeconds(), 1000);
    if (differenceInSeconds(Date.now(), resendTime) >= 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [updateSeconds, resendTime]);

  useEffect(() => {
    updateSeconds();
  }, [updateSeconds]);

  return (
    <Wrapper>
      <Container>
        <Title>Enter the code we emailed you</Title>
        <VerifyCodeContainer>
          <VerifyCodeInput
            onChange={handleVerifyCodeInputChange}
            handleInputPaste={setEmailVerificationCode}
            value={emailVerificationCode}
            ref={verifyCodeEl}
          />
          <PasteButton onClick={handlePasteClick}>Paste</PasteButton>
        </VerifyCodeContainer>
        {differenceInSeconds(Date.now(), resendTime) >= 0 ? (
          <ResendLink onClick={handleResendTimeClick}>Resend</ResendLink>
        ) : (
          <ResendText>Resend {seconds}s</ResendText>
        )}
        <Description>
          If you cannot find it, look into your spam folder. Still nothing? Try resending the code.
        </Description>
      </Container>
      <NextButton onClick={handleNextStep} endIcon={<ArrowForwardIcon />} disabled={isNextButtonDisabled}>
        Next
      </NextButton>
    </Wrapper>
  );
};

export default CheckEmailStep;
