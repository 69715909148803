import styled from '@emotion/styled/macro';
import { focusVisibleStyles } from 'components/styled/common';
import { MenuItem } from '@mui/material';

export const ContactsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

export const ContactsContent = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 12px;
`;

export const ContactsKVKContainer = styled.div`
  padding: 0 15px;
  flex-grow: 0.1;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.common.smokyStudio};
  order: 1;
`;

export const ContactsOfficeContainer = styled.div`
  padding: 0 15px;
  flex-grow: 1;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.common.smokyStudio};
  order: 2;
`;

export const ContactsLangContainer = styled.div`
  padding: 0 15px;
  //flex-grow: 1;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.common.smokyStudio};
  order: 3;
  display: flex;
`;

export const ContactsListTitle = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
`;

export const ContactsWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 100px 0 180px;

  ${({ theme }) => theme.media.md`
    padding: 0;
  `}
`;

export const Logo = styled.a`
  min-width: 194px;
  max-width: 194px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-right: auto;
  &:focus-visible {
    ${({ theme }) => focusVisibleStyles(theme)}
  }
`;

export const LogoContainer = styled.div`
  padding: 0 15px;
  flex-grow: 0.1;
  ${({ theme }) => theme.media.xs`
    width: 100%;
  `}
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.common.white};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  background-color: #212931;
`;
