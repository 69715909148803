import React, { useCallback, useMemo } from 'react';
// libs
import { useStickyBox } from 'react-sticky-box';
import { useIntl } from 'react-intl';
// components
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
// hooks
import useVisibility from 'hooks/useVisible';
import useWindowSize from 'hooks/useWindowSize';
// constants
import { breakpoints } from 'themes/mediaQueries';
import { IntlKeys } from 'localization/keys';
// icons
import { ReactComponent as ArrowForwardIcon } from 'assets/icons/arrow-forward.svg';
import { ReactComponent as ArrowBackwardIcon } from 'assets/icons/arrow-backward.svg';
// styled
import {
  Nav,
  Wrapper,
  Container,
  PrimaryNavTab,
  SecondaryNavTab,
  SideContent,
  LeftColumnWrapper,
  BuyButtonsContainer,
  BuyButtonsContainerStatic,
  ButtonBuy,
} from './styles';

function BundlesPage() {
  const { formatMessage } = useIntl();

  const [isButtonsContainerVisible, buttonsContainerElement] = useVisibility<HTMLDivElement>(0, 0);
  const [isLeftColumnVisible, leftColumnElement] = useVisibility<HTMLDivElement>(40, 0, 'bottom', true);

  const stickyRef = useStickyBox({ offsetTop: 20, offsetBottom: 20 });
  const [width] = useWindowSize();

  const smallerThanMd = useMemo(() => width <= breakpoints.md, [width]);

  const hideButtons = useMemo(
    () => isButtonsContainerVisible || isLeftColumnVisible,
    [isButtonsContainerVisible, isLeftColumnVisible],
  );

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, []);

  return (
    <Wrapper>
      <Nav>
        {smallerThanMd ? (
          <ArrowBackwardIcon />
        ) : (
          <>
            <PrimaryNavTab>{formatMessage({ id: IntlKeys.websiteBundleviewBreadcrumbBundles })}</PrimaryNavTab>
            <ArrowForwardIcon width="11px" height="11px" />
            <SecondaryNavTab>Top 10</SecondaryNavTab>
          </>
        )}
      </Nav>
      <Container>
        {smallerThanMd ? (
          <LeftColumnWrapper ref={leftColumnElement}>
            <LeftColumn />
          </LeftColumnWrapper>
        ) : (
          <SideContent ref={stickyRef}>
            <LeftColumn />
          </SideContent>
        )}
        <RightColumn />
      </Container>
      <BuyButtonsContainer containerWidth={buttonsContainerElement.current?.offsetWidth} isHidden={hideButtons}>
        <ButtonBuy onClick={scrollToTop}>Buy TOP10 Now</ButtonBuy>
      </BuyButtonsContainer>
      {smallerThanMd && (
        <BuyButtonsContainerStatic ref={buttonsContainerElement}>
          <ButtonBuy onClick={scrollToTop}>
            {formatMessage({ id: IntlKeys.websiteBundleviewBuyButtonMobile }, { bundle: 'Top 10' })}
          </ButtonBuy>
        </BuyButtonsContainerStatic>
      )}
    </Wrapper>
  );
}

export default BundlesPage;
