import React from 'react';
// libs
import { useIntl } from 'react-intl';
// components
import YoutubeEmbed from 'components/YoutubeEmbed';
// constants
import { IntlKeys } from 'localization/keys';
// styled
import { Wrapper, Container, AboutSection, Title, Description, VideoSection } from './styled';

const YOUTUBE_EMBED_CODE = '9ZAF8ggFhUg';

const HowItWorksSection = () => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Container>
        <AboutSection>
          <Title>{formatMessage({ id: IntlKeys.botsPageHowItWorksSectionTitle })}</Title>
          <Description>{formatMessage({ id: IntlKeys.botsPageHowItWorksSectionDescription })}</Description>
        </AboutSection>
        <VideoSection>
          <YoutubeEmbed embedId={YOUTUBE_EMBED_CODE} />
        </VideoSection>
      </Container>
    </Wrapper>
  );
};

export default HowItWorksSection;
