import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';

export const FormTabsContainer = styled.div`
  display: flex;
  & > * {
    margin-right: 32px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const TabContent = styled.div`
  margin-top: 32px;
`;

export const FormTab = styled.button<{ selected?: boolean }>`
  font-size: 21px;
  line-height: 25px;
  padding: 7px 15px;
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
  font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  border: 1px solid transparent;
  cursor: pointer;
  ${({ selected, theme }) =>
    selected &&
    css`
      border: 1px solid ${theme.palette.common.snowBank};
      border-radius: 3px;
      color: ${theme.palette.common.brescianBlue};
    `}
`;
