import styled from '@emotion/styled/macro';

export const Wrapper = styled.div<{ visible: boolean; position: { top: number; left: number } }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  top: ${({ position }) => position.top + 18}px;
  left: ${({ position }) => position.left - 40}px;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  font-size: 12px;
  width: 80px;
  border-radius: 3px;
  position: absolute;
  transition: all 0.1s ease;
  padding: 2px 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
`;

export const Title = styled.div`
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
`;

export const List = styled.ul``;

export const ListItem = styled.li`
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
`;
