import React from 'react';
// icons
import { ReactComponent as POSIcon } from 'assets/icons/proof-of-stake.svg';
import { ReactComponent as BotsIcon } from 'assets/icons/bots.svg';
import { ReactComponent as BeginnerIcon } from 'assets/icons/beginner.svg';
// import { ReactComponent as AdvancedIcon } from 'assets/icons/advanced.svg';
// import { ReactComponent as AboutIcon } from 'assets/icons/about.svg';
// import { ReactComponent as NewsIcon } from 'assets/icons/news.svg';
import { ReactComponent as LearnIcon } from 'assets/icons/learn.svg';
import { ReactComponent as MyProfileIcon } from '../../assets/icons/my-profile.svg';
import { ReactComponent as AccountLevelIcon } from '../../assets/icons/account-level.svg';
import { ReactComponent as SecurityIcon } from '../../assets/icons/security.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';

import { ReactComponent as EarnIcon } from 'assets/icons/header/earn.svg';
import { ReactComponent as StakingIcon } from 'assets/icons/header/stacking.svg';
import { ReactComponent as AffiliateIcon } from 'assets/icons/header/affiliate.svg';
import { ReactComponent as BotIcon } from 'assets/icons/header/bot.svg';
import { ReactComponent as NewsIcon } from 'assets/icons/header/news.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/header/info.svg';
import { ReactComponent as QuestionIcon } from 'assets/icons/header/question.svg';
import { ReactComponent as BeginnersIcon } from 'assets/icons/header/beginners.svg';
import { ReactComponent as AdvancedIcon } from 'assets/icons/header/advanced.svg';
import { ReactComponent as AppIcon } from 'assets/icons/header/app.svg';
import { ReactComponent as BookIcon } from 'assets/icons/header/book.svg';
import { ReactComponent as AboutIcon } from 'assets/icons/header/about.svg';
import { ReactComponent as VacanciesIcon } from 'assets/icons/header/vacancies.svg';
// other
import { IntlKeys } from 'localization/keys';

export type ListItemsType = {
  title: IntlKeys;
  description: IntlKeys;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  link: string;
}[];

export type ListItem = {
  id: string;
  title: IntlKeys;
  description: IntlKeys;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  link: string;
};

export const PRODUCTS_ITEMS = [
  {
    title: IntlKeys.headerExplorePopoverProductItemsProofTitle,
    description: IntlKeys.headerExplorePopoverProductItemsProofDescription,
    icon: POSIcon,
    link: 'proof-of-stake',
  },
  // TODO: add small bot icon when it will be available
  {
    title: IntlKeys.headerExplorePopoverProductItemsBotsTitle,
    description: IntlKeys.headerExplorePopoverProductItemsBotsDescription,
    icon: BotsIcon,
    link: 'coinmerce-bots',
  },
  {
    title: IntlKeys.headerExplorePopoverProductItemsBeginnersTitle,
    description: IntlKeys.headerExplorePopoverProductItemsBeginnersDescription,
    icon: BeginnerIcon,
    link: 'beginner',
  },
  {
    title: IntlKeys.headerExplorePopoverProductItemsAdvancedTitle,
    description: IntlKeys.headerExplorePopoverProductItemsAdvancedDescription,
    icon: AdvancedIcon,
    link: 'advanced',
  },
  {
    title: IntlKeys.headerExplorePopoverProductItemsGiftcardsTitle,
    description: IntlKeys.headerExplorePopoverProductItemsGiftcardsDescription,
    icon: AboutIcon,
    link: 'giftcards',
  },
] as ListItemsType;

export const ABOUT_ITEMS = [
  {
    title: IntlKeys.headerExplorePopoverAboutItemsAboutTitle,
    description: IntlKeys.headerExplorePopoverAboutItemsAboutDescription,
    icon: AboutIcon,
    link: 'about-us',
  },
  // TODO: there is no appropriate icon for the item, change it when you have appropriate one
  {
    title: IntlKeys.headerExplorePopoverAboutItemsCryptoTitle,
    description: IntlKeys.headerExplorePopoverAboutItemsCryptoDescription,
    icon: BotsIcon,
    link: 'store',
  },
  {
    title: IntlKeys.headerExplorePopoverAboutItemsNewsTitle,
    description: IntlKeys.headerExplorePopoverAboutItemsNewsDescription,
    icon: NewsIcon,
    link: 'news',
  },
  {
    title: IntlKeys.headerExplorePopoverAboutItemsLearnTitle,
    description: IntlKeys.headerExplorePopoverAboutItemsLearnDescription,
    icon: LearnIcon,
    link: 'learn',
  },
  {
    title: IntlKeys.headerExplorePopoverAboutItemsHelpTitle,
    description: IntlKeys.headerExplorePopoverAboutItemsHelpDescription,
    icon: LearnIcon,
    link: 'need-help',
  },
  // TODO: there is no appropriate icon for the item, change it when you have appropriate one
  {
    title: IntlKeys.headerExplorePopoverAboutItemsVacanciesDescription,
    description: IntlKeys.headerExplorePopoverAboutItemsVacanciesDescription,
    icon: BotsIcon,
    link: 'vacancies',
  },
] as ListItemsType;

export const NFTS_ITEMS = [
  // {
  //   title: IntlKeys.headerNFTPopoverCreate,
  //   // TODO: replace text
  //   description: IntlKeys.commonLorem,
  //   icon: NewsIcon,
  //   link: 'create-nft',
  // },
  {
    title: IntlKeys.headerNFTPopoverLaunchpad,
    // TODO: replace text
    description: IntlKeys.commonLorem,
    icon: NewsIcon,
    link: '/',
  },
  {
    title: IntlKeys.headerNFTPopoverMarketplace,
    // TODO: replace text
    description: IntlKeys.commonLorem,
    icon: NewsIcon,
    link: 'marketplace',
  },
  {
    title: IntlKeys.headerNFTPopoverMyCollections,
    // TODO: replace text
    description: IntlKeys.commonLorem,
    icon: NewsIcon,
    link: 'my-collections',
  },
];

export const ACCOUNT_ITEMS = [
  { title: IntlKeys.headerAccountPopoverProfile, icon: MyProfileIcon, link: 'profile' },
  { title: IntlKeys.headerAccountPopoverAccountLevel, icon: AccountLevelIcon, link: 'verification' },
  { title: IntlKeys.headerAccountPopoverSecurity, icon: SecurityIcon, link: 'security' },
  { title: IntlKeys.headerAccountPopoverSettings, icon: SettingsIcon, link: 'settings' },
  // TODO: there is no appropriate icon for the item, change it when you have appropriate one
  { title: IntlKeys.headerAccountPopoverFriends, icon: AccountLevelIcon, link: 'rewards' },
  // TODO: there is no appropriate icon for the item, change it when you have appropriate one
  { title: IntlKeys.headerAccountPopoverWithdrawAddresses, icon: SettingsIcon, link: 'withdraw-addresses' },
  { title: IntlKeys.headerAccountPopoverLogout, icon: LogoutIcon, link: '#' },
];

export const PASSIVE_INCOME_ITEMS = [
  {
    id: '1',
    title: IntlKeys.headerPassiveIncomePopoverItemTitle1,
    description: IntlKeys.headerPassiveIncomePopoverItemDescription1,
    icon: EarnIcon,
    link: '#',
  },
  {
    id: '2',
    title: IntlKeys.headerPassiveIncomePopoverItemTitle2,
    description: IntlKeys.headerPassiveIncomePopoverItemDescription2,
    icon: StakingIcon,
    link: '#',
  },
  {
    id: '3',
    title: IntlKeys.headerPassiveIncomePopoverItemTitle3,
    description: IntlKeys.headerPassiveIncomePopoverItemDescription3,
    icon: AffiliateIcon,
    link: '#',
  },
] as ListItem[];

export const BOTS_ITEMS = [
  {
    id: '1',
    title: IntlKeys.headerBotsPopoverItemTitle1,
    description: IntlKeys.headerBotsPopoverItemDescription1,
    icon: BotIcon,
    link: '#',
  },
] as ListItem[];

export const LEARN_ITEMS = [
  {
    id: '1',
    title: IntlKeys.headerLearnPopoverItemTitle1,
    description: IntlKeys.headerLearnPopoverItemDescription1,
    icon: NewsIcon,
    link: '#',
  },
  {
    id: '2',
    title: IntlKeys.headerLearnPopoverItemTitle2,
    description: IntlKeys.headerLearnPopoverItemDescription2,
    icon: InfoIcon,
    link: '#',
  },
  {
    id: '3',
    title: IntlKeys.headerLearnPopoverItemTitle3,
    description: IntlKeys.headerLearnPopoverItemDescription3,
    icon: QuestionIcon,
    link: '#',
  },
  {
    id: '4',
    title: IntlKeys.headerLearnPopoverItemTitle4,
    description: IntlKeys.headerLearnPopoverItemDescription4,
    icon: BeginnersIcon,
    link: '#',
  },
  {
    id: '5',
    title: IntlKeys.headerLearnPopoverItemTitle5,
    description: IntlKeys.headerLearnPopoverItemDescription5,
    icon: AdvancedIcon,
    link: '#',
  },
] as ListItem[];

export const ABOUT_ITEMS_ALT = [
  {
    id: '1',
    title: IntlKeys.headerAboutPopoverItemTitle1,
    description: IntlKeys.headerAboutPopoverItemDescription1,
    icon: AppIcon,
    link: '#',
  },
  {
    id: '2',
    title: IntlKeys.headerAboutPopoverItemTitle2,
    description: IntlKeys.headerAboutPopoverItemDescription2,
    icon: BookIcon,
    link: '#',
  },
  {
    id: '3',
    title: IntlKeys.headerAboutPopoverItemTitle3,
    description: IntlKeys.headerAboutPopoverItemDescription3,
    icon: AboutIcon,
    link: '#',
  },
  {
    id: '4',
    title: IntlKeys.headerAboutPopoverItemTitle4,
    description: IntlKeys.headerAboutPopoverItemDescription4,
    icon: VacanciesIcon,
    link: '#',
  },
] as ListItem[];
