import { useCallback, useLayoutEffect, useState } from 'react';

const useScreenWidth = () => {
  const [size, setSize] = useState(window.screen.width);

  const updateSize = useCallback(() => {
    setSize(window.screen.width);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, [updateSize]);

  return size;
};

export default useScreenWidth;
