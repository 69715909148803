import React, { useCallback, useMemo } from 'react';
// libs
import { useIntl } from 'react-intl';
import { Field, useFormikContext } from 'formik';
// material-ui
import { Tooltip } from '@mui/material';
// hooks
import useInputsAdornments from './hooks/useInputsAdornments';
// constant
import { IntlKeys } from 'localization/keys';
// types
import { RegisterFormType } from '../types';
import { PasswordState, PasswordHintKeys } from '../index';
// icons
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/registerPage/cross.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/registerPage/check.svg';
import { ReactComponent as BulletIcon } from 'assets/icons/registerPage/bullet.svg';
// styled
import {
  InnerContainer,
  Link,
  PasswordHintItem,
  PasswordHintText,
  PasswordHitsLeftColumn,
  PasswordHitsRightColumn,
  PasswordsHistContainer,
  PepFieldWrapper,
  StyledFormCheckbox,
  StyledFormTextField,
  Form,
  UsCitizenFieldWrapper,
} from '../styled';

export interface RegisterFormProps {
  passwordState: PasswordState;
  isPasswordVisible: boolean;
  isPasswordFieldFocused: boolean;
  handlePasswordFieldFocusChange: (value: boolean) => () => void;
  togglePasswordVisibility: () => void;
}

const RegisterForm: React.FC<RegisterFormProps> = ({
  passwordState,
  isPasswordVisible,
  isPasswordFieldFocused,
  handlePasswordFieldFocusChange,
  togglePasswordVisibility,
}) => {
  const { formatMessage } = useIntl();
  const { errors, touched, handleBlur } = useFormikContext<RegisterFormType>();
  const { fullNameInputAdornment, emailInputAdornment, referralInputAdornment, passwordInputAdornment } =
    useInputsAdornments({ isPasswordFieldFocused, togglePasswordVisibility });

  const getHintItemIcon = useCallback(
    (hintName: PasswordHintKeys) => {
      switch (passwordState[hintName]) {
        case 'error':
          return <CrossIcon />;
        case 'success':
          return <CheckIcon />;
        case 'default':
          return <BulletIcon />;
      }
    },
    [passwordState],
  );

  const getHintItemText = useCallback((hintName: PasswordHintKeys) => {
    switch (hintName) {
      case 'oneLowercaseChar':
        return '1 lowercase character';
      case 'oneUppercaseChar':
        return '1 uppercase character';
      case 'oneNumber':
        return '1 number';
      case 'oneSpecialChar':
        return '1 special character';
      case 'minEightChars':
        return 'min 8 characters';
    }
  }, []);

  const isFullNameSuccess = useMemo(
    () =>
      !Object.keys(errors).some((error) => error === 'fullName') &&
      Object.keys(touched).some((field) => field === 'fullName'),
    [errors, touched],
  );

  const isPasswordSuccess = useMemo(
    () =>
      Object.values(passwordState).every((item) => item === 'success') &&
      Object.keys(touched).some((field) => field === 'password'),
    [touched, passwordState],
  );

  const isEmailSuccess = useMemo(
    () =>
      !Object.keys(errors).some((error) => error === 'email') &&
      Object.keys(touched).some((field) => field === 'email'),
    [errors, touched],
  );

  const handlePasswordFieldBlur = useCallback(
    (event: React.FocusEvent<any>) => {
      handleBlur(event);
      handlePasswordFieldFocusChange(false)();
    },
    [handleBlur, handlePasswordFieldFocusChange],
  );

  return (
    <Form>
      <InnerContainer>
        <Field
          name="fullName"
          component={StyledFormTextField}
          label="Full name"
          InputProps={fullNameInputAdornment}
          autoComplete="off"
          placeholder="John Christian van Andel"
          success={isFullNameSuccess}
          autoFocus
        />

        <Field
          name="email"
          component={StyledFormTextField}
          label="Email"
          InputProps={emailInputAdornment}
          autoComplete="off"
          placeholder="your.email@address.com"
          success={isEmailSuccess}
        />

        <Field
          name="password"
          component={StyledFormTextField}
          label="Password"
          InputProps={passwordInputAdornment}
          type={isPasswordVisible ? 'text' : 'password'}
          autoComplete="off"
          placeholder="Y0uRP@$$w0rD73832"
          success={isPasswordSuccess}
          onFocus={handlePasswordFieldFocusChange(true)}
          onBlur={handlePasswordFieldBlur}
          isPasswordField
        />

        <PasswordsHistContainer>
          <PasswordHitsLeftColumn>
            {Object.entries(passwordState)
              .slice(0, 3)
              .map(([key, value]) => (
                <PasswordHintItem key={key} hintStatus={value}>
                  {getHintItemIcon(key as PasswordHintKeys)}
                  <PasswordHintText>{getHintItemText(key as PasswordHintKeys)}</PasswordHintText>
                </PasswordHintItem>
              ))}
          </PasswordHitsLeftColumn>
          <PasswordHitsRightColumn>
            {Object.entries(passwordState)
              .slice(3)
              .map(([key, value]) => (
                <PasswordHintItem key={key} hintStatus={value}>
                  {getHintItemIcon(key as PasswordHintKeys)}
                  <PasswordHintText>{getHintItemText(key as PasswordHintKeys)}</PasswordHintText>
                </PasswordHintItem>
              ))}
          </PasswordHitsRightColumn>
        </PasswordsHistContainer>

        <Field
          name="referralCode"
          component={StyledFormTextField}
          label="Do you have a referral code?"
          InputProps={referralInputAdornment}
          autoComplete="off"
          placeholder="Enter referral code"
          optionalLabel=" (optional)"
        />
        <UsCitizenFieldWrapper>
          <Field name="usCitizen" component={StyledFormCheckbox} label="I am not a US person" />
          <Tooltip title="Someone who permanently lives in the United States of America. Under some circumstances US citizens who live abroad can also be considered a US person.">
            <InfoIcon />
          </Tooltip>
        </UsCitizenFieldWrapper>

        <PepFieldWrapper>
          <Field name="pep" component={StyledFormCheckbox} label="I am not a PEP" />
          <Tooltip title="A PEP (Politically Exposed Person) is someone with a prominent political role or a close family member of this person.">
            <InfoIcon />
          </Tooltip>
        </PepFieldWrapper>

        <Field
          name="understand"
          component={StyledFormCheckbox}
          label="I understand that cryptocurrencies are volatile products & I could lose (part of) my investment"
        />

        <Field
          name="agree"
          component={StyledFormCheckbox}
          label={formatMessage(
            { id: IntlKeys.registrationPageRegisterStepFormTermsAndConditions },
            {
              firstLink: <Link href="#">Terms & conditions</Link>,
              secondLink: <Link href="#">Privacy Policy</Link>,
            },
          )}
        />
      </InnerContainer>
    </Form>
  );
};

export default RegisterForm;
