import React, { useCallback, useMemo } from 'react';
// libs
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
// material-ui
import { Collapse } from '@mui/material';
// store
import { uiLocaleSelector } from 'store/ui';
// constants
import { COINMERCE_BASE_URL } from 'constants/general';
import { IntlKeys } from 'localization/keys';
// icons
import { ReactComponent as BTCIcon } from 'assets/icons/cryptos/btc.svg';
import { ReactComponent as ADAIcon } from 'assets/icons/cryptos/ada.svg';
import { ReactComponent as ETHIcon } from 'assets/icons/cryptos/eth.svg';
import { ReactComponent as XRPIcon } from 'assets/icons/cryptos/xrp.svg';
import { ReactComponent as SOLIcon } from 'assets/icons/cryptos/sol.svg';
import { ReactComponent as VETIcon } from 'assets/icons/cryptos/vet.svg';
import { ReactComponent as DOTIcon } from 'assets/icons/cryptos/dot.svg';
import { ReactComponent as VRAIcon } from 'assets/icons/cryptos/vra.svg';
import { ReactComponent as MATICIcon } from 'assets/icons/cryptos/matic.svg';
// styled
import { Wrapper, LeftColumn, RightColumn, Divider, Container, Link } from './styled';
import { ListItem, ListItemDescription, ListItemTitle, ListItemLink, ListItemIconWrapper } from '../styled';

export interface CoinsPopoverProps {
  isOpened: boolean;
}

const COINS_LIST = [
  { id: '1', title: 'Bitcoin', description: 'BTC', icon: BTCIcon, link: '#' },
  { id: '2', title: 'Cardano', description: 'ADA', icon: ADAIcon, link: '#' },
  { id: '3', title: 'Ethereum', description: 'ETH', icon: ETHIcon, link: '#' },
  { id: '4', title: 'Ripple', description: 'XRP', icon: XRPIcon, link: '#' },
  { id: '5', title: 'Solana', description: 'SOL', icon: SOLIcon, link: '#' },
  { id: '6', title: 'VeChain', description: 'VET', icon: VETIcon, link: '#' },
  { id: '7', title: 'Polkadot', description: 'DOT', icon: DOTIcon, link: '#' },
  { id: '8', title: 'Veracity', description: 'VRA', icon: VRAIcon, link: '#' },
  { id: '9', title: 'Poligon', description: 'MATIC', icon: MATICIcon, link: '#' },
];

const CoinsPopover: React.FC<CoinsPopoverProps> = ({ isOpened }) => {
  const { formatMessage } = useIntl();

  const locale = useSelector(uiLocaleSelector);

  const getCoinMerceLink = useCallback((subLink: string) => `${COINMERCE_BASE_URL}${locale}/${subLink}`, [locale]);

  const renderList = useCallback(
    (list: typeof COINS_LIST) => {
      return list.map(({ id, title, description, icon, link }) => {
        const Icon = icon;
        return (
          <ListItem key={id}>
            <ListItemLink href={getCoinMerceLink(link)} tabIndex={1}>
              <ListItemIconWrapper>
                <Icon />
              </ListItemIconWrapper>
              <ListItemTitle>
                {title}
                <ListItemDescription>{description}</ListItemDescription>
              </ListItemTitle>
            </ListItemLink>
          </ListItem>
        );
      });
    },
    [getCoinMerceLink],
  );

  const leftColumnList = useMemo(() => renderList(COINS_LIST.slice(0, 5)), [renderList]);

  const rightColumnList = useMemo(() => renderList(COINS_LIST.slice(5)), [renderList]);

  return (
    <Collapse in={isOpened} timeout={{ enter: 250, exit: 150 }} unmountOnExit>
      <Wrapper>
        <Container>
          <LeftColumn>{leftColumnList}</LeftColumn>
          <Divider />
          <RightColumn>
            {rightColumnList}
            <Link href="components/layouts/headers/UnauthHeader/popovers/CoinsPopover/index#">
              {formatMessage({ id: IntlKeys.headerCoinsPopoverMenuLink })}
            </Link>
          </RightColumn>
        </Container>
      </Wrapper>
    </Collapse>
  );
};

export default CoinsPopover;
