import React from 'react';
// components
import Modal from 'components/Modal';
// icons
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
// styled
import { ModalCloseButtonWrapper, ModalCoinsContainer, ModalContainer, ModalHeader, ModalTitle } from './styled';

export interface MoreCoinsModalProps {
  isOpen: boolean;
  handleClose: () => void;
  children: React.ReactNode;
}

function MoreCoinsModal({ isOpen, handleClose, children }: MoreCoinsModalProps) {
  return (
    <Modal open={isOpen} onClose={handleClose} fullScreenBreakpoint="md">
      <ModalContainer>
        <ModalHeader>
          <ModalTitle>All coins in this bundle</ModalTitle>
          <ModalCloseButtonWrapper onClick={handleClose}>
            <CrossIcon />
          </ModalCloseButtonWrapper>
        </ModalHeader>
        <ModalCoinsContainer>{children}</ModalCoinsContainer>
      </ModalContainer>
    </Modal>
  );
}

export default MoreCoinsModal;
