import React from 'react';
// icons
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
// styled
import { Amount, ButtonsContainer, Container, DepositButton, RecordButton, Title, Wrapper } from './styled';
import { Tooltip } from '@mui/material';

export interface YourCoinmerceCoinsCardProps {
  amount?: number | string;
  tooltipText?: string;
}

const YourCoinmerceCoinsCard: React.FC<YourCoinmerceCoinsCardProps> = ({
  amount = '0,00',
  tooltipText = 'Lorem ipsum',
}) => {
  return (
    <Wrapper>
      <Container>
        <Title>
          Jouw Coinmerce Coins
          <Tooltip title={tooltipText}>
            <InfoIcon />
          </Tooltip>
        </Title>
        <Amount>€{amount}</Amount>
        <ButtonsContainer>
          <DepositButton>Storten</DepositButton>
          <RecordButton color="secondary">Opnemen</RecordButton>
        </ButtonsContainer>
      </Container>
    </Wrapper>
  );
};

export default YourCoinmerceCoinsCard;
