import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  max-width: 1280px;
  padding: 120px 16px 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.media.lg`
    max-width: 100%;
  `}

  ${({ theme }) => theme.media.md`
    padding: 16px;
    padding-top: 80px;
    flex-direction: column;
  `}
`;

export const AboutSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 440px;

  ${({ theme }) => theme.media.lg`
    width: 400px;
  `}

  ${({ theme }) => theme.media.md`
    width: 100%;
    align-items: center;
  `}
`;

export const VideoSection = styled.div`
  width: 550px;
  height: 345px;
  border-radius: 10px;
  margin-left: 90px;
  overflow: hidden;
  z-index: 1;

  ${({ theme }) => theme.media.lg`
    margin: 0;
    margin-top: 18px;
    margin-left: 40px;
    width: 528px;
  `}

  ${({ theme }) => theme.media.md`
    margin: 0;
    margin-top: 18px;
  `}

  ${({ theme }) => theme.media.xs`
    width: 100%;
    height: 240px;
  `}

  ${({ theme }) => theme.media.xxs`
    width: 100%;
    height: 200px;
  `}
`;

export const Title = styled.div`
  font-size: 48px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  line-height: 54px;
  color: ${({ theme }) => theme.palette.common.brescianBlue};

  ${({ theme }) => theme.media.md`
    font-size: 32px;
    line-height: 36px;
  `}
`;

export const Description = styled.div`
  font-size: 18px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  line-height: 36px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  margin-top: 44px;

  ${({ theme }) => theme.media.md`
    text-align: center;
    font-size: 16px;
    margin-top: 18px;
  `}
`;
