import styled from '@emotion/styled/macro';
import { Button } from '@mui/material';
import { css } from '@emotion/react';

export const Wrapper = styled.div`
  width: 100%;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 2px;

  ${({ theme }) => theme.media.sm`
    
  `};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.div`
  font-size: 24px;
  line-height: 29px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.8px;
  color: ${({ theme }) => theme.palette.common.ultimateGrey};
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  margin: 16px 0 12px;
`;

export const Image = styled.img`
  width: 100%;
  //height: 352px;
  margin-top: 32px;
`;

const buttonStyles = css`
  height: 51px;
  font-size: 16px;
  line-height: 19px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
`;

export const EuroButton = styled(Button)`
  ${buttonStyles};
  margin-top: 72px;
`;

export const CryptoButton = styled(Button)`
  ${buttonStyles};
  margin-top: 16px;

  background-color: ${({ theme }) => theme.palette.common.blackSpace};

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.nightOut};
  }
`;
