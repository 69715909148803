import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled/macro';

export const Wrapper = styled.div``;

/* eslint-disable */
const TrustBoxWidget = () => {
  const ref = useRef(null);

  useEffect(() => {
    if (window?.Trustpilot) {
      window?.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <Wrapper
      ref={ref}
      className="trustpilot-widget"
      data-locale="en-US"
      data-template-id="5406e65db0d04a09e042d5fc"
      data-businessunit-id="5b30c785d5597d0001ad610c"
      data-style-height="28px"
      data-style-width="100%"
      data-theme="light"
      data-font-family="visbyroundcf-medium"
      data-text-color="#535353"
    >
      <a href="https://www.trustpilot.com/review/example.com" target="_blank" rel="noopener">
        {' '}
        Trustpilot
      </a>
    </Wrapper>
  );
};
export default TrustBoxWidget;
