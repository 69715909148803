import React, { useCallback, useMemo, useEffect } from 'react';
// libs
import * as Yup from 'yup';
import { Field, FormikProvider, useFormik } from 'formik';
// material-ui
import { InputAdornment } from '@mui/material';
// helpers
import { getFlag, getPhoneCodeByCountryCode } from 'helpers/countriesHelpers';
import { getFormattedPostCode } from 'helpers/postCodeHelpers';
// constants
import { COUNTRIES } from 'constants/countries';
import { MONTH, POSTAL_CODE_LIST } from 'constants/general';
// icons
import { ReactComponent as ArrowForwardIcon } from 'assets/icons/arrow-forward.svg';
// styled
import {
  BirthdayInputsContainer,
  BirthdayInputsWrapper,
  Block,
  BlockTitle,
  BlockWrapper,
  Container,
  FieldWrapper,
  FlagWrapper,
  GetStartedButton,
  Label,
  MonthSelector,
  PhoneCode,
  RadioGroup,
  StyledFormRadio,
  StyledFormSelect,
  StyledFormTextField,
  StyledSelectPhoneCode,
  TwoFieldsContainer,
  Wrapper,
} from './styled';

const validationSchema = Yup.object().shape({
  birthDay: Yup.string().required('Required'),
  birthYear: Yup.string().required('Required'),
});

const monthsDaysCount = [
  { monthNumber: MONTH.January, daysCount: 31 },
  { monthNumber: MONTH.February, daysCount: 28 },
  { monthNumber: MONTH.March, daysCount: 31 },
  { monthNumber: MONTH.April, daysCount: 30 },
  { monthNumber: MONTH.May, daysCount: 31 },
  { monthNumber: MONTH.June, daysCount: 30 },
  { monthNumber: MONTH.July, daysCount: 31 },
  { monthNumber: MONTH.August, daysCount: 31 },
  { monthNumber: MONTH.September, daysCount: 30 },
  { monthNumber: MONTH.October, daysCount: 31 },
  { monthNumber: MONTH.November, daysCount: 30 },
  { monthNumber: MONTH.December, daysCount: 31 },
];

// TODO: refactor: split into smaller components
const AccountDetailsStep: React.FC = () => {
  const formik = useFormik({
    validationSchema,
    initialValues: {
      birthDay: '',
      birthMonth: 1,
      birthYear: '',
      nationality: 'NL',
      country: 'NL',
      postalCode: '',
      house: '',
      address: '',
      city: '',
      phoneNumber: '',
      phoneCodeCountry: 'NL',
      usage: '1',
      sourceOfFunds: '1',
    },
    onSubmit: () => {},
  });

  const handlePhoneCodeItemClick = useCallback(
    (value: string) => formik.setFieldValue('phoneCodeCountry', value || ''),
    [formik],
  );

  const monthOptions = useMemo(
    () => [
      { value: MONTH.January, label: 'January' },
      { value: MONTH.February, label: 'February' },
      { value: MONTH.March, label: 'March' },
      { value: MONTH.April, label: 'April' },
      { value: MONTH.May, label: 'May' },
      { value: MONTH.June, label: 'June' },
      { value: MONTH.July, label: 'July' },
      { value: MONTH.August, label: 'August' },
      { value: MONTH.September, label: 'September' },
      { value: MONTH.October, label: 'October' },
      { value: MONTH.November, label: 'November' },
      { value: MONTH.December, label: 'December' },
    ],
    [],
  );

  const countriesOptions = useMemo(
    () => COUNTRIES.map(({ name, code, flag }) => ({ value: code, label: name, fontIcon: flag })),
    [],
  );

  const phoneCodesOptions = useMemo(
    () =>
      COUNTRIES.map(({ dialCode, code, flag, name }) => ({
        value: code,
        label: `${name} (${dialCode})`,
        fontIcon: flag,
      })),
    [],
  );

  const handleNumberInputChange = useCallback(
    ({ name, min, max, value }: { name: string; min: number; max: number; value: string }) => {
      let newValue = parseInt(value, 10);

      if (newValue < min) newValue = min;
      if (newValue > max) newValue = max;

      formik.setFieldValue(name, newValue);
    },
    [formik],
  );

  const handleBirthDayChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let maxNumber = monthsDaysCount.find((item) => item.monthNumber === formik.values.birthMonth)?.daysCount || 31;

      const isFebruary = formik.values.birthMonth === MONTH.February;
      const isLeapYear = formik.values.birthYear && Number(formik.values.birthYear) % 4 === 0;

      if (isFebruary && isLeapYear) {
        maxNumber = 29;
      }

      handleNumberInputChange({
        name: event.target.name,
        value: event.target.value,
        min: 0,
        max: maxNumber,
      });
    },
    [handleNumberInputChange],
  );

  const handleBirthYearChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      handleNumberInputChange({ name: event.target.name, value: event.target.value, min: 0, max: 2022 }),
    [handleNumberInputChange],
  );

  const handlePostalCodeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      formik.setFieldValue(
        event.target.name,
        getFormattedPostCode(event.target.value, formik.values.country).trimEnd(),
      );
    },
    [formik],
  );

  const handlePhoneNumberChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const maxLength = 9;
      if (event.target.value.toString().length > maxLength) {
        return formik.values.phoneNumber;
      }
      return formik.setFieldValue(event.target.name, event.target.value);
    },
    [formik],
  );

  const validateForm = useCallback(() => formik.validateForm().then((errors) => errors), [formik]);

  useEffect(() => {
    formik.setFieldValue('phoneCodeCountry', formik.values.nationality);
    formik.setFieldValue('country', formik.values.nationality);
  }, [formik.values.nationality]);

  useEffect(() => {
    validateForm().then((errors) => formik.setErrors(errors));
  }, []);

  return (
    <Wrapper>
      <FormikProvider value={formik}>
        <Container>
          <BlockWrapper>
            <BlockTitle>Personal details</BlockTitle>
            <Block>
              <BirthdayInputsWrapper>
                <Label>Your Birthday</Label>
                <BirthdayInputsContainer>
                  <Field
                    component={StyledFormTextField}
                    name="birthDay"
                    placeholder="DD"
                    type="number"
                    value={formik.values.birthDay}
                    onChange={handleBirthDayChange}
                  />
                  <Field
                    component={MonthSelector}
                    name="birthMonth"
                    value={formik.values.birthMonth}
                    options={monthOptions}
                  />
                  <Field
                    component={StyledFormTextField}
                    name="birthYear"
                    placeholder="YYYY"
                    type="number"
                    onChange={handleBirthYearChange}
                  />
                </BirthdayInputsContainer>
              </BirthdayInputsWrapper>
              <FieldWrapper>
                <Field
                  component={StyledFormSelect}
                  name="nationality"
                  label="Nationality"
                  value={formik.values.nationality}
                  options={countriesOptions}
                  startAdornment={
                    <InputAdornment position="start">
                      <FlagWrapper>{getFlag(formik.values.nationality)}</FlagWrapper>
                    </InputAdornment>
                  }
                />
              </FieldWrapper>
              <FieldWrapper>
                <Field
                  component={StyledFormTextField}
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  label="Phone number"
                  type="number"
                  onChange={handlePhoneNumberChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <StyledSelectPhoneCode
                          name="phoneCodeCountry"
                          options={phoneCodesOptions}
                          value={formik.values.phoneCodeCountry}
                          onMenuItemClick={handlePhoneCodeItemClick}
                        />
                        <PhoneCode>{getPhoneCodeByCountryCode(formik.values.phoneCodeCountry)}</PhoneCode>
                      </InputAdornment>
                    ),
                  }}
                />
              </FieldWrapper>
            </Block>
          </BlockWrapper>
          <BlockWrapper>
            <BlockTitle>Residence</BlockTitle>
            <Block>
              <Field
                component={StyledFormSelect}
                name="country"
                label="Where do you live?"
                value={formik.values.country}
                options={countriesOptions}
                startAdornment={
                  <InputAdornment position="start">
                    <FlagWrapper>{getFlag(formik.values.country)}</FlagWrapper>
                  </InputAdornment>
                }
              />
              <TwoFieldsContainer>
                <Field
                  component={StyledFormTextField}
                  name="postalCode"
                  label="Postal code / ZIP"
                  value={formik.values.postalCode}
                  placeholder={POSTAL_CODE_LIST?.[formik.values.country]?.format}
                  onChange={handlePostalCodeChange}
                />
                <Field
                  component={StyledFormTextField}
                  name="house"
                  label="House / apt."
                  value={formik.values.house}
                  placeholder="118 B"
                />
              </TwoFieldsContainer>
              <FieldWrapper>
                <Field
                  component={StyledFormTextField}
                  name="address"
                  label="Address"
                  value={formik.values.address}
                  placeholder="Keizersgracht"
                />
              </FieldWrapper>
              <FieldWrapper>
                <Field
                  component={StyledFormTextField}
                  name="city"
                  label="City"
                  value={formik.values.city}
                  placeholder="Amsterdam"
                />
              </FieldWrapper>
            </Block>
          </BlockWrapper>
          <BlockWrapper>
            <BlockTitle>Usage</BlockTitle>
            <Block>
              <RadioGroup>
                <Label>I use Coinmerce To</Label>
                <Field component={StyledFormRadio} type="radio" name="usage" label="Trade on Coinmerce" value="1" />
                <Field
                  component={StyledFormRadio}
                  type="radio"
                  name="usage"
                  label="Trade on another exchange"
                  value="2"
                />
                <Field
                  component={StyledFormRadio}
                  type="radio"
                  name="usage"
                  label="Invest(store funds on Coinmerce)"
                  value="3"
                />
                <Field
                  component={StyledFormRadio}
                  type="radio"
                  name="usage"
                  label="Invest (store funds outside of Coinmerce)"
                  value="4"
                />
                <Field component={StyledFormRadio} type="radio" name="usage" label="Pay online" value="5" />
              </RadioGroup>
              <RadioGroup>
                <Label>My source of funds is</Label>
                <Field component={StyledFormRadio} type="radio" name="sourceOfFunds" label="Work" value="1" />
                <Field component={StyledFormRadio} type="radio" name="sourceOfFunds" label="Investments" value="2" />
                <Field component={StyledFormRadio} type="radio" name="sourceOfFunds" label="Heritage" value="3" />
                <Field component={StyledFormRadio} type="radio" name="sourceOfFunds" label="Business owner" value="4" />
              </RadioGroup>
              <GetStartedButton endIcon={<ArrowForwardIcon />} disabled={!!Object.keys(formik.errors).length}>
                Get started
              </GetStartedButton>
            </Block>
          </BlockWrapper>
        </Container>
      </FormikProvider>
    </Wrapper>
  );
};

export default React.memo(AccountDetailsStep);
