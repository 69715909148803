import React, { useCallback, useState } from 'react';
// libs
import { useIntl } from 'react-intl';
// store
import { useSelector } from 'react-redux';
import { uiLocaleSelector } from 'store/ui';
// material-ui
import { Collapse } from '@mui/material';
// icons
import { ExpandLess, ExpandMore } from '@mui/icons-material';
// constants
import { COINMERCE_BASE_URL } from 'constants/general';
import { ListItemsType } from 'components/layouts/constants';
// styled
import {
  NavButton,
  CollapseNavLink,
  CollapseNavLinkText,
  CollapseNavLinkDescription,
  CollapseRouterLink,
} from './styled';

type CollapseNavProps = {
  title: string;
  items: ListItemsType;
  onClick?: () => void;
  isRouterLink?: boolean;
};

const CollapseNav: React.FC<CollapseNavProps> = ({ title, items, isRouterLink, onClick }) => {
  const { formatMessage } = useIntl();
  const locale = useSelector(uiLocaleSelector);

  const [isOpen, setIsOpen] = useState(true);

  const toggleCollapse = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  return (
    <React.Fragment>
      <NavButton onClick={toggleCollapse}>
        {formatMessage({ id: title })} {isOpen ? <ExpandLess /> : <ExpandMore />}
      </NavButton>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {/* TODO: need refactor */}
        {items.map(({ icon: Icon, title, description, link }, index) =>
          isRouterLink ? (
            <CollapseRouterLink key={index} to={link} onClick={onClick ? onClick : () => {}}>
              <Icon />

              <CollapseNavLinkText>
                {formatMessage({ id: title })}

                <CollapseNavLinkDescription>{formatMessage({ id: description })}</CollapseNavLinkDescription>
              </CollapseNavLinkText>
            </CollapseRouterLink>
          ) : (
            <CollapseNavLink key={index} href={`${COINMERCE_BASE_URL}${locale}/${link}`}>
              <Icon />

              <CollapseNavLinkText>
                {formatMessage({ id: title })}

                <CollapseNavLinkDescription>{formatMessage({ id: description })}</CollapseNavLinkDescription>
              </CollapseNavLinkText>
            </CollapseNavLink>
          ),
        )}
      </Collapse>
    </React.Fragment>
  );
};

export default CollapseNav;
