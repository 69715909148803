import styled from '@emotion/styled/macro';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-forward-alt.svg';

export const Wrapper = styled.div`
  width: 100%;
  height: 525px;
  margin-top: 200px;
  background-color: ${({ theme }) => theme.palette.common.drWhite};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.media.md`
    height: 100%;
    padding: 32px;
  `}
`;

export const Container = styled.div`
  width: 814px;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.media.md`
    width: 100%;
    flex-direction: column;
  `}
`;

export const InfoSection = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.md`
    width: 100%;
    align-items: center;
  `}
`;

export const Title = styled.div`
  font-size: 48px;
  line-height: 72px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.brescianBlue};

  ${({ theme }) => theme.media.md`
    font-size: 32px;
    line-height: 56px;
  `}
`;

export const Description = styled.div`
  font-size: 18px;
  line-height: 36px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.blackSpace};

  ${({ theme }) => theme.media.md`
    font-size: 16px;
    line-height: 28px;
    text-align: center;
  `}
`;

export const SocialsSection = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.md`
    align-items: center;
  `}
`;

export const SocialItem = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.common.tomato} !important;
  margin-top: 42px;
  &:first-of-type {
    margin-top: 24px;
  }

  ${({ theme }) => theme.media.md`
    margin-top: 12px;
  `}
`;

export const SocialIcon = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 24px;

  ${({ theme }) => theme.media.md`
    width: 28px;
    height: 28px;
  `}
`;

export const SocialText = styled.div`
  font-size: 28px;
  line-height: 36px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
  margin-right: 24px;

  ${({ theme }) => theme.media.md`
    font-size: 18px;
    line-height: 28px;
  `}
`;

export const StyledArrowIcon = styled(ArrowIcon)`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
  margin-top: 4px;

  ${({ theme }) => theme.media.md`
    width: 12px;
    height: 12px;
  `}
`;
