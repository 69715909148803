import React, { useCallback, useContext, useState } from 'react';

export enum DashboardState {
  Verified = 'Verified',
  Unverified = 'Unverified',
  InUse = 'InUse',
}

export interface IDashboardContext {
  dashboardState: DashboardState;
  setDashboardState: (state: DashboardState) => void;
}

export interface DashboardProviderProps {
  children: React.ReactNode;
}

const DashboardContext = React.createContext<IDashboardContext>(null as unknown as IDashboardContext);

export const useDashboardContext = () => useContext(DashboardContext);

// TODO: remove this context after backend will be provided
const DashboardProvider: React.FC<DashboardProviderProps> = ({ children }) => {
  const [dashboardState, setDashboardState] = useState(DashboardState.Unverified);

  const handleDashboardStateChange = useCallback((state: DashboardState) => setDashboardState(state), []);

  return (
    <DashboardContext.Provider
      value={{
        dashboardState,
        setDashboardState: handleDashboardStateChange,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
