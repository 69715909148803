import React, { useCallback, useMemo, useState } from 'react';
// icons
import btcIcon from 'assets/icons/cryptos/btc.svg';
import solIcon from 'assets/icons/cryptos/sol.svg';
import { Container, HelperText, InputsContainer, StyledTextField, SwapButton, Title, Wrapper } from './styled';
import CoinDropdown from './CoinDropdown';

const SwapForm: React.FC = () => {
  const [fromInput, setFromInput] = useState(0.1939);
  const [toInput, setToInput] = useState(35093.28);

  const fromDropdownItems = useMemo(
    () => [
      { icon: btcIcon, title: 'BTC', value: 'btc' },
      { icon: solIcon, title: 'SOL', value: 'sol' },
    ],
    [],
  );

  const toDropdownItems = useMemo(
    () => [
      { icon: solIcon, title: 'SOL', value: 'sol' },
      { icon: btcIcon, title: 'BTC', value: 'btc' },
    ],
    [],
  );

  const [fromSelected, setFromSelected] = useState(fromDropdownItems[0].value);
  const [toSelected, setToSelected] = useState(toDropdownItems[0].value);

  const handleFromChange = useCallback((value: string) => setFromSelected(value), []);

  const handleToChange = useCallback((value: string) => setToSelected(value), []);

  const handleFromInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const numericValue = Number(event.target.value);
    setFromInput(numericValue);
  }, []);

  const handleToInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const numericValue = Number(event.target.value);
    setToInput(numericValue);
  }, []);

  return (
    <Wrapper>
      <Container>
        <Title>Direct swappen</Title>
        <InputsContainer>
          <CoinDropdown items={fromDropdownItems} selected={fromSelected} onChange={handleFromChange} label="Van" />
          <StyledTextField
            value={fromInput}
            autoComplete="off"
            size="small"
            type="number"
            onChange={handleFromInputChange}
          />
          <HelperText>Max. 0,92834 {fromDropdownItems.find(({ value }) => value === fromSelected)?.title}</HelperText>
        </InputsContainer>
        <InputsContainer>
          <CoinDropdown items={toDropdownItems} selected={toSelected} onChange={handleToChange} label="Naar" />
          <StyledTextField
            value={toInput}
            autoComplete="off"
            size="small"
            type="number"
            onChange={handleToInputChange}
          />
          <HelperText>= €353,28</HelperText>
        </InputsContainer>
        <SwapButton>Swap</SwapButton>
      </Container>
    </Wrapper>
  );
};

export default SwapForm;
