import styled from '@emotion/styled/macro';
import { Button, Skeleton } from '@mui/material';

export const Wrapper = styled.div`
  width: calc(50% - 8px);
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  padding: 24px;
  background-color: ${({ theme }) => theme.palette.common.white};
  transition: filter 0.3s ease;
  position: relative;
  z-index: 10;

  &:hover {
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.1));
    cursor: pointer;
  }

  ${({ theme }) => theme.media.md`
    width: 100%;
  `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  color: ${({ theme }) => theme.palette.common.brescianBlue};

  & > svg:first-of-type {
    width: 20px;
    height: 20px;
  }
`;

export const Title = styled.div`
  font-size: 21px;
  line-height: 25.5px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  letter-spacing: 0.8px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;

export const Amount = styled.div`
  font-size: 21px;
  line-height: 25px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  letter-spacing: 0.8px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
`;

export const ActionButton = styled(Button)`
  width: fit-content;
  padding: 8px 32px;
`;

export const CardSkeletonWrapper = styled.div`
  width: calc(50% - 8px);
  position: relative;

  ${({ theme }) => theme.media.md`
    width: 100%;
  `}
`;

export const CardSkeleton = styled(Skeleton)`
  width: 100%;
  height: 75.5px;
`;

export const CardTitleSkeleton = styled(Skeleton)`
  width: 130px;
  height: 24px;
  position: absolute;

  top: 26px;
  left: 24px;
`;

export const CardPriceSkeleton = styled(Skeleton)`
  width: 100px;
  height: 24px;
  position: absolute;

  top: 26px;
  right: 24px;
`;
