import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  width: 468px;
  margin-top: 0;
  margin-bottom: 0;
  background-color: ${({ theme }) => theme.palette.common.infinity};
  padding: 10px 20px;
  border-radius: 6px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Divider = styled.div`
  width: 1px;
  margin: 17px 0;
  background-color: ${({ theme }) => theme.palette.common.deadForest};
`;

export const LeftColumn = styled.div`
  width: 190px;
`;

export const RightColumn = styled.div`
  width: 275px;
  padding-left: 36px;
`;

export const Link = styled.a`
  display: inline-block;
  font-size: 15px;
  line-height: 18px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.palette.common.white};
  margin: 16px 0 0 -12px;
`;
