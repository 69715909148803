// constants
import { COINMERCE_BASE_URL } from 'constants/general';
// other
import { IntlKeys } from 'localization/keys';

export type FooterListItem = {
  title: IntlKeys;
  link: string;
};

export type FooterList = FooterListItem[][];

export const FOOTER_LISTS = (locale: string) =>
  [
    [
      [
        { title: IntlKeys.footerListTradeTitle, link: '' },
        { title: IntlKeys.footerListTradeItem1, link: '' },
        { title: IntlKeys.footerListTradeItem2, link: '' },
        { title: IntlKeys.footerListTradeItem3, link: '' },
        { title: IntlKeys.footerListTradeItem4, link: '' },
      ],
    ],
    [
      [
        { title: IntlKeys.footerListPopularTitle, link: '' },
        { title: IntlKeys.footerListPopularItem1, link: '' },
        { title: IntlKeys.footerListPopularItem2, link: '' },
        { title: IntlKeys.footerListPopularItem3, link: '' },
        { title: IntlKeys.footerListPopularItem4, link: '' },
        { title: IntlKeys.footerListPopularItem5, link: '' },
        { title: IntlKeys.footerListPopularItem6, link: '' },
        { title: IntlKeys.footerListPopularItem7, link: '' },
        { title: IntlKeys.footerListPopularItem8, link: '' },
        { title: IntlKeys.footerListPopularItem9, link: '' },
        { title: IntlKeys.footerListPopularItem10, link: '' },
      ],
    ],
    [
      [
        { title: IntlKeys.footerListLearnTitle, link: '' },
        { title: IntlKeys.footerListLearnItem1, link: '' },
        { title: IntlKeys.footerListLearnItem2, link: '' },
        { title: IntlKeys.footerListLearnItem3, link: '' },
        { title: IntlKeys.footerListLearnItem4, link: '' },
        { title: IntlKeys.footerListLearnItem5, link: '' },
      ],
      [
        { title: IntlKeys.footerListLegalTitle, link: '' },
        { title: IntlKeys.footerListLegalItem1, link: '' },
        { title: IntlKeys.footerListLegalItem2, link: '' },
        { title: IntlKeys.footerListLegalItem3, link: '' },
      ],
    ],
    [
      [
        { title: IntlKeys.footerListGeneralTitle, link: '' },
        { title: IntlKeys.footerListGeneralItem1, link: '' },
        { title: IntlKeys.footerListGeneralItem2, link: '' },
        { title: IntlKeys.footerListGeneralItem3, link: '' },
        { title: IntlKeys.footerListGeneralItem4, link: '' },
        { title: IntlKeys.footerListGeneralItem5, link: '' },
      ],
    ],
  ] as FooterList[];
