import React from 'react';

import { CheckboxProps } from '@mui/material';

import { Icon, IconChecked, Label, StyledCheckbox } from './styled';

const CustomCheckbox: React.FC<CheckboxProps & { label?: string }> = (props) => {
  return (
    <Label>
      <StyledCheckbox
        sx={{
          '&:hover': { bgcolor: 'transparent' },
        }}
        disableRipple
        color="default"
        checkedIcon={<IconChecked />}
        icon={<Icon />}
        inputProps={{ 'aria-label': 'Checkbox demo' }}
        {...props}
      />
      {props?.label}
    </Label>
  );
};

export default CustomCheckbox;
