import React from 'react';
// libs
import { useIntl } from 'react-intl';
// components
import AutomaticTradingSection from './AutomaticTradingSection';
import HowItWorksSection from './HowItWorksSection';
import DescriptionSection from './DescriptionSection';
import FutureSection from './FutureSection';
import QuestionsSection from './QuestionsSection';
// constants
import { IntlKeys } from 'localization/keys';
// icons
import { ReactComponent as ArrowForward } from 'assets/icons/arrow-forward-alt.svg';
// styled
import { Container, LinkButton, Wrapper } from './styled';

const BotsPage: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Container>
        <AutomaticTradingSection />
        <HowItWorksSection />
        <DescriptionSection />
        <FutureSection />
      </Container>
      <QuestionsSection />
      <LinkButton href="#">
        {formatMessage({ id: IntlKeys.botsPageFutureSectionTableLink })}
        <ArrowForward />
      </LinkButton>
    </Wrapper>
  );
};

export default BotsPage;
