import React from 'react';
// components
import RegisterStepper from './RegisterStepper';
// libs
import { useMediaQuery } from 'react-responsive';
// constants
import { breakpoints } from 'themes/mediaQueries';
// icons
import { ReactComponent as LogoIcon } from 'assets/icons/logo-alt.svg';
import { ReactComponent as LogoSimplifiedIcon } from 'assets/icons/logo-simplified.svg';
// styled
import { ButtonsContainer, HelpButton, LogoLink, Navigation, StepperContainer } from './styled';

const RegisterHeader: React.FC = () => {
  const isSm = useMediaQuery({ maxWidth: breakpoints.sm });

  return (
    <Navigation>
      <LogoLink href="">{isSm ? <LogoSimplifiedIcon /> : <LogoIcon />}</LogoLink>
      <StepperContainer>
        <RegisterStepper />
      </StepperContainer>
      <ButtonsContainer>
        <HelpButton>help</HelpButton>
      </ButtonsContainer>
    </Navigation>
  );
};

export default RegisterHeader;
