import React, { useMemo, useState } from 'react';
// components
import YourCoinmerceCoinsCard from '../components/YourCoinmerceCoinsCard';
import SwapForm from '../components/SwapForm';
// providers
import { DashboardState, useDashboardContext } from 'providers/DashboardProvider';
// images
import verifyId from 'assets/images/dashboard/verify-id.svg';
import verifyBank from 'assets/images/dashboard/verify-bank.svg';
import pendingId from 'assets/images/dashboard/pending-id.svg';
import pendingBank from 'assets/images/dashboard/pending-bank.svg';
import approvedId from 'assets/images/dashboard/approved-id.svg';
import approvedBank from 'assets/images/dashboard/approved-bank.svg';
// styled
import { Container, Wrapper } from './styled';
import VerificationCard from '../components/VerificationCard';
import VerifiedCard from '../components/VerifiedCard';

const LeftColumn: React.FC = () => {
  const { dashboardState, setDashboardState } = useDashboardContext();

  const [selectedCard, setSelectedCard] = useState(0);

  const handleCardButtonClick = () => setSelectedCard((prevState) => prevState + 1);

  const verificationCards = useMemo(
    () => [
      {
        title: 'Verifieer je account',
        description: 'Please complete your onboarding by verifiying your bankaccount and ID.',
        images: [verifyId, verifyBank],
        buttonTitle: 'Verifieer account',
        onButtonClick: handleCardButtonClick,
      },
      {
        title: 'Verifieer je bank',
        description: 'Please complete your bank verification while we are reviewing your ID.',
        images: [pendingId, verifyBank],
        buttonTitle: 'Go to bank verification',
        onButtonClick: handleCardButtonClick,
      },
      {
        title: 'Verifieer je ID',
        description: 'Please complete your ID verification while we are reviewing your bank verification.',
        images: [verifyId, pendingBank],
        buttonTitle: 'Go to ID verification',
        onButtonClick: handleCardButtonClick,
      },
      {
        title: 'Bijna klaar!',
        description:
          'Your ID has been verified. Please start your bankverification so we can fully verify your account.',
        images: [approvedId, verifyBank],
        buttonTitle: 'Go to bank verification',
        onButtonClick: handleCardButtonClick,
      },
      {
        title: 'Bijna klaar!',
        description:
          'Your bank verification has been approved. Please start your ID verification so we can fully verify your account.',
        images: [verifyId, approvedBank],
        buttonTitle: 'Go to ID verification',
        onButtonClick: handleCardButtonClick,
      },
      {
        title: 'Bezig met verifieren',
        description: 'Your bank - and ID verification are pending. We will let you know once your account is verified.',
        images: [pendingId, pendingBank],
        buttonTitle: 'See all coins',
        onButtonClick: handleCardButtonClick,
      },
      {
        title: 'Bezig met verifieren',
        description: 'Your ID verification has been approved, we are now reviewing your bank account.',
        images: [approvedId, pendingBank],
        buttonTitle: 'See all coins',
        onButtonClick: handleCardButtonClick,
      },
      {
        title: 'Bezig met verifieren',
        description: 'Your bank account has been approved, we are now reviewing your ID.',
        images: [pendingId, approvedBank],
        buttonTitle: 'See all coins',
        onButtonClick: () => setDashboardState(DashboardState.Verified),
      },
    ],
    [setDashboardState],
  );

  const selectedVerificationCard = verificationCards[selectedCard];

  return (
    <Wrapper>
      <Container>
        {dashboardState === DashboardState.Unverified && (
          <VerificationCard
            title={selectedVerificationCard.title}
            description={selectedVerificationCard.description}
            images={selectedVerificationCard.images}
            buttonTitle={selectedVerificationCard.buttonTitle}
            onButtonClick={selectedVerificationCard.onButtonClick}
          />
        )}

        {dashboardState === DashboardState.Verified && (
          <VerifiedCard
            onEuroClick={() => setDashboardState(DashboardState.InUse)}
            onCryptoClick={() => setDashboardState(DashboardState.InUse)}
          />
        )}

        {dashboardState === DashboardState.InUse && (
          <>
            <YourCoinmerceCoinsCard amount="216,92" />
            <SwapForm />
          </>
        )}
      </Container>
    </Wrapper>
  );
};

export default LeftColumn;
