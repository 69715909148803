import styled from '@emotion/styled/macro';

export const Wrapper = styled.div`
  width: 1006px;
  margin-top: 224px;

  ${({ theme }) => theme.media.lg`
    width: 100%;
    margin-top: 60px;
  `}
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.media.lg`
    padding: 16px;
  `}
`;

export const List = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.media.md`
    padding: 16px;
    flex-direction: column;
  `}
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 292px;

  ${({ theme }) => theme.media.md`
    max-width: 100%;
  `}
`;

export const ItemImageWrapper = styled.div`
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const ItemImage = styled.img``;

export const ItemTitle = styled.div`
  font-size: 18px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  line-height: 36px;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
  margin-top: 14px;

  ${({ theme }) => theme.media.md`
    font-size: 16px;
    line-height: 34px;
  `}
`;

export const ItemDescription = styled.div`
  font-size: 16px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  text-align: center;
  margin-top: 14px;

  ${({ theme }) => theme.media.md`
    font-size: 14px;
    line-height: 26px;
  `}
`;
