import styled from '@emotion/styled/macro';
import { Button } from '@mui/material';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 40px 80px 80px;
  margin-bottom: 60px;

  ${({ theme }) => theme.media.lg`
    padding: 8px 16px;
  `}
  ${({ theme }) => theme.media.sm`
    padding: 8px 16px;
  `}
  ${({ theme }) => theme.media.xs`
    padding: 8px 8px;
  `}
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 40px;

  ${({ theme }) => theme.media.md`
    flex-direction: column;
    gap: 16px;
  `}
`;

export const WalletsTableWrapper = styled.div`
  margin-top: 40px;
`;

export const ConvertButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

export const ConvertButton = styled(Button)`
  height: 43px;
  font-size: 16px;
  line-height: 19px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;

  background-color: ${({ theme }) => theme.palette.common.blackSpace};

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.nightOut};
  }
`;
