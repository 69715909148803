import React from 'react';
// libs
import { Outlet } from 'react-router-dom';
// components
import AuthHeader from '../headers/AuthHeader';
import UnauthHeader from '../headers/UnauthHeader';
import AuthFooter from '../footers/AuthFooter';
import UnauthFooter from '../footers/UnauthFooter';
// import Footer from './Footer';

// hooks
import useIsAuth from 'hooks/useIsAuth';
// styled
import { Wrapper } from './styled';

export interface MainLayoutProps {
  unauthHeaderBgColor?: string;
}

const MainLayout: React.FC<MainLayoutProps> = ({ unauthHeaderBgColor }) => {
  const isAuth = useIsAuth();

  return (
    <>
      {isAuth ? <AuthHeader /> : <UnauthHeader bgColor={unauthHeaderBgColor} />}

      <Wrapper>
        <Outlet />
      </Wrapper>

      {isAuth ? <AuthFooter /> : <UnauthFooter />}
    </>
  );
};

export default MainLayout;
