import React, { useCallback, useMemo } from 'react';
// libs
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
// material-ui
import { Collapse } from '@mui/material';
// store
import { uiLocaleSelector } from 'store/ui';
// constants
import { PASSIVE_INCOME_ITEMS, BOTS_ITEMS, LEARN_ITEMS, ABOUT_ITEMS_ALT } from 'components/layouts/constants';
import { COINMERCE_BASE_URL } from 'constants/general';
// styled
import { Wrapper, Link } from './styled';
import { ListItem, ListItemDescription, ListItemTitle, ListItemLink, ListItemIconWrapper } from '../styled';

export type ListType = 'about' | 'income' | 'bots' | 'learn';

export interface PopoverProps {
  isOpened: boolean;
  listType: ListType;
  width?: number;
  listLink?: {
    name: string;
    href: string;
  };
}

export const LISTS = {
  about: ABOUT_ITEMS_ALT,
  income: PASSIVE_INCOME_ITEMS,
  bots: BOTS_ITEMS,
  learn: LEARN_ITEMS,
};

const MenuPopover: React.FC<PopoverProps> = ({ isOpened, listType, width, listLink }) => {
  const { formatMessage } = useIntl();

  const locale = useSelector(uiLocaleSelector);

  const getCoinMerceLink = useCallback((subLink: string) => `${COINMERCE_BASE_URL}${locale}/${subLink}`, [locale]);

  const list = useMemo(() => {
    return LISTS[listType].map(({ title, description, icon, link }) => {
      const Icon = icon;
      return (
        <ListItem key={title}>
          <ListItemLink href={getCoinMerceLink(link)} tabIndex={1}>
            <ListItemIconWrapper>
              <Icon />
            </ListItemIconWrapper>
            <ListItemTitle>
              {formatMessage({ id: title })}
              <ListItemDescription>{formatMessage({ id: description })}</ListItemDescription>
            </ListItemTitle>
          </ListItemLink>
        </ListItem>
      );
    });
  }, [formatMessage, getCoinMerceLink, listType]);

  return (
    <Collapse in={isOpened} timeout={{ enter: 250, exit: 150 }} unmountOnExit>
      <Wrapper width={width}>
        {list}
        {listLink ? <Link href={listLink.href}>{listLink.name}</Link> : null}
      </Wrapper>
    </Collapse>
  );
};

export default MenuPopover;
