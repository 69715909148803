import React, { useEffect, useMemo, useState } from 'react';
// libs
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
// components
import PopularAssetsTable from '../components/PopularAssetsTable';
import ExchangeRateChart from '../components/ExchangeRateChart';
import PriceCard from '../components/PriceCard';
import AboutCoinCard from '../components/AboutCoinCard';
import StakingRewardForm from '../components/StakingRewardForm';
// store
import { uiLocaleSelector } from 'store/ui';
// constants
import { IntlKeys } from 'localization/keys';
// styled
import {
  ChartContainer,
  Wrapper,
  BuyBitcoinTitle,
  BuyBitcoinDescription,
  ChartTitle,
  CardsContainer,
  BitcoinEarnTitle,
  BitcoinEarnDescription,
  BitcoinEarnContainer,
  AboutCoinCardWrapper,
  StartStakingButton,
  OverBitcoinTitle,
  OverBitcoinDescription,
  TableTitle,
  TableDescription,
  TableDescriptionLink,
} from './styled';

function RightColumn() {
  const locale = useSelector(uiLocaleSelector);

  const { formatMessage } = useIntl();

  // simulated loading code start
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 5000);

    return () => clearTimeout(timer);
  }, []);
  // simulated loading code end

  const priceCards = useMemo(
    () => [
      {
        id: '1',
        price: 0.0051,
        description: formatMessage({ id: IntlKeys.websiteSinglecoinH2PriceCoin }, { coin: 'VRA' }),
        digitName: '',
        isLoading,
      },
      {
        id: '2',
        price: 52.42,
        description: formatMessage({ id: IntlKeys.websiteSinglecoinH2PriceVolume }, { coin: 'Verasity' }),
        digitName: 'M',
        isLoading,
      },
      {
        id: '3',
        price: 27.9,
        description: formatMessage({ id: IntlKeys.websiteSinglecoinH2PriceMarketcap }),
        digitName: 'M',
        isLoading,
      },
    ],
    [formatMessage, isLoading],
  );

  return (
    <Wrapper>
      <BuyBitcoinTitle>{formatMessage({ id: IntlKeys.websiteSinglecoinH1 }, { coin: 'VRA' })}</BuyBitcoinTitle>
      <BuyBitcoinDescription>
        Hoe Bitcoin kopen met iDeal? Bij Coinmerce kun je Bitcoin kopen met iDeal. Zo kun je Bitcoin kopen als je bij
        Rabobank, ING, ABN AMRO of een andere Nederlandse bank bent aangesloten. Als je eenmaal ingelogd bent, zie je in
        het bovenste menu "Coins" staan, waar je op klikt. Nu kom je op een pagina met alle cryptomunten die Coinmerce
        aanbiedt. Als je Bitcoin niet ziet staan, gebruik je de zoekfunctie om naar Bitcoin te zoeken, waarna je op deze
        cryptomunt klikt. In het rechtervenster kun je aangeven voor hoeveel euro jij Bitcoin wil kopen. Hieronder staat
        weergegeven hoeveel Bitcoin je daarvoor krijgt. Heb je het juiste bedrag ingevoerd? Klik dan op "Koop". Je kunt
        bij Coinmerce ook een "Stoplimiet order", misschien beter bekend als "Stop limit order", uitvoeren. Dan koopt
        ons systeem automatisch Bitcoin wanneer de prijs van Bitcoin jouw ingevoerde waarde behaalt. Ook kun je een
        herhalende order uitvoeren. Wil je Bitcoin met een andere cryptomunt kopen? Dan ga je naar de cryptomunt waarmee
        je wil betalen, en klik je in het rechtervenster op "Swap" om aan te geven dat je Bitcoin wil ontvangen.
      </BuyBitcoinDescription>
      <ChartTitle>{formatMessage({ id: IntlKeys.websiteSinglecoinH2Price }, { coin: 'VRA' })}</ChartTitle>
      <ChartContainer>
        <ExchangeRateChart isLoading={isLoading} />
      </ChartContainer>
      <CardsContainer>
        {priceCards.map(({ id, price, description, digitName, isLoading }) => (
          <PriceCard key={id} price={price} digitName={digitName} description={description} isLoading={isLoading} />
        ))}
      </CardsContainer>
      <BitcoinEarnTitle>{formatMessage({ id: IntlKeys.websiteSinglecoinEarnH2 }, { coin: 'VRA' })}</BitcoinEarnTitle>
      <BitcoinEarnDescription>
        Je kunt Bitcoin niet staken, omdat de blockchain geen gebruik maakt van Proof-of-Stake. Je kunt Bitcoin wel
        activeren voor Earn. Net zoals bij BTC staking, kun je een passief inkomen verdienen over de Bitcoins die jij
        inzet voor Earn. Crypto-experts zorgen middels geavanceerde handelsstrategieën dat ze geld verdienen uit de BTC
        die jij inzet voor Earn. Jij ontvangt de opbrengsten vervolgens in je Coinmerce wallet, waarna deze automatisch
        opnieuw worden ingezet voor Earn. Zonder iets te doen kun je dus Bitcoin beloningen ontvangen wanneer je jouw
        BTC inzet voor Earn.
      </BitcoinEarnDescription>
      <BitcoinEarnContainer>
        <AboutCoinCardWrapper>
          <AboutCoinCard
            percent="5,00"
            percentDescription={formatMessage({ id: IntlKeys.websiteSinglecoinEarnRewards })}
            price={0.00388}
            priceDescription={formatMessage({ id: IntlKeys.websiteSinglecoinEarnMinimum })}
            paymentFrequency={formatMessage({ id: IntlKeys.websiteSinglecoinEarnFrequencyTime })}
            paymentFrequencyDescription={formatMessage({ id: IntlKeys.websiteSinglecoinEarnFrequencyTitle })}
          />
        </AboutCoinCardWrapper>
        <StakingRewardForm />
      </BitcoinEarnContainer>
      <StartStakingButton>{formatMessage({ id: IntlKeys.websiteSinglecoinEarnButton })}</StartStakingButton>
      <OverBitcoinTitle>
        {formatMessage({ id: IntlKeys.websiteSinglecoinAboutTitle }, { coin: 'VRA' })}
      </OverBitcoinTitle>
      <OverBitcoinDescription>
        Bitcoin is in 2008 ontwikkeld door Satoshi Nakamoto. Helaas weet niemand de daadwerkelijke identiteit van de
        ontwikkelaar. Deze ontwikkelaar heeft dus de eerste digitale munt ontwikkeld die niet in controle is van een
        overheid of centrale autoriteit. Hierdoor is er geen derde partij nodig die alles controleert. Wanneer je
        Bitcoin overmaakt, wordt deze Bitcoin direct naar de ontvanger verstuurd. Er is geen bank nodig die controleert
        of de transactie wel uitgevoerd kan worden. Bij Bitcoin wordt deze controle namelijk gedaan door het netwerk van
        de blockchain. Het netwerk bestaat uit nodes die continu transacties aan het valideren zijn. Hiervoor krijgen ze
        Bitcoin als beloning. Alle goedgekeurd transacties worden in een blok aan de blockchain toegevoegd. Vandaar de
        naam “Blockchain”. Wanneer je Bitcoin wil kopen, gebruik je een cryptocurrency exchange. Je kunt dat vergelijken
        met een combinatie tussen een bankaccount en broker voor aandelen. Bij een exchange kun je namelijk Bitcoin
        kopen, Bitcoin verkopen maar ook Bitcoin bewaren in een wallet.
      </OverBitcoinDescription>
      <TableTitle>{formatMessage({ id: IntlKeys.websiteSinglecoinPopularTitle })}</TableTitle>
      <TableDescription>
        {formatMessage(
          { id: IntlKeys.websiteSinglecoinPopularDescription },
          {
            link: (
              <TableDescriptionLink href={`https://coinmerce.io/${locale}/cryptocurrencies/`}>
                {formatMessage({ id: IntlKeys.websiteSinglecoinPopularDescriptionAllCrypto })}
              </TableDescriptionLink>
            ),
          },
        )}
      </TableDescription>
      <PopularAssetsTable isLoading={isLoading} />
    </Wrapper>
  );
}

export default RightColumn;
