import styled from '@emotion/styled/macro';
import { Button } from '@mui/material';

export const Wrapper = styled.div`
  width: 1080px;
  margin-top: 140px;

  ${({ theme }) => theme.media.md`
    width: 100%;
    margin-top: 80px;
  `}
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.media.md`
    flex-direction: column;
    justify-content: center;
  `}
`;

export const Image = styled.img`
  width: 418.13px;
  height: 424px;

  ${({ theme }) => theme.media.md`
    align-self: center;
  `}

  ${({ theme }) => theme.media.xs`
    width: 100%;
    height: 100%;
    align-self: center;
  `}
`;

export const ListSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 560px;

  ${({ theme }) => theme.media.md`
    width: 100%;
  `}
`;

export const Title = styled.div`
  font-size: 48px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.brescianBlue};
  line-height: 100%;

  ${({ theme }) => theme.media.md`
    font-size: 32px;
    margin-top: 16px;
  `}
`;

export const Description = styled.div`
  font-size: 18px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  line-height: 36px;
  margin-top: 32px;

  ${({ theme }) => theme.media.md`
    font-size: 16px;
    line-height: 34px;
  `}
`;

export const ItemsList = styled.ul`
  margin-top: 24px;
  gap: 4px;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
`;

export const ItemIcon = styled.img`
  width: 28px;
  height: 18px;
  margin-right: 20px;
`;

export const ItemText = styled.div`
  font-size: 18px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  line-height: 36px;

  ${({ theme }) => theme.media.md`
    font-size: 14px;
    line-height: 32px;
  `}
`;

export const RegisterButton = styled(Button)`
  width: fit-content;
  height: 51px;
  padding: 16px 32px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  margin-top: 24px;

  ${({ theme }) => theme.media.md`
    font-size: 14px;
  `}
`;
