import React, { useCallback, useMemo, useRef, useState } from 'react';
// libs
import { useSelector } from 'react-redux';
// store
import { uiLocaleSelector } from 'store/ui';
// hooks
import useWindowSize from 'hooks/useWindowSize';
import useOnMouseLeave from 'hooks/useOnMouseLeave';
// icons
import HomeIcon from 'assets/icons/home.png';
import CoinsIcon from 'assets/icons/coins.png';
import OrdersIcon from 'assets/icons/orders.png';
import MoreIcon from 'assets/icons/more.png';
// constants
import { COINMERCE_BASE_URL } from 'constants/general';
import { breakpoints } from 'themes/mediaQueries';
// other
import { IntlKeys } from 'localization/keys';

function useSubMenus() {
  const locale = useSelector(uiLocaleSelector);

  const [width] = useWindowSize();

  const navRef = useRef<HTMLDivElement>(null);
  const exploreRef = useRef<HTMLDivElement>(null);

  const [isNFTMenuOpened, setIsNFTMenuOpened] = useState(false);
  const [isExploreMenuOpened, setIsExploreMenuOpened] = useState(false);
  const [isAccountMenuOpened, setIsAccountMenuOpened] = useState(false);
  const [isMobileExploreNavOpened, setMobileExploreNavOpened] = useState(false);

  const toggleMobileExploreNav = useCallback(
    () => setMobileExploreNavOpened(!isMobileExploreNavOpened),
    [isMobileExploreNavOpened],
  );

  const handleMouseOver = useCallback(() => {
    setIsExploreMenuOpened(false);
    setIsAccountMenuOpened(false);
    setIsNFTMenuOpened(false);
  }, []);

  const handleOnExploreMouseOver = useCallback(() => {
    setIsAccountMenuOpened(false);
    setIsNFTMenuOpened(false);
    setIsExploreMenuOpened(true);
  }, []);

  const handleOnAccountMouseOver = useCallback(() => {
    setIsExploreMenuOpened(false);
    setIsNFTMenuOpened(false);
    setIsAccountMenuOpened(true);
  }, []);

  const handleOnNFTMouseOver = useCallback(() => {
    setIsExploreMenuOpened(false);
    setIsAccountMenuOpened(false);
    setIsNFTMenuOpened(true);
  }, []);

  useOnMouseLeave([navRef, exploreRef], handleMouseOver);

  const menuItems = useMemo(
    () => [
      {
        icon: <img src={HomeIcon} alt="Home Icon" style={{ width: 20, height: 22 }} />,
        title: IntlKeys.headerHome,
        url: `${COINMERCE_BASE_URL}${locale}/dashboard`,
      },
      {
        icon: <img src={CoinsIcon} alt="Coins Icon" style={{ width: 33, height: 15 }} />,
        title: IntlKeys.headerCoins,
        url: `${COINMERCE_BASE_URL}${locale}/cryptocurrencies`,
      },
      {
        title: IntlKeys.headerNfts,
        onMouseOver: handleOnNFTMouseOver,
      },
      {
        title: IntlKeys.headerWallets,
        url: `${COINMERCE_BASE_URL}${locale}/wallets`,
      },
      {
        icon: <img src={OrdersIcon} alt="Orders Icon" style={{ width: 18, height: 16 }} />,
        title: IntlKeys.headerOrders,
        url: `${COINMERCE_BASE_URL}${locale}/orders`,
      },
      {
        icon: <img src={MoreIcon} alt="More Icon" style={{ width: 20, height: 4 }} />,
        title: IntlKeys.headerExplore,
        onMouseOver: width <= breakpoints.sm ? undefined : handleOnExploreMouseOver,
        onClick: width <= breakpoints.sm ? toggleMobileExploreNav : undefined,
      },
    ],
    [locale, handleOnNFTMouseOver, width, handleOnExploreMouseOver, toggleMobileExploreNav],
  );

  return {
    isNFTMenuOpened,
    isAccountMenuOpened,
    isExploreMenuOpened,
    isMobileExploreNavOpened,
    menuItems,
    navRef,
    exploreRef,
    toggleMobileExploreNav,
    handleOnAccountMouseOver,
  };
}

export default useSubMenus;
