import React, { useMemo } from 'react';
// components
import PortfolioChart from '../components/PortfolioChart';
import InfoCard from '../components/InfoCard';
// providers
import { DashboardState, useDashboardContext } from 'providers/DashboardProvider';
// icons
import { ReactComponent as StackIcon } from 'assets/icons/stack.svg';
import { ReactComponent as SignalIcon } from 'assets/icons/signal.svg';
import { ReactComponent as StakingRewardIcon } from 'assets/icons/staking-reward.svg';
import { ReactComponent as BotIcon } from 'assets/icons/bot.svg';
import { ReactComponent as BundlesIcon } from 'assets/icons/bundles.svg';
// styled
import {
  ChartContainer,
  Container,
  InfoCardsContainer,
  MoreProductsCard,
  MoreProductsCardText,
  Wrapper,
} from './styled';
import { CardPriceSkeleton, CardSkeleton, CardSkeletonWrapper, CardTitleSkeleton } from '../components/InfoCard/styled';

export interface RightColumnProps {
  isLoading?: boolean;
}

const RightColumn: React.FC<RightColumnProps> = ({ isLoading }) => {
  const { dashboardState } = useDashboardContext();

  const infoCards = useMemo(
    () => [
      {
        id: '1',
        icon: <StackIcon />,
        title: 'Crypto',
        amount: dashboardState === DashboardState.InUse ? '14.306,02' : undefined,
        isLoading,
      },
      {
        id: '2',
        icon: <SignalIcon />,
        title: 'Earn',
        amount: dashboardState === DashboardState.InUse ? '7.000,50' : undefined,
        isLoading,
      },
      {
        id: '3',
        icon: <StakingRewardIcon />,
        title: 'Staking',
        amount: dashboardState === DashboardState.InUse ? '10,40' : undefined,
        isLoading,
      },
      {
        id: '4',
        icon: <BotIcon />,
        title: 'Bots',
        amount: dashboardState === DashboardState.InUse ? '0,00' : undefined,
        isLoading,
      },
      {
        id: '5',
        icon: <BundlesIcon />,
        title: 'Bundles',
        amount: dashboardState === DashboardState.InUse ? '0,00' : undefined,
        isLoading,
      },
    ],
    [dashboardState, isLoading],
  );

  const infoCardSkeleton = useMemo(
    () => (
      <CardSkeletonWrapper>
        <CardSkeleton />
        <CardTitleSkeleton />
        <CardPriceSkeleton />
      </CardSkeletonWrapper>
    ),
    [],
  );

  return (
    <Wrapper>
      <Container>
        <ChartContainer>
          <PortfolioChart isLoading={isLoading} />
        </ChartContainer>
        <InfoCardsContainer>
          {infoCards.map(({ icon, title, id, amount, isLoading }) => (
            <InfoCard icon={icon} title={title} key={id} amount={amount} isLoading={isLoading} />
          ))}
          {isLoading ? (
            infoCardSkeleton
          ) : (
            <MoreProductsCard>
              <MoreProductsCardText>More products coming soon</MoreProductsCardText>
            </MoreProductsCard>
          )}
        </InfoCardsContainer>
      </Container>
    </Wrapper>
  );
};

export default RightColumn;
