import React, { useState } from 'react';
// material-ui
import { Snackbar, Alert } from '@mui/material';

export type NotificationType = 'error' | 'warning' | 'success' | 'info';

export interface NotificationProps {
  message: string;
  type: NotificationType;
}

const ALERT_STYLES = { width: '100%' };

function Notification({ message, type }: NotificationProps) {
  const [notificationOpen, setNotificationOpen] = useState(true);

  const handleNotificationClose = () => setNotificationOpen(false);

  if (!message) {
    return null;
  }

  return (
    <Snackbar open={notificationOpen} autoHideDuration={6000} onClose={handleNotificationClose}>
      <Alert elevation={6} variant="filled" onClose={handleNotificationClose} severity={type} sx={ALERT_STYLES}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default Notification;
