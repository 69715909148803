/*
  to get color name you can use link below:
  https://colornamer.robertcooper.me/
*/

export enum Colors {
  white = '#ffffff',
  snowBank = '#e9e9e9',
  black = '#000000',
  blackSpace = '#535353',
  wizardWhite = '#e2f1ff',
  brescianBlue = '#0082FF',
  bleachedSilk = '#f2f2f2',
  kuretakeBlackManga = '#000f21',
  galacticTint = '#bfc3c7',
  infinity = '#222931',
  draculaOrchid = '#2d343c',
  deadForest = '#424a51',
  nightOut = '#656a6f',
  antarcticDeep = '#333a40',
  smokyStudio = '#808790',
  whiteOut = '#fbfbfb',
  ultimateGrey = '#a9a9a9',
  drWhite = '#fafafa',
  brilliantLicorice = '#545454',
  coldGrey = '#9f9f9f',
  grenadinePink = '#fb5f68',
  pedestrianGreen = '#00be1d',
  kodamaWhite = '#e6f3ff',
  waystoneGreen = '#00c200',
  tomato = '#f1392c',
  miamiMarmalade = '#f7931a',
  boulevardier = '#d40707',
  orochimaru = '#d9d9d9',
  incision = '#ff0021',
  deadPixel = '#3a3a3a',
  pixelWhite = '#dbdbdb',
  coolGreen = '#38C462',
  ancestralWater = '#d0d0d0',
  rhapsodyInBlue = '#002344',
  shadowMountain = '#595959',
  grenadePink = '#fb5f68',
  atmosphere = '#019ce2',
  meissenBlue = '#0480B8',
  argent = '#888888',
  frostyDay = '#ccebf9',
  brilliance = '#fdfdfd',
  // rgba
  white50 = 'rgba(255, 255, 255, 0.5)',
  white30 = 'rgba(255, 255, 255, 0.3)',
  black50 = 'rgba(0, 0, 0, 0.5)',
  black30 = 'rgba(0, 0, 0, 0.3)',
  waystoneGreen10 = 'rgba(0, 194, 0, 0.1)',
  brilliantLicorice50 = 'rgba(84, 84, 84, 0.5)',
  blackSpace10 = 'rgba(83, 83, 83, 0.1)',
  blackSpace70 = 'rgba(83, 83, 83, 0.70)',
  blackSpace75 = 'rgba(83, 83, 83, 0.75)',
  brescianBlue10 = 'rgba(0, 129, 255, 0.1)',
  brescianBlue20 = 'rgba(0, 129, 255, 0.2)',
  incision20 = 'rgba(255, 0, 33, 0.2)',
  // transparent
  transparent = 'transparent',
}
