import React from 'react';
import styled from '@emotion/styled/macro';
import FormTextField from 'components/formik/FormTextField';
import FormSelect from 'components/formik/FormSelect';
import Select from 'components/Select';
import FormRadio, { FormRadioProps } from 'components/formik/FormRadio';
import { Button } from '@mui/material';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const Block = styled.div`
  width: 420px;
  padding: 32px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.palette.common.white};

  ${({ theme }) => theme.media.xs`
    width: 100%;
    max-width: 100%;
    padding: 16px;
  `};
`;

export const BlockTitle = styled.div`
  font-size: 19.2px;
  line-height: 32px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.rhapsodyInBlue};
`;

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledFormTextField = styled(({ success, ...rest }: { success: boolean; isPasswordField?: boolean }) => (
  <FormTextField {...rest} />
))`
  & .MuiOutlinedInput-input.MuiInputBase-input {
    font-size: 16px;
    line-height: 24px;
    font-family: 'VisbyRoundCF SemiBold', sans-serif;
    padding: 16px;
  }

  & .MuiInputAdornment-positionStart + .MuiOutlinedInput-input.MuiInputBase-input {
    padding: 16px 16px 16px 0 !important;
  }

  & .MuiOutlinedInput-notchedOutline,
  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme, success }) =>
      success ? theme.palette.common.coolGreen + ' !important' : theme.palette.common.ancestralWater};
    border-radius: 6px;
  }

  & .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.common.grenadePink};
  }

  & .Mui-focused {
    box-shadow: 0 4px 4px 0 #0023441a;
  }

  & .MuiInputAdornment-root {
    color: ${({ theme, success }) => (success ? theme.palette.common.coolGreen : theme.palette.common.rhapsodyInBlue)};
  }

  & .Mui-error .MuiInputAdornment-root {
    color: ${({ theme }) => theme.palette.common.grenadePink};
  }

  & .Mui-error .MuiInputAdornment-root {
    color: ${({ theme }) => theme.palette.common.grenadePink};
  }

  &:hover {
    & .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme, success }) =>
        success ? theme.palette.common.coolGreen : theme.palette.common.ancestralWater};
      border-radius: 6px;
    }
  }

  & .MuiFormHelperText-root {
    box-shadow: none !important;
  }

  & .MuiFormHelperText-root {
    display: ${({ isPasswordField }) => (isPasswordField ? 'none' : 'block')};
  }

  ${({ theme }) => theme.media.sm`
    max-width: 100%;
  `}
`;

export const StyledFormSelect = styled(FormSelect)`
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.common.ancestralWater};
    border-radius: 6px;
  }

  & .MuiSelect-select {
    font-size: 16px;
    line-height: 24px;
    font-family: 'VisbyRoundCF SemiBold', sans-serif;
    color: ${({ theme }) => theme.palette.common.rhapsodyInBlue};
    padding: 16px 32px 16px 14px;
  }

  ${({ theme }) => theme.media.xs`
      max-width: calc(100vw - 64px);
  `}
`;

export const FlagWrapper = styled.div`
  font-size: 16px;
  opacity: 1;
  color: black;
`;

export const BirthdayInputsContainer = styled.div`
  display: flex;
  gap: 20px;

  & label:nth-of-type(1) {
    width: 16%;
  }

  & label:nth-of-type(2) {
    width: 53%;
  }

  & label:nth-of-type(3) {
    width: 21%;
  }
`;

export const BirthdayInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-size: 16px;
  line-height: 24px;
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
  color: ${({ theme }) => theme.palette.common.shadowMountain};
  margin-bottom: 8px;
`;

export const StyledSelectPhoneCode = styled(Select)`
  box-shadow: none;

  & .MuiOutlinedInput-root {
    box-shadow: none;
  }

  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.common.black};
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiOutlinedInput-root .MuiSelect-select {
    padding-left: 0;
    padding-right: 24px;
  }

  & .MuiSelect-select span {
    display: none;
  }
`;

export const PhoneCode = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.common.ancestralWater};
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
`;

export const FieldWrapper = styled.div`
  margin-top: 16px;
`;

export const TwoFieldsContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 16px;
  justify-content: space-between;
`;

export const Icon = styled.div`
  width: 18px;
  height: 18px;
  //padding: 3px;
  border: 2px solid ${({ theme }) => theme.palette.common.argent};
  border-radius: 100%;
  box-shadow: none;
`;

export const IconChecked = styled.div`
  width: 18px;
  height: 18px;
  //padding: 3px;
  border: 2px solid ${({ theme }) => theme.palette.common.argent};
  border-radius: 100%;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconBullet = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.palette.common.brescianBlue};
  border-radius: 100%;
`;

export const StyledFormRadio = styled((props: FormRadioProps) => (
  <FormRadio
    disableRipple
    icon={<Icon />}
    checkedIcon={
      <IconChecked>
        <IconBullet />
      </IconChecked>
    }
    {...props}
  />
))`
  .MuiRadio-root {
    background-color: rgba(0, 0, 0, 0) !important;
  }

  & .MuiTypography-root {
    font-size: 16px;
    line-height: 24px;
    font-family: 'VisbyRoundCF SemiBold', sans-serif;
    color: ${({ theme }) => theme.palette.common.argent};
    margin-left: 8px;
    white-space: nowrap;
  }

  ${({ theme }) => theme.media.xs`
    & .MuiTypography-root {
        white-space: normal;
    }
  `};
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const GetStartedButton = styled(Button)`
  width: 100%;
  height: 56px;
  padding: 12px 32px;
  font-size: 19.2px;
  line-height: 32px;
  font-family: 'VisbyRoundCF Bold', sans-serif;
  color: ${({ theme }) => theme.palette.common.white};
  margin-top: 16px;
  border-radius: 6px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.atmosphere};
  }
`;

export const MonthSelector = styled(StyledFormSelect)`
  & .MuiSelect-select {
    margin-left: 16px;
  }
`;
