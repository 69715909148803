import styled from '@emotion/styled/macro';
import checkIcon from 'assets/icons/check.svg';
import { Checkbox } from '@mui/material';

export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.common.blackSpace};
  font-family: 'VisbyRoundCF SemiBold', sans-serif;
`;

export const Icon = styled.span`
  width: 24px;
  height: 24px;
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
`;

export const IconChecked = styled(Icon)`
  &:before {
    display: block;
    width: 24px;
    height: 24px;

    background-image: url(${checkIcon});
    background-repeat: no-repeat;
    background-position: center;

    content: '';
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  padding: 0;
`;
