import { css } from '@emotion/react';
import { CSSInterpolation, SerializedStyles } from '@emotion/serialize';

export type Media = {
  [K in keyof typeof breakpoints]: (template: TemplateStringsArray, ...args: CSSInterpolation[]) => SerializedStyles;
};

export const breakpoints = {
  xxs: 375,
  xs: 576,
  sm: 768,
  md: 990,
  lg: 1200,
  xl: 1440,
};

export const media = (Object.keys(breakpoints) as (keyof typeof breakpoints)[]).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (max-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `;

  return accumulator;
}, {} as Media);
