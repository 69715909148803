import styled from '@emotion/styled/macro';
// material-ui
import { TextField } from '@mui/material';

export const Wrapper = styled.div`
  width: 400px;
  border: 1px solid ${({ theme }) => theme.palette.common.snowBank};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 24px;
  ${({ theme }) => theme.media.md`
    width: 100%;
  `}
`;

export const FormTab = styled.div``;

export const StyledTextField = styled(TextField)`
  width: 100%;

  & .MuiOutlinedInput-input {
    height: auto;
    padding: 12px 0;
    font-size: 16px;
    line-height: 26px;
    color: ${({ theme }) => theme.palette.common.blackSpace};
    font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  }

  & .MuiInputAdornment-root > p {
    margin-right: 8px;
    font-size: 21px;
    color: ${({ theme }) => theme.palette.common.blackSpace};
    font-family: ${({ theme }) => theme.typography.fontFamilyVisbyRoundCFSemiBold};
  }
`;
