import React from 'react';
// images
import positiveBot from 'assets/images/bots-page/positive-bot.png';
import neutralBot from 'assets/images/bots-page/neutral-bot.png';
import aggressiveBot from 'assets/images/bots-page/aggressive-bot.png';
// styled
import { Wrapper, Row, BotNameSection, BotIcon, Text, BotTypeSection, Percents } from './styled';

const BOTS_LIST = [
  { id: '1', icon: positiveBot, name: 'BA-BOT-1', type: 'Defensive', percents: 7.61 },
  { id: '2', icon: neutralBot, name: 'BA-BOT-2', type: 'Neutral', percents: 6.12 },
  { id: '3', icon: aggressiveBot, name: 'BA-BOT-3', type: 'Aggressive', percents: 5.61 },
];

const BotsTable: React.FC = () => {
  return (
    <Wrapper>
      {BOTS_LIST.map(({ id, icon, name, type, percents }) => (
        <Row key={id}>
          <BotNameSection>
            <BotIcon src={icon} />
            <Text>{name}</Text>
          </BotNameSection>
          <BotTypeSection>
            <Text>{type}</Text>
            <Percents isPositive={percents >= 0}>
              {percents >= 0 ? '+' : ''}
              {percents}%
            </Percents>
          </BotTypeSection>
        </Row>
      ))}
    </Wrapper>
  );
};

export default BotsTable;
